import { fromJS, List, Map } from 'immutable'
import { EVENTS_WITH_SENT_LM_STATUS } from '../actions/actionTypes'
import { ConsignmentEventLM, OrderIdType } from '../types/coreEntitiesTypes'

interface LmEventsActionType {
  type: string
  params: { shipmentId: string }
  body: Map<OrderIdType, List<ConsignmentEventLM>>
}

const initialState = Map({
  eventsWithSentLmStatus: Map()
})

export default (state = initialState, action: LmEventsActionType) => {
  switch (action.type) {
    case EVENTS_WITH_SENT_LM_STATUS.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn(['eventsWithSentLmStatus', action.params.shipmentId], fromJS(action.body))
      })
    default:
      return state
  }
}
