import { fromJS, Map, List, Seq } from 'immutable'
import { CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE, SEARCH_ADDRESSES } from '../actions/actionTypes'
import { AnyAction } from 'redux'
import { IAddress } from '../pages/instant/bookingOrderTypes'

const initialState = Map({
  addresses: List()
})

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SEARCH_ADDRESSES.SUCCESS:
      return state.withMutations((mState) => {
        const body = fromJS(action.body) as Seq.Keyed<string, Map<number, IAddress>>

        return body.map((v: Map<number, IAddress>, k: string) => {
          return mState.setIn([k], v.keySeq())
        })
      })
    case CLEAR_SEARCH_ADDRESSES_FOR_ADDRESSES_PAGE:
      return state.setIn(['addresses'], initialState)
    default:
      return state
  }
}
