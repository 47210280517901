import { isCollection, Map } from 'immutable'
import { DateTime, Duration } from 'luxon'
import replace from 'lodash/replace'
import trim from 'lodash/trim'
import { isNotNullOrUndefined, isNullOrUndefined } from '../types/immutableTypes'
import { isBlank, isEmpty } from './stringUtils'
import { enableMandatoryVehicle, isGlowTechnology } from '@glow/common'

export const required = (value: any) => value && ((isCollection(value) && value.count()) || value.toString().length)

export const requiredImmutable = (value: Map<unknown, unknown>) => value && value.count()

export const validPositiveNumber = (value: string | number | null | undefined) =>
  validNumber(value) && Number(value) >= 0

export const validNumber = (value: string | number | null | undefined) =>
  isNotNullOrUndefined(value) && !value.toString().includes(' ') && !isNaN(Number(value))

export const validNumberOrEmpty = (value: string | number | null | undefined) =>
  isNullOrUndefined(value) || (!value.toString().includes(' ') && !isNaN(Number(value)))

export const validAlphanumeric = (value: string | null | undefined) => value && /^[\da-zA-Z]+$/.test(value)

export const validTrimmedAlphanumericOrEmpty = (value: string | null | undefined) =>
  isEmpty(value) || (value && validAlphanumeric(trim(value)))

export const validQuantityOrEmpty = (quantity: string) => quantity.toString() === '' || validQuantity(quantity)

export const validQuantityOrEmptyOrUndefined = (quantity: string | undefined) =>
  quantity === undefined || validQuantityOrEmpty(quantity)

const validQuantity = (quantity: string) =>
  validNumber(quantity) && Number.isInteger(Number(quantity)) && Number(quantity) > 0

export const validTrimmedToNumber = (value: string | null | undefined) =>
  !(value && isNaN(Number(replace(value, ' ', ''))))

export const validTrimmed = (value: string | null | undefined) => !(value && isBlank(value))

export const validNumberNotZero = (value: number | null | undefined) => value && validNumber(value) && value > 0

export const validNumberNotZeroIfSaas = (value: number | null | undefined) =>
  enableMandatoryVehicle() ? value && validNumber(value) && value > 0 : true

export const validUnitId = (value: number | null | undefined) =>
  isGlowTechnology() ? validNumberOrEmpty(value) : validNumberNotZero(value)

export const isChecked = (value: boolean) => value

// Returns valid email address, ex name@adress.com
const emailRegex = /.+@.+\..+/
export const email = (value: string | null | undefined) => (value ? value === '' || emailRegex.test(value) : true)

// Returns valid phone number, ex: 22 33 44 55, 22334455, +47 22 33 44 55, +4722334455 and +62 812-3750-2718
export const phone = (value: string | null | undefined) =>
  !(value && !/^(?:[-\d\s]+|[+\s][-\d\s]+|\s[+][-\d\s]+|[+])$/i.test(value))

export const validPhonePrefix = (value: string | null | undefined) =>
  !value || value.startsWith('+') || value.startsWith('00')

export const TIMESTAMP_DATE_FORMAT = 'yyyy-MM-dd'
export const TIMESTAMP_TIME_FORMAT = 'HH:mm'

export const validDate = (value: string | null | undefined) =>
  DateTime.fromFormat(value || '', TIMESTAMP_DATE_FORMAT).isValid
export const validDateOrBlank = (value: string | null | undefined) => isEmpty(value) || validDate(value)

export const validTime = (value: string | null | undefined) =>
  DateTime.fromFormat(value || '', TIMESTAMP_TIME_FORMAT).isValid
export const validDuration = (value: string | null | undefined) => !!parseDuration(value)
export const validTimeOrBlank = (value: string | null | undefined) => isEmpty(value) || validTime(value)
export const validDurationOrBlank = (value: string) => isEmpty(value) || validDuration(value)

export const parseDuration = (duration: string | null | undefined) => {
  const parts = duration?.trim()?.split(':')
  if (!parts || parts.length !== 2 || parts[0]?.trim() === '' || parts[1]?.trim() === '') return null
  const minutes = parseInt(parts[0], 10)
  const seconds = parseInt(parts[1], 10)
  if (isNaN(minutes) || isNaN(seconds)) {
    return null
  } else {
    return Duration.fromObject({ minutes, seconds })
  }
}

export const validZipCode = (value: string) => {
  const norwegianZipCode = /^\d{4}$/i.test(value)
  const swedishZipCode = /^\d{3}\s\d{2}$/i.test(value)
  const finnishZipCode = /^\d{5}$/i.test(value)
  return norwegianZipCode || swedishZipCode || finnishZipCode
}

export const isValidJson = (value: string) => {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}

export const validDeviationCode = (value: string | null | undefined) => (value ? !value.includes('-') : true)

export const validNumberWithinRange = (value: string | null | undefined) =>
  value ? validNumber(value) && value.toString().length <= 10 : true

export const isValidTimezone = (value: string) => DateTime.local().setZone(value).isValid

export const requiredDateWhenSecureLogin = (values: Map<string, unknown>) =>
  values?.get('secureLoginEnabled') ? required(values?.get('dateOfBirth')) : true
