import { isEmpty } from '../../utils/stringUtils'

export const validBreakTimeWindow = (timeFrom: string, timeTo: string, duration: string): boolean => {
  const timeFromParts = timeFrom.trim()?.split(':')
  const timeFromHours = parseInt(timeFromParts[0], 10)
  const timeFromMinutes = parseInt(timeFromParts[1], 10)
  const timeToParts = timeTo?.trim()?.split(':')
  const timeToHours = parseInt(timeToParts[0], 10)
  const timeToMinutes = parseInt(timeToParts[1], 10)

  return (
    allEmptyFields(duration, timeFrom, timeTo) ||
    timeToHours > timeFromHours ||
    (timeToHours === timeFromHours && timeToMinutes > timeFromMinutes)
  )
}

const allEmptyFields = (duration: string, timeFrom: string, timeTo: string) =>
  isEmpty(duration) && isEmpty(timeFrom) && isEmpty(timeTo)
