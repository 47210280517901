import styled, { css } from 'styled-components'
import * as CSS from 'csstype'

interface FlexProps {
  justifyContent?: CSS.Property.JustifyContent
  flexWrap?: CSS.Property.FlexWrap
  alignItems?: CSS.Property.AlignItems
  alignContent?: CSS.Property.AlignContent
  gap?: CSS.Property.Gap<number>
  inline?: boolean
  fullWidth?: boolean
}

const Flex = styled.div<FlexProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ alignContent }) =>
    alignContent &&
    css`
      align-content: ${alignContent};
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const FlexRow = styled(Flex)<FlexProps>``

export const FlexColumn = styled(Flex)<FlexProps>`
  flex-direction: column;
`
