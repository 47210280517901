import { Label } from '../../primitives/Forms'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import ValidationInput from '../ValidationInput'
import { required, validNumberWithinRange } from '../../utils/inputValidation'
import { ValidationError } from '../../primitives/ErrorMessages'
import React from 'react'

interface Props {
  model: string
  isRequired: boolean
  changeAction?: (model: string, value: string) => void
}

export const CapacityInput = ({ model, isRequired = false, changeAction }: Props) => (
  <Label>
    {i18next.t(`courier${model}`)} {isRequired && '*'}
    <Control.text
      model={model}
      component={ValidationInput}
      validators={(isRequired && { required, validNumberWithinRange }) || { validNumberWithinRange }}
      placeholder={i18next.t(`courier${model}`)}
      changeAction={changeAction}
    />
    <Errors
      className="errors"
      model={model}
      show="touched"
      wrapper={ValidationError}
      messages={{
        required: `${i18next.t('application.required')}`,
        validNumberWithinRange: `${i18next.t('error.notAValidNumber')}`
      }}
    />
  </Label>
)
