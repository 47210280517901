import { useToggle } from '@glow/common'
import React, { createContext, ReactNode, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

export interface AppMenuContextValue {
  expanded: boolean
  menuWidthPx: number
  menuWidthWithPaddingPx: number
  lastSelectedAirExpressDepartment?: string
  lastSelectedPlannerDepartment?: string
  toggleExpanded: () => void
  setAirExpressDepartment: (id: string) => void
  setPlannerDepartment: (id: string) => void
}

const LayoutCardPadding = 64
export const initialAppMenuContextValue: AppMenuContextValue = {
  expanded: true,
  menuWidthPx: 0,
  menuWidthWithPaddingPx: LayoutCardPadding,
  lastSelectedAirExpressDepartment: undefined,
  lastSelectedPlannerDepartment: undefined,
  toggleExpanded: () => {},
  setAirExpressDepartment: () => {},
  setPlannerDepartment: () => {}
}
export const AppMenuContext = createContext<AppMenuContextValue>(initialAppMenuContextValue)

export const AppMenuProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const [expanded, toggleExpanded] = useToggle(true)
  const menuWidth = useMemo(() => (expanded ? 256 : 72), [location.pathname, expanded])
  const [lastSelectedAirExpressDepartment, setAirExpressDepartment] = useState<string>()
  const [lastSelectedPlannerDepartment, setPlannerDepartment] = useState<string>()

  return (
    <AppMenuContext.Provider
      value={{
        expanded,
        menuWidthPx: menuWidth,
        lastSelectedAirExpressDepartment,
        lastSelectedPlannerDepartment,
        menuWidthWithPaddingPx: menuWidth + LayoutCardPadding,
        toggleExpanded,
        setAirExpressDepartment,
        setPlannerDepartment
      }}
    >
      {children}
    </AppMenuContext.Provider>
  )
}
