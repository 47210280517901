import { fromJS, List, Map } from 'immutable'
import { GET_INVOICING_DATA, ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'

const initialState = Map()

interface GetInvoicingDataAction {
  type: typeof GET_INVOICING_DATA
  body: Map<number, List<unknown>>
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
  body: Map<number, List<unknown>>
}

type InvoicingDataAction = LocationChangeAction | GetInvoicingDataAction

export default function reducer(state = initialState, action: InvoicingDataAction) {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE_ACTION:
      return initialState
    case GET_INVOICING_DATA.SUCCESS:
      return state.set('data', fromJS(action.body).valueSeq())
    default:
      return state
  }
}
