import i18next from 'i18next'
import { List, Map, isCollection } from 'immutable'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import toString from 'lodash/toString'
import { ActionType, GET_USER } from '../actions/actionTypes'
import { ImmutableMap, isCollectionOf } from '../types/immutableTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { isNotEmpty } from '../utils/collectionUtils'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { IError } from './optimizeJobResultsSelector'

type Tuple = [Map<string, HttpStatus>, ActionType?]

type HttpStatusType = 'init' | 'request' | 'success' | 'failure' | null

interface HttpErrorDetailsProps {
  errorType: string
  description: string
  params: Map<string, any>
}

interface HttpErrorDetails extends ImmutableMap<HttpErrorDetailsProps> {}

interface HttpErrorProps {
  status: number
  errors: List<HttpErrorDetails>
}

interface HttpError extends ImmutableMap<HttpErrorProps> {}

interface HttpStatusProps {
  status: HttpStatusType
  lastError: HttpError
  params: Map<string, any>
}

export interface HttpStatus extends ImmutableMap<HttpStatusProps> {}

const getAllHttpStatuses: (state: AppStateType, action?: ActionType) => Tuple = createImmutableEqualSelector(
  (state: AppStateType, action?: ActionType) => [state.getIn(['httpStatus']), action] as Tuple,
  (it) => it
)

export const getHttpStatus: (state: AppStateType, action: ActionType) => HttpStatus = createImmutableEqualSelector(
  getAllHttpStatuses,
  ([httpStatus, action]: Tuple) => (action ? httpStatus.get(action.name, Map()) : Map())
)

export const getHttpStatusStatus: (state: AppStateType, action: ActionType) => HttpStatusType =
  createImmutableEqualSelector(getHttpStatus, (httpStatus) => httpStatus.get('status'))

export const loaded: (state: AppStateType, action: ActionType) => boolean = createImmutableEqualSelector(
  getHttpStatusStatus,
  (status: HttpStatusType) => status === 'success'
)

export const failed: (state: AppStateType, action: ActionType) => boolean = createImmutableEqualSelector(
  getHttpStatusStatus,
  (status: HttpStatusType) => status === 'failure'
)

export const responded: (state: AppStateType, action: ActionType) => boolean = createImmutableEqualSelector(
  loaded,
  failed,
  (loaded: boolean, failed: boolean) => loaded || failed
)

export const request: (state: AppStateType, action: ActionType) => boolean = createImmutableEqualSelector(
  getHttpStatusStatus,
  (status: HttpStatusType) => status === 'request'
)

export const shouldInitLoad: (state: AppStateType, action: ActionType) => boolean = createImmutableEqualSelector(
  getHttpStatusStatus,
  (status: HttpStatusType) => status === 'init' || isNil(status)
)

export const userNotLoggedIn: (state: AppStateType, action?: ActionType) => boolean = createImmutableEqualSelector(
  getAllHttpStatuses,
  ([httpStatuses]: Tuple) => httpStatuses.getIn([GET_USER.name, 'status']) !== 'success'
)

export const getRawErrorParams = (errors: any) => {
  return (
    isCollectionOf<string, any>(errors) &&
    errors.map((err) => {
      return err.get('params')
    })
  )
}

export const getRawErrorTexts: (errors: List<IError>) => List<string> = (errors: List<IError>) => {
  return isCollection(errors)
    ? errors.map((err) => {
        const errorType = err.get('errorType')
        const description = err.get('description') != null ? err.get('description') : errorType
        const params = err.get('params')
        const key = `errors.${errorType}`
        return toString(i18next.exists(key) ? i18next.t(key, params?.toJS()) : description)
      })
    : List()
}

export const getRawOptimusErrorTexts = (errors: any) => {
  const details = isCollectionOf<string, any>(errors) && errors.getIn(['structuredErrorMessage', 'details'])

  if (details && isCollectionOf<string, any>(details)) {
    return details.map((err) => {
      const errorType = err.get('errorType')
      const description = err.get('description') != null ? err.get('description') : errorType
      const key = `errors.${errorType}`
      return i18next.exists(key) ? i18next.t(key) : description
    })
  } else if (isString(details)) {
    return details
  } else if (isCollectionOf<string, any>(errors) && isCollection(errors.get('errors'))) {
    return errors.get('errors').map((e: any) => 'Optimus error: ' + e)
  } else {
    return errors && JSON.stringify(errors)
  }
}

const getErrorTexts = (httpStatus: HttpStatus, getRawErrorTexts: any) => {
  const lastError = httpStatus.get('lastError')
  const status = lastError.get('status')
  const errors = lastError.get('errors')
  const errorMsg = getRawErrorTexts(errors)

  return isNotEmpty(errorMsg)
    ? errorMsg
    : 400 === status
      ? List([i18next.t('error.somethingWentWrongWithTheRequest')])
      : List([i18next.t('error.somethingWentWrongOnTheServer')])
}

export const successMessagesFor: (state: AppStateType, action: ActionType) => any = createImmutableEqualSelector(
  getHttpStatus,
  (httpStatus) =>
    httpStatus.get('status') === 'success' && !httpStatus.getIn(['lastError', 'read']) ? httpStatus.get('params') : null
)

export const errorMessagesFor: (state: AppStateType, action: ActionType) => List<string> | null =
  createImmutableEqualSelector(getHttpStatus, (httpStatus) =>
    httpStatus.get('status') === 'failure' && !httpStatus.getIn(['lastError', 'read'])
      ? getErrorTexts(httpStatus, getRawErrorTexts)
      : null
  )

export const errorsFor: (state: AppStateType, action: ActionType) => List<IError> | null = createImmutableEqualSelector(
  getHttpStatus,
  (httpStatus) =>
    httpStatus.get('status') === 'failure' && !httpStatus.getIn(['lastError', 'read'])
      ? httpStatus.get('lastError').get('errors')
      : null
)

export const errorMessagesOptimusFor: (state: AppStateType, action: ActionType) => List<string> | null =
  createImmutableEqualSelector([getHttpStatus], (httpStatus) =>
    httpStatus.get('status') === 'failure' && !httpStatus.getIn(['lastError', 'read'])
      ? getErrorTexts(httpStatus, getRawOptimusErrorTexts)
      : null
  )

export const errorParamsFor = createImmutableEqualSelector([getHttpStatus], (httpStatus) =>
  httpStatus.get('status') === 'failure' && !httpStatus.getIn(['lastError', 'read'])
    ? getRawErrorParams(httpStatus.getIn(['lastError', 'errors']))
    : null
)
