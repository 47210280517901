import styled from 'styled-components'

export function getWidth(props: IContainer) {
  if (props.right) {
    return '75%'
  }
  if (props.left) {
    return '600px'
  }
  if (props.wide) {
    return '1200px'
  }
  if (props.small) {
    return '600px'
  }
  if (props.full || props.fullscreen) {
    return '100%'
  }
  return '900px'
}

export interface IContainer {
  right?: boolean
  left?: boolean
  noPadding?: boolean
  center?: boolean
  gray?: boolean
  fullscreen?: boolean
  blur?: boolean
  wide?: boolean
  small?: boolean
  full?: boolean
  noTopPadding?: boolean
  halfPadding?: boolean
  expand?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}

const Container = styled.div<IContainer>`
  margin: ${(props) => !props.right && '0 auto'};
  margin-top: ${(props) => props.marginTop && '1rem'};
  margin-bottom: ${(props) => props.marginBottom && '1rem'};
  float: ${(props) => (props.right ? 'right' : props.left ? 'left' : 'center')};
  max-width: ${(props) => getWidth(props)};
  padding: ${(props) =>
    props.noPadding
      ? '0'
      : props.noTopPadding
        ? '0 1rem 2rem 1rem'
        : props.halfPadding
          ? '1rem 0rem 1rem 1rem'
          : '2rem 1rem 2rem 1rem'};
  filter: ${(props) => props.blur && 'blur(4px)'};
  transition: filter 100ms;
  text-align: ${(props) => props.center && 'center'};
  background: ${(props) => (props.gray ? '#f2f2f2' : null)};
  width: ${(props) => (props.fullscreen ? '100%' : '')};
  position: relative;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`

export const ScrollableContainer = styled(Container)`
  overflow-y: scroll;
`

export default Container
