import i18next from 'i18next'
import { fromJS } from 'immutable'
import { NotificationProps } from '../../types/notificationTypes'
import { uuidv4 } from '../../utils/uuid'
import {
  ADD_FEEDBACK_NOTIFICATION,
  ADD_NOTIFICATION,
  DISMISS_ALL_NOTIFICATIONS,
  DISMISS_FEEDBACK_NOTIFICATION,
  DISMISS_NOTIFICATION
} from '../actionTypes'
import { ThunkDispatch } from './baseHelpers'

export const dismissNotification = (id: number | string) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: DISMISS_NOTIFICATION,
    id: id
  })
}
export const addFeedbackNotification = () => (dispatch: ThunkDispatch) => {
  dispatch({
    type: ADD_FEEDBACK_NOTIFICATION,
    id: uuidv4()
  })
}
export const dismissFeedbackNotification = () => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: DISMISS_FEEDBACK_NOTIFICATION
  })
}
export const dismissAllNotifications = () => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: DISMISS_ALL_NOTIFICATIONS
  })
}

export const addNoAccessNotification = () =>
  addNotification({
    title: i18next.t('application.noAccessTitle'),
    message: i18next.t('application.noAccessMessage'),
    timeout: 10000,
    type: 'error'
  })

export const addNotification = (props: NotificationProps) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: ADD_NOTIFICATION,
    id: props.id ? props.id : uuidv4(),
    props: fromJS(props)
  })
}

export const unresolvedAddressesNotificationId = 'unresolvedAddressesNotification'
export const addUnresolvedAddressesNotification =
  (unresolvedCount: number, departmentId: number, navigateToBasePath = '/planner') =>
  (dispatch: ThunkDispatch) => {
    dispatch(
      addNotification({
        id: unresolvedAddressesNotificationId,
        title: i18next.t('coordinates.setCoordinates'),
        message: i18next.t('coordinates.thereAreUnsetCoordinates', { count: unresolvedCount }),
        type: 'error',
        button: 'navigateOrDismiss',
        navigateTo: `${navigateToBasePath}/${departmentId}/coordinates`
      })
    )
  }
