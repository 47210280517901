import { Duration } from 'luxon'
import { BreakType } from '../actions/creators/helpers'
import { Map } from 'immutable'
import { Option } from '@glow/ui-components'
import i18next from 'i18next'

export const toBreakTimeJS = (values: Map<string, any> | null | undefined) => {
  if (values && values.get('type') === BreakType.TimeWindow) {
    const ISODuration = Duration.fromISO(values.get('duration'))
    const durationMinutes = ISODuration.minutes + ISODuration.hours * 60
    return {
      type: values.get('type'),
      duration: durationMinutes,
      earliest: values.get('earliest'),
      latest: values.get('latest')
    }
  } else if (values && values.get('type') === BreakType.WorkingTime) {
    const ISODuration = Duration.fromISO(values.get('duration'))
    const durationMinutes = ISODuration.minutes + ISODuration.hours * 60
    const workingTime = Duration.fromISO(values.get('maxWorkingTime')).hours.toString()
    return {
      type: values.get('type'),
      workingTimeDuration: durationMinutes,
      maxWorkingTime: workingTime
    }
  } else {
    return null
  }
}

export const BREAK_TIME_ROUTE_POINT_TYPE = 'rest_break'

export const workingTimeHours: Option[] = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hours) => ({
  code: hours.toString(),
  label: i18next.t('courier.hours', { hours })
}))
