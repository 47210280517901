import { Collection, fromJS, isCollection, List, Map, Set } from 'immutable'
import { isNotNullOrUndefined } from '../types/immutableTypes'

export const isEmpty = (collection: any) => {
  return !isNotEmpty(collection)
}

export const isNotEmpty = (collection: any) => {
  return (
    collection && ((isCollection(collection) && collection.count() > 0) || collection.length > 0 || collection.size > 0)
  )
}

export const hasNotNullOrUndefinedValues = (collection: any) => {
  return isNotEmpty(collection) && collection.every((it: any) => isNotNullOrUndefined(it))
}

export const count = (collection: any) => {
  if (isEmpty(collection)) {
    return 0
  } else if (isCollection(collection)) {
    return collection.count()
  }
  return collection.length
}

export const partitionBy = <E>(xs: List<E>, f: (x: E) => unknown): List<List<E>> => {
  if (!xs || xs.count() === 0) {
    return List([])
  }

  if (xs.count() === 1) {
    return List<List<E>>([List<E>([xs.first() as E])])
  }

  return fromJS(
    xs.shift().reduce(
      (res, curr) => {
        // Typed as E | {}, we know it's always E
        const prevGroup = res[res.length - 1] as E[]
        const prev = prevGroup[prevGroup.length - 1] as E

        if (f(prev) === f(curr)) {
          prevGroup.push(curr)
        } else {
          res.push([curr])
        }

        return res
      },
      [[xs.first()]]
    )
  ) as List<List<E>>
}

export const zipBy = <K, T extends Map<any, any>>(key: string, xs: Collection.Indexed<T>) => {
  if (!xs || !key) return Map<K, T>()

  const definedXs = xs.filter((it) => it !== undefined)
  const keys: Collection.Indexed<K> = definedXs.map((it: T) => it.get(key))
  return keys.zip(definedXs)
}

export const zipmapBy = <K, T extends Map<any, any>>(key: string, xs: Collection.Indexed<T>): Map<K, T> => {
  return Map(zipBy<K, T>(key, xs))
}

export function concat(...args: any) {
  let res: any = null
  args.forEach((msgs: any) => {
    if (!msgs) {
      return
    }
    if (!res) {
      res = List()
    }

    res = res.concat(msgs)
  })
  return res
}

export function keyIn(...keys: any) {
  const keySet = Set(keys)
  return function (v: any, k: any) {
    return keySet.has(k)
  }
}

export const notNull = (value: any) => value !== null
