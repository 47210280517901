import React from 'react'
import { Helmet } from 'react-helmet-async'
import { getClient } from '@glow/common'
import capitalize from 'lodash/capitalize'

export function MetaTags() {
  const client = getClient()
  const title = capitalize(client)
  const appName = `Track your delivery - ${client}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="apple-mobile-web-app-title" content={appName} />
        <meta name="application-name" content={appName} />

        <meta content={title} property="og:title" />
        <meta content={title} property="og:site_name" />
        <meta content={title} property="og:description" />
        <meta content="website" property="og:type" />

        <link rel="apple-touch-icon" sizes="180x180" href={`/${client}/appleTouchIcon.png`} />
        <link rel="mask-icon" href={`/${client}/safariPinnedTab.svg`} />
        <link rel="manifest" href={`/${client}/site.webmanifest`} />
      </Helmet>
      <Favicons />
    </>
  )
}

function Favicons() {
  const client = getClient()

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={`/${client}/favicon16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`/${client}/favicon32x32.png`} sizes="32x32" />
      <link rel="shortcut icon" href={`/${client}/favicon.ico`} />
    </Helmet>
  )
}
