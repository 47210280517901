import { fromJS, List } from 'immutable'
import { GET_SERVICES_AND_VASES_TEXTS } from '../actions/actionTypes'
import { AnyAction } from 'redux'
import { ServicesAndVasesTexts } from '../utils/serviceUtils'

const initialState = List<ServicesAndVasesTexts>()

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_SERVICES_AND_VASES_TEXTS.SUCCESS:
      return fromJS(action.body)
    case GET_SERVICES_AND_VASES_TEXTS.FAILURE:
      return initialState
    default:
      return state
  }
}
