import { Map } from 'immutable'
import { GET_APP_SETTING } from '../actions/actionTypes'
import { AppSettingsType } from '../utils/AppSettings'
import { AnyData } from '../actions/creators/baseHelpers'

interface SettingActionType {
  type: string
  params: { type: AppSettingsType }
  body: [AnyData]
}

const initialState = Map().withMutations((map) =>
  map.set(AppSettingsType.C2CBookingCustomerNumber, '').set(AppSettingsType.StripeApiKey, '')
)

export default (state = initialState, action: SettingActionType) => {
  switch (action.type) {
    case GET_APP_SETTING.SUCCESS:
      const value = action.body[0][action.params.type]
      return state.set(action.params.type, value)
    default:
      return state
  }
}
