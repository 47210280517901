import 'rc-slider/assets/index.css'
import * as Slider from 'rc-slider'
import React from 'react'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

type Props = {
  rangeStep?: number
}

export default (props: Props & Slider.RangeProps) => {
  return (
    <div>
      <Range
        min={props.min || 0}
        max={props.max || 2}
        step={props.rangeStep || 1}
        defaultValue={props.defaultValue || [1.0]}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  )
}
