import { lazy, ComponentType } from 'react'

function retry<T>(fn: () => Promise<T>, retries = 3, interval = 500): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retries == 1) {
            console.log(error)
            window.location.reload()
            return
          }
          retry(fn, retries - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export default function lazyWithRetry(fn: () => Promise<any>): ComponentType<any> {
  return lazy(() => retry(fn))
}
