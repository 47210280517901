import { Map } from 'immutable'
import { DRAWER_CLOSE, DRAWER_OPEN, ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'
import { AnyData } from '../actions/creators/baseHelpers'

const initialState = Map()

interface OpenDrawerAction {
  type: typeof DRAWER_OPEN
  className: string
  props: AnyData
}

interface CloseDrawerAction {
  type: typeof DRAWER_CLOSE
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
}

type DrawerAction = LocationChangeAction | OpenDrawerAction | CloseDrawerAction

export const drawer = (state = initialState, action: DrawerAction) => {
  switch (action.type) {
    case DRAWER_OPEN:
      return state.withMutations((s) => s.set('className', action.className).set('props', action.props))
    case DRAWER_CLOSE:
    case ROUTER_LOCATION_CHANGE_ACTION:
      return initialState
    default:
      return state
  }
}
