import i18next from 'i18next'
import { List } from 'immutable'
import React from 'react'
import { useSelector } from 'react-redux'
import { SET_PICKUP_DELIVERY_DURATION } from '../../actions/actionTypes'
import { H2 } from '../../primitives/Headings'
import { errorMessagesFor } from '../../selectors/httpStatusSelectors'
import { AppStateType } from '../../utils/appStateReduxStore'
import { isNotEmpty } from '../../utils/collectionUtils'
import { OrderIdType } from '../../types/coreEntitiesTypes'
import { closeModalAndNotify } from '../../actions/creators/helpers'
import { PrimaryButton } from '../../primitives/Button'
import { ErrorMessages, ValidationError } from '../../primitives/ErrorMessages'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import { ImmutableMap, ImmutableMapFactory } from '../../types/immutableTypes'
import { ThunkDispatch } from '../../actions/creators/baseHelpers'
import { api } from '../../http/httpHelper'
import { parseDuration, validDurationOrBlank } from '../../utils/inputValidation'
import { Input, LabelNew, LabelText } from '../../primitives/Forms'
import { gridSelectNone } from '../../actions/creators/helpersInstant'
import { useAppDispatch } from '../../reducers/redux-hooks'

interface Props {
  orderIds?: List<OrderIdType>
}

interface FormProps {
  pickupDuration: string
  deliveryDuration: string
}

export const setPickupDeliveryDurationFormInitialState = ImmutableMapFactory<FormProps>({
  pickupDuration: '',
  deliveryDuration: ''
})

export default ({ orderIds }: Props) => {
  if (!orderIds) {
    throw 'orderIds cannot be null or undefined'
  }
  const errorMessages = useSelector((state: AppStateType) => errorMessagesFor(state, SET_PICKUP_DELIVERY_DURATION))
  const dispatch = useAppDispatch()
  const handleSubmit = (formValues: ImmutableMap<FormProps>) => {
    setPickupDeliveryDuration(
      orderIds,
      toSecondsOrNull(formValues.get('pickupDuration')),
      toSecondsOrNull(formValues.get('deliveryDuration'))
    )(dispatch)
      .then(() =>
        closeModalAndNotify(
          i18next.t('setPickupDeliveryDuration.confirmation', { count: orderIds.size }),
          '',
          4000,
          true
        )(dispatch)
      )
      .then(() => gridSelectNone('PlannerShipmentsGrid')(dispatch))
  }

  return (
    <>
      {isNotEmpty(errorMessages) && <ErrorMessages errorMessages={errorMessages} />}
      <Form model="setPickupDeliveryDurationForm" onSubmit={handleSubmit}>
        <H2>{i18next.t('setPickupDeliveryDuration.heading')}</H2>
        <p>{i18next.t('setPickupDeliveryDuration.subtext', { count: orderIds.size })}</p>
        <div style={{ display: 'flex' }}>
          <LabelNew style={{ width: 'max-content', height: '4em' }}>
            <LabelText>{i18next.t('setPickupDeliveryDuration.pickupDuration')}</LabelText>
            <Control.input
              model=".pickupDuration"
              id="setPickupDeliveryDurationForm.pickupDuration"
              component={Input}
              validators={{ validDurationOrBlank }}
              placeholder={'mm:ss'}
              defaultValue={''}
              style={{ textAlign: 'center', width: '5em', padding: '11px' }}
            />
            <Errors
              className="errors"
              model=".pickupDuration"
              show="touched"
              wrapper={ValidationError}
              messages={{ validDurationOrBlank: `${i18next.t('error.notAValidDuration')}` }}
            />
          </LabelNew>
          <LabelNew style={{ width: 'max-content', height: '4em', marginLeft: '1em' }}>
            <LabelText>{i18next.t('setPickupDeliveryDuration.deliveryDuration')}</LabelText>
            <Control.input
              model=".deliveryDuration"
              id="setPickupDeliveryDurationForm.deliveryDuration"
              component={Input}
              validators={{ validDurationOrBlank }}
              placeholder={'mm:ss'}
              defaultValue={''}
              style={{ textAlign: 'center', width: '5em', padding: '11x' }}
            />
            <Errors
              className="errors"
              model=".deliveryDuration"
              show="touched"
              wrapper={ValidationError}
              messages={{ validDurationOrBlank: `${i18next.t('error.notAValidDuration')}` }}
            />
          </LabelNew>
          <LabelNew style={{ marginLeft: '1em' }}>
            <LabelText>&nbsp;</LabelText>
            <PrimaryButton type="submit">{i18next.t('button.save')}</PrimaryButton>
          </LabelNew>
        </div>
      </Form>
    </>
  )
}

export const toSecondsOrNull = (duration: string) => (duration && parseDuration(duration)?.as('seconds')) || null

export const setPickupDeliveryDuration =
  (orderIds: List<OrderIdType>, pickupDurationSeconds: number | null, deliveryDurationSeconds: number | null) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(SET_PICKUP_DELIVERY_DURATION, {
        type: SET_PICKUP_DELIVERY_DURATION.name,
        payload: {
          orderIds,
          pickupDurationSeconds,
          deliveryDurationSeconds
        }
      })
    )
