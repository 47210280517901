import { Map } from 'immutable'
import { CREATE_SNACKBAR, DISMISS_ALL_SNACKBARS, HIDE_SNACKBAR, SHOW_SNACKBAR } from '../actions/actionTypes'

interface CreateSnackbarAction {
  type: typeof CREATE_SNACKBAR
  id: string
  props: {}
}

interface ShowSnackbarAction {
  type: typeof SHOW_SNACKBAR
  id: string
}

interface HideSnackbarAction {
  type: typeof HIDE_SNACKBAR
  id: string
}

interface DismissAllSnackbarAction {
  type: typeof DISMISS_ALL_SNACKBARS
  id: string
  meta: {
    timestamp: number
  }
}

export type SnackbarActionType =
  | ShowSnackbarAction
  | DismissAllSnackbarAction
  | HideSnackbarAction
  | CreateSnackbarAction

const initialState = Map()

export default function reducer(state = initialState, action: SnackbarActionType) {
  switch (action.type) {
    case CREATE_SNACKBAR:
      return state.set(action.id, Map(action.props).set('show', true).set('id', action.id))
    case HIDE_SNACKBAR:
      return state.setIn([action.id, 'show'], false)
    case SHOW_SNACKBAR:
      return state.setIn([action.id, 'show'], true)
    case DISMISS_ALL_SNACKBARS:
      return initialState
    default:
      return state
  }
}
