/**
 * Export various styling variables
 */
export default {
  bringTheme: {
    grey900: '#171717',
    grey800: '#262626',
    grey700: '#404040',
    grey600: '#525252',
    grey500: '#737373',
    grey400: '#a3a3a3',
    grey300: '#d4d4d4',
    grey200: '#e5e5e5',
    grey100: '#f5f5f5',
    grey050: '#fafafa',

    primary900: '#042314',
    primary800: '#002f19',
    primary700: '#053d22',
    primary600: '#005730',
    primary500: '#00643a',
    primary400: '#7bc144',
    primary300: '#9bc473',
    primary200: '#C8DC8D',
    primary100: '#f0f9e5',
    primary050: '#f8fbf3'
  },
  states: {
    dangerDark: '#b91c1d',
    dangerLight: '#fef2f2',
    successDark: '#7BC144',
    successLight: '#F1F7E9',
    warning: '#FFD482',
    warningDark: '#C2410C',
    warningLight: '#FFF7ED'
  },
  emphasis: {
    low: '#D4D4D4', // grey300
    medium: '#525252', // grey600
    high: '#000000',
    extra: '#00643a', // primary500
    ultra: '#7bc144' // primary400
  },
  newColors: {
    primary: '#7bc144',
    primaryWithoutHash: '7bc144',

    cloudBlue: '#F0F8E5',
    skyBlue: '#D9ECBF',
    dayBlue: '#90CA44',

    // Greenscale
    primaryGreen: '#388068',
    pineGreen: '#32705A',
    leafGreen: '#3E9077',
    pistachioGreen: '#e3f3f0',

    // Text and icon
    black: '#232323',
    darkGrey: '#616161',

    // Greyscale
    stellarGrey: '#949494',
    spaceGrey: '#c8c8c8',
    orbitGrey: '#e9e9e9',
    moonGrey: '#f4f4f4',
    white: '#ffffff',

    // Feedback
    paleYellow: '#ffe4b4',
    paleGrey: '#e9e9e9',
    lightRed: '#FF9A75',
    lightGreen: '#93E4D1',
    lightGrey: '#c8c8c8',
    lightYellow: '#ffd482',
    intenseRed: '#E52A01',
    intenseGreen: '#3E9077',
    intenseGrey: '#949494',

    // Link and error text
    deepRed: '#AE1F00'
  },
  colors: {
    logo: '#7bc144',
    secondary: 'orange',
    white: '#fff',
    gray: '#f2f2f2',
    gray4: '#dddddd',
    gray5: '#cccccc',
    gray6: '#666',
    gray9: '#444444', // primary text color
    grayDark: '#ddd',
    black: '#444',
    darkGray: '#3D3D3D',
    pink: '#E47D8A',
    link: '#0066dd',
    blue: '#66b8d3',
    green: '#7ED321',
    gradientBackground: 'linear-gradient(35deg, #328968 65%, #7bc144 100%)',
    event: '#d6ecf3',
    consignmentCollected: '#444444',
    consignmentReturned: '#A06910',
    consignmentAssigned: '#0088b5',
    consignmentDeviated: '#e32d22',
    consignmentDelivered: '#7bc144',
    consignmentNotCollected: '#0088b5',

    //new sketches
    gray10: '#f7f7f7', // grid row background
    gray11: '#424242', // font color
    gray12: '#E0E0E0',
    gray13: '#C2C2C2',
    gray14: '#888888',
    lightGreen: '#F0FAE5',
    darkRed: '#D0021B',
    lightRed: '#F0B3BA',
    yellow: '#F5A623',
    lightYellow: '#FAD291',
    softerYellow: '#FDEDD3',
    allOk: '#B8E986',
    inactive: 'rgba(65,65,65,0.4)',
    lemon: '#F8E71C',
    brown: 'rgb(167, 103, 0)',

    warningRow: 'rgba(246,190,99,0.3)',
    warningHoverRow: '#FAD291',
    errorRow: 'rgba(228, 125, 138)',
    errorHoverRow: '#e32d22',

    gridText: '#4A4A4A',
    gridHoverRow: '#FCFCFC',

    primaryGrey: '#D6D6D6',

    shipmentReturn: '#E94978',
    shipmentOversized: '#2ABEC9',
    shipmentInstallation: '#FFAC60',
    shipmentCurbside: '#61BF52',
    borderGrey: '#CACACA',
    lightBlue: '#BBDEFB'
  },
  fonts: {
    primary:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;'
  },
  fontSize: {
    default: '1rem',
    small: '.9rem',
    medium: '1.2rem'
  },
  boxShadow: {
    small: '0px 0px 10px 3px rgba(0, 0, 0, 0.025)',
    customerMedium: '0 2px 4px rgba(0,0,0,0.2)',
    medium: '0 2px 4px rgba(0,0,0,0.2)',
    large: '0 1px 40px 3px rgba(0, 0, 0, 0.25)'
  },
  animation: {
    cubicBezier: 'cubic-bezier(.17, .67, 0, .98)'
  },
  margin: {
    right: {
      m: '0 5px 0 0'
    }
  },
  borderRadius: {
    bottom: {
      m: '0 0 5px 5px'
    },
    all: {
      m: '5px'
    },
    left: {
      m: '5px 0 0 5px'
    },
    default: '5px',
    small: '3px'
  },
  padding: {
    all: {
      xs: '',
      s: '',
      m: '20px',
      l: '',
      xl: ''
    },
    top: {
      m: '10px 0 0 0'
    },
    right: {},
    bottom: {
      m: '0 0 15px 0'
    },
    left: {}
  },
  inputs: {
    height: '40px'
  }
}
