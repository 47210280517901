import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collection, List, Map, Set } from 'immutable'
import toString from 'lodash/toString'
import React, { CSSProperties } from 'react'
import { ColumnDefinition } from '../../components/grid/GridColumns'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { GroupProps, Row } from '../../primitives/InstantGridStyles'
import vars from '../../styles/variables'
import { Entity, Shipment } from '../../types/coreEntitiesTypes'
import { DEFAULT_SELECT_COLUMN, InstantGridAction, noop2 } from './InstantGrid'
import InstantGridRow, {
  InstantGridDragColumn,
  InstantGridMultiSelectCheckbox,
  InstantGridPriorityColumn,
  stopPropagating
} from './InstantGridRow'
import isFunction from 'lodash/isFunction'

const PriorityPointerColors: { [key: string]: string } = {
  normal: '',
  high: vars.colors.white
}

interface SummaryRowProps<T> {
  isSelected: boolean
  onClick: () => void
  isClickable?: boolean
  isMultiSelectEnabled: boolean
  expanded: boolean
  priority: string
  group: Collection<number, Entity<T>>
  onSelect: () => void
  columns: List<ColumnDefinition<T>>
  defaultColumnWidth: number
  toggleExpand: () => void
  stateKey?: string
  isDragging?: boolean
  isDragNDrop?: boolean
  setRef?: (el: any) => void
  groupProps: GroupProps
  addDeletableColumn: boolean
  addUnassignableColumn: boolean
  isDisabled: boolean
}

export function SummaryRow<T>(props: SummaryRowProps<T>) {
  const firstRow = props.group.first<Entity<T>>()
  return (
    <Row
      group={props.groupProps}
      ref={props.setRef}
      onClick={props.onClick}
      isClickable={props.isClickable}
      selected={props.isSelected}
      isDragging={props.isDragging && !props.isMultiSelectEnabled}
      expanded={props.expanded}
    >
      {props.priority && (
        <InstantGridPriorityColumn priority={props.priority} clickable={true} selected={props.isSelected}>
          <a onClick={stopPropagating(props.toggleExpand)}>
            <FontAwesomeIcon
              icon={props.expanded ? 'angle-down' : 'angle-right'}
              color={PriorityPointerColors[props.priority]}
              size="lg"
            />
          </a>
        </InstantGridPriorityColumn>
      )}
      {props.isDragNDrop && <InstantGridDragColumn selected={props.isSelected} />}
      {props.isMultiSelectEnabled && (
        <td>
          <InstantGridMultiSelectCheckbox
            selected={props.isSelected}
            id={firstRow.get('id')}
            value={`${props.isSelected}`}
            checked={props.isSelected}
            onChange={props.onSelect}
            disabled={props.isDisabled}
          />
        </td>
      )}
      {props.columns.map((column) => {
        if (column.hidden) {
          return null
        }
        const style: React.CSSProperties = {
          width: column.width || '',
          maxWidth: column.maxWidth || column.width || '150px',
          minWidth: column.minWidth || column.width || '',
          ...column.style
        }
        const rows = props.group as Collection<number, Map<string, any>>
        let row = firstRow

        if (isFunction(column.summaryRowPicker)) {
          const summaryPick = column.summaryRowPicker({ column: column.name, rows })
          if (summaryPick) {
            row = summaryPick
          }
        }
        const value = row.get(column.dataIndex) || ''
        const title = column.title
          ? isFunction(column.valueRenderer)
            ? column.valueRenderer({ column: toString(column.dataIndex), row, value })
            : toString(value)
          : undefined

        const renderElement = (value: JSX.Element) => (
          <td
            style={style}
            title={title}
            key={`${props.stateKey}_${row.get('id')}_${String(column.dataIndex)}_${column.name}`}
          >
            {value}
          </td>
        )

        if (isFunction(column.groupRenderer)) {
          return renderElement(column.groupRenderer({ column: column.name, rows }))
        } else if (isFunction(column.renderer)) {
          return renderElement(column.renderer({ column: column.name, value, row }))
        } else {
          return renderElement(<>{value}</>)
        }
      })}
      {props.addDeletableColumn && <td />}
      {props.addUnassignableColumn && <td />}
      <td />
    </Row>
  )
}

export interface InstantGridGroupRowProps<T> {
  group: Collection<number, Entity<T>>
  groupKey: string
  columns: List<ColumnDefinition<T>>
  stateKey?: string
  onEditRow?: (rowId: number, row: Entity<T>) => void
  onClickRow?: (row: Entity<T>) => void
  onDoubleClickRow?: (row: Entity<T>) => void
  overflow?: boolean
  height?: string
  defaultColumnWidth?: number
  isMultiSelectEnabled: boolean
  isEditable: boolean
  isDeletable: boolean
  isUnassignable: boolean | ((row: Entity<T>) => boolean)
  style?: CSSProperties
  onSelectOrderRow?: (group: Collection<number, Entity<T>>, columnName?: string) => (event?: React.MouseEvent) => void
  onDeleteRow?: (row: Entity<T>) => void
  onUnassignRow?: (row: Entity<T>) => void
  shipmentPriorities?: Map<number, string>
  onDragEnd?: (resultId: number, row: Shipment, id: number, index: number, resultIndex: number) => void
  index: number
  id: string
  isOverCurrent?: boolean
  isDragNDropEnabled: boolean
  isDropEnabled: boolean
  allSelected: boolean
  selectedRows: Map<string, Set<number>>
  isDragging?: boolean
  isOver?: boolean
  dragPreview?: string
  connectDragPreview?: (img: HTMLImageElement) => void
  connectDragSource?: (element: JSX.Element) => void
  actions?: List<InstantGridAction<T>>
  isDragEnabled?: boolean
  setRef?: (el: any) => void
  disabledRows?: Set<number>
  isGroupedElementClickDisabled?: boolean
}

export default class InstantGridGroupRow<T> extends ImmutableComponent<InstantGridGroupRowProps<T>> {
  constructor(props: InstantGridGroupRowProps<T>) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  onToggleOneSelected = (row: Entity<T>) => () =>
    this.props.onSelectOrderRow && this.props.onSelectOrderRow(List.of(row))

  render() {
    const {
      group,
      columns,
      stateKey,
      defaultColumnWidth = 100,
      isMultiSelectEnabled,
      onClickRow,
      onDoubleClickRow,
      onSelectOrderRow = noop2,
      shipmentPriorities,
      allSelected,
      selectedRows,
      isEditable,
      isDeletable,
      isUnassignable,
      actions,
      isDragEnabled = false,
      setRef,
      disabledRows = Set(),
      isGroupedElementClickDisabled
    } = this.props
    const priority: string =
      (shipmentPriorities && group.map((value) => shipmentPriorities.get(value.get('id'))).first('normal')) || 'normal'
    const selectedDefaultRows = selectedRows && selectedRows.get(DEFAULT_SELECT_COLUMN, Set())
    const isSelected =
      allSelected ||
      (!selectedDefaultRows.isEmpty() && group.every((value) => selectedDefaultRows.has(value.get('id'))))
    const isRowClickable = !!onClickRow
    const isDisabled = disabledRows && group.every((value) => disabledRows.has(value.get('id')))

    const summary = (
      <SummaryRow
        groupProps={{
          inGroup: true,
          firstInGroup: true,
          lastInGroup: !this.state.expanded
        }}
        setRef={setRef}
        isSelected={isSelected}
        onClick={onSelectOrderRow(group)}
        isClickable={isRowClickable}
        columns={columns}
        defaultColumnWidth={defaultColumnWidth}
        expanded={this.state.expanded}
        isMultiSelectEnabled={isMultiSelectEnabled}
        onSelect={onSelectOrderRow(group)}
        priority={priority}
        group={group}
        stateKey={stateKey}
        toggleExpand={this.toggleExpand}
        isDragNDrop={isDragEnabled}
        addDeletableColumn={isDeletable}
        addUnassignableColumn={!!isUnassignable}
        isDisabled={isDisabled}
      />
    )

    const expandedElements = () => {
      return group.map((row, idx) => {
        const id: number = row.get('id')
        return (
          <InstantGridRow
            groupProps={{
              inGroup: true,
              firstInGroup: false,
              lastInGroup: idx + 1 === group.count()
            }}
            defaultColumnWidth={defaultColumnWidth}
            onDragEnd={this.props.onDragEnd}
            onEditRow={this.props.onEditRow}
            key={idx}
            index={idx}
            id={id}
            row={row}
            isOverCurrent={this.props.isOverCurrent}
            stateKey={stateKey}
            columns={columns}
            onClickRow={isGroupedElementClickDisabled ? undefined : onClickRow}
            onDoubleClickRow={onDoubleClickRow}
            selectedRows={selectedRows}
            isSelected={allSelected || isSelected}
            isMultiSelectEnabled={isMultiSelectEnabled}
            selectionDisabled={true}
            onSelectOrderRow={this.onToggleOneSelected}
            isEditable={isEditable}
            isDragNDropEnabled={this.props.isDragNDropEnabled}
            isDropEnabled={this.props.isDropEnabled}
            isDeletable={isDeletable}
            onUnassignRow={this.props.onUnassignRow}
            isUnassignable={isUnassignable}
            onDeleteRow={this.props.onDeleteRow}
            priority={shipmentPriorities ? shipmentPriorities.get(id, 'normal') : undefined}
            backgroundColor={vars.colors.gray}
            actions={actions}
            isDragEnabled={isDragEnabled}
          />
        )
      })
    }

    return (
      <>
        {summary}
        {this.state.expanded && expandedElements().toList()}
      </>
    )
  }
}
