import { isCollection } from 'immutable'
import isEqual from 'lodash/isEqual'
import React from 'react'

export default class ImmutableComponent<T = any, U = any> extends React.Component<T, U> {
  constructor(props: T) {
    super(props)
  }
  shouldComponentUpdate(nextProps: T, nextState: any) {
    return propsChanged(this.props, nextProps as { [key: string]: any }) || propsChanged(this.state, nextState)
  }
}

function propsChanged(props: { [key: string]: any }, nextProps: { [key: string]: any }) {
  const keysToSkip = {}

  for (const key in props) {
    if (Object.hasOwnProperty.call(props, key)) {
      if (key in keysToSkip) {
        continue
      }

      const val = props[key]
      const nextVal = nextProps[key]

      if (typeof val === 'function' && typeof nextVal === 'function') {
        continue
      }

      if (isCollection(val)) {
        if (!val.equals(nextVal)) {
          return true
        }
      } else {
        if (!isEqual(val, nextVal)) {
          return true
        }
      }
    }
  }
  return false
}
