import { Collection, Map } from 'immutable'
import { createImmutableEqualSelector } from '../../selectors/createImmutableEqualSelector'
import { Entity, Shipment } from '../../types/coreEntitiesTypes'
import toString from 'lodash/toString'

export const groupingSelector: <T>(
  state: Collection<number, Entity<T>>,
  groupFunction: (value: Entity<T>, key: number) => string,
  groupSortFunction: (a: Entity<T>, b: Entity<T>) => number
) => Map<string, Collection<number, Entity<T>>> = createImmutableEqualSelector(
  <T>(state: Collection<number, Entity<T>>) => state,
  <T>(state: Collection<number, Entity<T>>, groupFunction: (value: Entity<T>, key: number) => string) => groupFunction,
  <T>(
    state: Collection<number, Entity<T>>,
    groupFunction: (value: Entity<T>, key: number) => string,
    groupSortFunction: (a: Entity<T>, b: Entity<T>) => number
  ) => groupSortFunction,
  <T>(
    state: Collection<number, Entity<T>>,
    groupFunction: (value: Entity<T>, key: number) => string,
    groupSortFunction: (a: Entity<T>, b: Entity<T>) => number
  ) => state.groupBy(groupFunction).map((group) => group.sort(groupSortFunction))
)

export const groupShipmentsByGroupId = (s: Shipment) => toString(s.get('groupId') ? s.get('groupId') : s.get('id'))

export const sortShipmentGroupByGroupOrder = (a: Shipment, b: Shipment) =>
  (a.get('groupOrder') || 0) - (b.get('groupOrder') || 0)

export const groupItemsByMapKey = (v: any, k: number) => k.toString(10)
