import { Map } from 'immutable'
import React, { Children, ReactNode } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../actions/creators/helpers'
import { resetForm } from '../actions/creators/formHelpers'
import Close from '../primitives/Close'
import Overlay from '../primitives/Overlay'
import Area from '../primitives/Area'
import { AppStateType } from '../utils/appStateReduxStore'

class Modal extends React.Component<Props> {
  render() {
    const { zIndex, modal, fullscreen, large, tall, keepStateOnClose = false, listOverflow } = this.props
    if (!modal.get('className')) return null

    let component = null
    Children.forEach(this.props.children, (c: any) => {
      if (c.type === modal.get('className')) {
        component = c
      }
    })

    if (!component) return null

    const modalProps = modal.get('props')
    let includeOverlay = true
    if (modalProps && modalProps.includeOverlay !== undefined) includeOverlay = modalProps.includeOverlay

    const closeModal = modalProps && modalProps.closeModal !== undefined ? modalProps.closeModal : this.props.closeModal

    return (
      <div className="modal-wrapper" style={{ zIndex: (zIndex + 1) * 10 }}>
        <div className="modal" onKeyDown={(e) => ('Escape' === e.key ? closeModal(keepStateOnClose) : null)}>
          {includeOverlay && (
            <>
              <Overlay
                onClick={() => {
                  closeModal(keepStateOnClose)
                }}
              />
              <Area fullscreen={fullscreen} large={large} tall={tall} listOverflow={listOverflow}>
                <Close
                  onClick={() => {
                    closeModal(keepStateOnClose)
                  }}
                />
                {React.cloneElement(component, { ...this.props, ...modalProps })}
              </Area>
            </>
          )}
          {!includeOverlay && React.cloneElement(component, { ...this.props, ...modalProps })}
        </div>
      </div>
    )
  }
}

interface OwnProps {
  fullscreen?: boolean
  tall?: boolean
  listOverflow?: boolean
  large?: boolean
  keepStateOnClose?: boolean
  children: ReactNode
}

interface StateProps {
  modal: Map<string, any>
  zIndex: number
}

interface DispatchProps {
  closeModal: (keepState?: boolean) => void
  resetForm: (formName: string) => void
}

type Props = OwnProps & StateProps & DispatchProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state) => ({
    modal: state.get('modal'),
    zIndex: 100
  }),
  { closeModal, resetForm }
)(Modal)
