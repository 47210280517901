// @ts-expect-error
import { actions } from 'react-redux-form/lib/immutable'
import { AnyData, ThunkDispatch } from './baseHelpers'
import { Map } from 'immutable'
import { AppStateType } from '../../utils/appStateReduxStore'
import { ValidityOptions } from 'react-redux-form'

export const resetAllForms = () => (dispatch: ThunkDispatch, getState: () => AppStateType) => {
  Object.keys(getState().get('forms'))
    .filter((f) => f != '$form')
    .forEach((f) => dispatch(actions.reset(f)))
}

export const resetForm = (formName: string) => (dispatch: ThunkDispatch) => dispatch(actions.reset(formName))

export const preLoadForm = (model: string, value: Map<string, any>) => (dispatch: ThunkDispatch) => {
  dispatch(actions.load(model, value))
}

export const setFormValue = (model: string, field: string, value: any) => (dispatch: ThunkDispatch) => {
  const newVal: AnyData = {}
  newVal[field] = value
  dispatch(actions.merge(model, Map(newVal)))
}

export const mergeFormValues = (model: string, newVals: Map<string, any>) => (dispatch: ThunkDispatch) => {
  dispatch(actions.merge(model, newVals))
}
export const mergeFormValuesAndSubmit = (model: string, newVals: Map<string, any>) => (dispatch: ThunkDispatch) => {
  dispatch(actions.merge(model, newVals))
  dispatch(actions.submit(model))
}

export const changeFormValue = (model: string, value: any) => (dispatch: ThunkDispatch) => {
  dispatch(actions.change(model, value))
}

export const addToFormList = (model: string, value: any) => (dispatch: ThunkDispatch) => {
  dispatch(actions.push(model, value))
}

export const removeFromFormList = (model: string, index: number) => (dispatch: ThunkDispatch) => {
  dispatch(actions.remove(model, index))
}

export const setTouched = (model: string) => (dispatch: ThunkDispatch) => {
  dispatch(actions.setTouched(model))
}

export const setUntouched = (model: string) => (dispatch: ThunkDispatch) => {
  dispatch(actions.setUntouched(model))
}

export const submit =
  (model: string, promise?: Promise<any>, options?: ValidityOptions) => (dispatch: ThunkDispatch) => {
    dispatch(actions.submit(model, promise, options))
  }

export const setValidity =
  (model: string, validity: boolean | {}, options?: ValidityOptions) => (dispatch: ThunkDispatch) => {
    dispatch(actions.setValidity(model, validity, options))
  }
