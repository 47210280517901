import { Map, Set } from 'immutable'
import {
  GRID_INIT,
  GRID_INIT_SORT,
  GRID_RESET_SORT,
  GRID_SELECT_NONE,
  GRID_SELECT_UPDATE,
  GRID_SORT_BY_COLUMN,
  GRID_SORT_DESCENDING,
  GRID_DISABLE_ROWS
} from '../actions/actionTypes'
import { DEFAULT_SELECT_COLUMN } from '../pages/instant/InstantGrid'

export interface GridInitAction {
  type: typeof GRID_INIT
  id: string
}

interface GridDisableRowsAction {
  type: typeof GRID_DISABLE_ROWS
  id: string
  disabledIds: Set<number>
}

interface GridSelectUpdateAction {
  type: typeof GRID_SELECT_UPDATE
  id: string
  column: string
  selectedIds: Set<number>
  allSelected: boolean
}

interface GridSelectNoneAction {
  type: typeof GRID_SELECT_NONE
  id: string
  column: string
}

interface GridSortDescendingAction {
  type: typeof GRID_SORT_DESCENDING
  id: string
  sortDescending: false
}

interface GridSortByColumnAction {
  type: typeof GRID_SORT_BY_COLUMN
  id: string
  sortedColumnKey: string | undefined
}

interface GridResetSortAction {
  type: typeof GRID_RESET_SORT
  id: string
  sortedColumnKey: string | undefined
}

interface GridInitSortAction {
  type: typeof GRID_INIT_SORT
  id: string
  sortedColumnKey: string
  sortDescending: boolean
  sortFunction: (d1: unknown, d2: unknown) => number
}

export type SnackbarActionType =
  | GridInitAction
  | GridSelectNoneAction
  | GridSelectUpdateAction
  | GridSortDescendingAction
  | GridSortByColumnAction
  | GridResetSortAction
  | GridInitSortAction
  | GridDisableRowsAction

const initialState = Map()

const initialGridState = Map({
  selectedIds: Set<number>(),
  selectedIdsPerColumn: Map<string, Set<number>>(),
  allSelectedPerColumn: Map<string, boolean>(),
  sortDescending: false,
  sortedColumnKey: undefined,
  sortFunction: undefined,
  disabledIds: Set<number>()
})

export default function reducer(state = initialState, action: SnackbarActionType) {
  switch (action.type) {
    case GRID_INIT:
      return state.set(action.id, initialGridState)
    case GRID_DISABLE_ROWS:
      return state.setIn([action.id, 'disabledIds'], action.disabledIds)
    case GRID_SELECT_UPDATE:
      if (action.column === DEFAULT_SELECT_COLUMN)
        return state
          .setIn([action.id, 'selectedIds'], action.selectedIds)
          .setIn([action.id, 'selectedIdsPerColumn', action.column], action.selectedIds)
          .setIn([action.id, 'allSelectedPerColumn', action.column], action.allSelected)
      else
        return state
          .setIn([action.id, 'selectedIdsPerColumn', action.column], action.selectedIds)
          .setIn([action.id, 'allSelectedPerColumn', action.column], action.allSelected)
    case GRID_SELECT_NONE:
      if (action.column === DEFAULT_SELECT_COLUMN)
        return state
          .setIn([action.id, 'selectedIds'], Set())
          .setIn([action.id, 'selectedIdsPerColumn', action.column], Set())
          .setIn([action.id, 'allSelectedPerColumn', action.column], false)
      else
        return state
          .setIn([action.id, 'selectedIdsPerColumn', action.column], Set())
          .setIn([action.id, 'allSelectedPerColumn', action.column], false)
    case GRID_SORT_DESCENDING:
      return state.setIn([action.id, 'sortDescending'], action.sortDescending)
    case GRID_SORT_BY_COLUMN:
      return state.setIn([action.id, 'sortedColumnKey'], action.sortedColumnKey)
    case GRID_RESET_SORT:
      return state.withMutations((s) => {
        s.setIn([action.id, 'sortedColumnKey'], undefined)
          .setIn([action.id, 'sortDescending'], false)
          .setIn([action.id, 'sortFunction'], undefined)
      })
    case GRID_INIT_SORT:
      return state.withMutations((s) => {
        s.setIn([action.id, 'sortedColumnKey'], action.sortedColumnKey)
          .setIn([action.id, 'sortDescending'], action.sortDescending)
          .setIn([action.id, 'sortFunction'], action.sortFunction)
      })
    default:
      return state
  }
}
