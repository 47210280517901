import * as envUtils from '@glow/common/utils/env'
import { UserAccessKey } from '@glow/entity-types'
import i18next from 'i18next'
import { List } from 'immutable'
import { AirlineTerminalIdType } from '../../../domain/airexpress/airlineTerminal'
import { CustomerIdType } from '../../../domain/customer'
import { DepartmentIdType, RoleId, SessionUser } from '../../../types/coreEntitiesTypes'
import {
  ROLE_ADMIN,
  ROLE_AIREXPRESS_CUSTOMS_AGENT,
  ROLE_AIREXPRESS_HANDLER,
  ROLE_CUSTOMER,
  ROLE_PARTNER,
  ROLE_PLANNER,
  ROLE_PLUS_PLANNER,
  ROLE_READ_ONLY_PLANNER
} from '../../../utils/roles'

export interface AdminUserFormProps {
  id?: number
  name: string
  email: string
  phoneNumber: string
  departments: DepartmentIdType[]
  customers?: CustomerIdType[]
  roleType: string
  canLogIn: boolean
  roles: RoleId[]
  displayCustomersTab: boolean
  terminals: AirlineTerminalIdType[]
}

export const adminPagesAccesses: UserAccessKey[] = [
  'ADMIN_OPTIMIZING',
  'ADMIN_LM_EVENTS',
  'ADMIN_NUMBER_SERIES',
  'ADMIN_TEXTS',
  'ADMIN_JOBS',
  'ADMIN_PRE_ADVICE',
  'ADMIN_SETTINGS',
  'ADMIN_SHD',
  'ADMIN_CUSTOMER',
  'ADMIN_CUSTOMER_ALL',
  'ADMIN_CUSTOMER_GROUP',
  'ADMIN_HOLIDAYS',
  'ADMIN_MATRIX',
  'ADMIN_USERS',
  'ADMIN_DEPARTMENT',
  'ADMIN_DEPARTMENT_GROUP',
  'ADMIN_VENDORS'
]

export const customerPortalAccesses: UserAccessKey[] = [
  'CUSTOMER_PORTAL',
  'CUSTOMER_PORTAL_ADMIN',
  'BOOK_SHIPMENT',
  'VIEW_PRICE'
]

export const plannerPageAccesses: UserAccessKey[] = [
  'PLANNER_ADDRESSES',
  'PLANNER_BILLING_ORDERS',
  'PLANNER_BOOKING',
  'PLANNER_CORRECTIONS',
  'PLANNER_COURIERS',
  'ADMIN_ALCOHOL_DELIVERY_EDUCATION',
  'PLANNER_DISPATCHING',
  'PLANNER_IMPORT_EXPORT',
  'PLANNER_IMPORT_EXPORT_EXTENDED',
  'PLANNER_INVOICING',
  'PLANNER_LIVE',
  'PLANNER_MANAGE_RETURNS',
  'PLANNER_PICKUP_ORDERS',
  'PLANNER_RECURRING_ORDERS',
  'PLANNER_ROUTE_MANAGEMENT',
  'PLANNER_ROUTE_PLANNING',
  'PLANNER_SHIPMENTS',
  'PLANNER_UNITS',
  'PLANNER_UNRESOLVED',
  'PLANNER_VEHICLES',
  'PLANNER_PRE_ADVICE'
]
export const optimusAccesses: UserAccessKey[] = ['OPTIMUS_VIEW', 'OPTIMUS_EDIT']

export const ordersApiSearchAccesses: UserAccessKey[] = ['ORDERS_API_SEARCH_RESTRICTED', 'ORDERS_API_SEARCH_FULL']

export const orderAttributesAccesses: UserAccessKey[] = optimusAccesses.concat(ordersApiSearchAccesses)

export const airExpressPagesAccesses: UserAccessKey[] = [
  'AIR_EXPRESS_BOOKING',
  'AIR_EXPRESS_CUSTOMS',
  'AIR_EXPRESS_EXPORT',
  'AIR_EXPRESS_INVOICING',
  'AIR_EXPRESS_MANIFEST',
  'AIR_EXPRESS_RECURRING',
  'AIR_EXPRESS_SHIPMENTS'
]
export const airExpressAdminPagesAccesses: UserAccessKey[] = [
  'AIR_EXPRESS_ADMIN_AIRLINES',
  'AIR_EXPRESS_ADMIN_AIRPORTS',
  'AIR_EXPRESS_ADMIN_CUSTOMS_AGENTS',
  'AIR_EXPRESS_ADMIN_FLIGHT_SEARCH',
  'AIR_EXPRESS_ADMIN_HANDLERS',
  'AIR_EXPRESS_ADMIN_PRE_ADVICE',
  'AIR_EXPRESS_ADMIN_SCHEDULES',
  'AIR_EXPRESS_ADMIN_TERMINALS',
  'AIR_EXPRESS_ADMIN_UPLOADS'
]
export const airExpressAccesses: UserAccessKey[] = airExpressAdminPagesAccesses.concat(airExpressPagesAccesses)

export const financialAccess: UserAccessKey[] = [
  'FINANCIAL_SHOW_INVOICING_PAYLOAD',
  'FINANCIAL_DOWNLOAD_INVOICE',
  'FINANCIAL_CREDIT_NOTE',
  'FINANCIAL_COPY_TO_BILLING_ORDER',
  'FINANCIAL_VIEW_GROUP',
  'FINANCIAL_VIEW_MULTILEG',
  'VIEW_PRICE_EVENTS'
]

export const checkDisplayCustomersTab = (userRole: string | undefined, selectedAccesses: List<UserAccessKey>) =>
  selectedAccesses.includes('CUSTOMER_PORTAL_ADMIN')
    ? false
    : userRole === ROLE_CUSTOMER.toLowerCase() || selectedAccesses.includes('CUSTOMER_PORTAL')

export const getUserRoleOptions = (user: SessionUser) => {
  return [
    { code: ROLE_PLANNER.toLowerCase(), label: i18next.t('planner.normal') },
    { code: ROLE_PLUS_PLANNER.toLowerCase(), label: i18next.t('planner.plus') },
    { code: ROLE_READ_ONLY_PLANNER.toLowerCase(), label: i18next.t('planner.readOnly') },
    { code: ROLE_PARTNER.toLowerCase(), label: i18next.t('planner.partner') }
  ]
    .concat(
      user.get('access')?.includes('ADMIN_ROLE')
        ? [{ code: ROLE_ADMIN.toLowerCase(), label: i18next.t('application.title.admin') }]
        : []
    )
    .concat(
      envUtils.isGlowTechnology()
        ? [{ code: ROLE_CUSTOMER.toLowerCase(), label: i18next.t('userRoles.customer') }]
        : [
            { code: ROLE_AIREXPRESS_CUSTOMS_AGENT.toLowerCase(), label: i18next.t('userRoles.customs_agent') },
            { code: ROLE_AIREXPRESS_HANDLER.toLowerCase(), label: i18next.t('userRoles.handler') }
          ]
    )
}
