import styled from 'styled-components'
import vars from '../styles/variables'

const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: ${vars.colors.white};
  line-height: 1;
  cursor: pointer;
  width: 56px;
  height: 56px;
  z-index: 99999;

  &:after {
    position: absolute;
    left: 20px;
    top: 13px;
    content: '×';
    color: ${vars.colors.gray11};
    font-size: 2rem;
  }
`

export default Close
