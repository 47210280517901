import styled from 'styled-components'
import vars from '../styles/variables'

const Select = styled.div<{
  expand?: boolean
  marginBottom?: boolean
  white?: boolean
  marginLeft?: boolean
  small?: boolean
  medium?: boolean
}>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.expand ? '100%' : 'auto')};
  margin-bottom: ${(props) => props.marginBottom && '3rem'};

  select {
    display: block;
    width: ${(props) => (props.expand ? '100%' : 'auto')};
    cursor: pointer;
    padding: 0.6rem 2rem 0.65rem 1rem;
    background: none;
    appearance: none;
    background: white;
    position: relative;
    margin-left: ${(props) => props.marginLeft && '.5rem'};
    border-radius: 4px;
    box-shadow: 0 0 1px ${vars.colors.black};
    font-size: ${(props) => (props.small ? '0.7rem' : props.medium && '1rem')};
    &:hover {
      box-shadow: 0 0 4px ${vars.colors.black};
    }

    &:active,
    &:focus {
      box-shadow: 0 0 4px ${vars.colors.secondary};
    }

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      background: ${vars.colors.gray};
    }
  }

  &::after {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    content: '';
    display: block;
    pointer-events: none;
    width: 0.5rem;
    height: 0.5rem;
    border-left: 2px solid ${vars.colors.darkGray};
    border-bottom: 2px solid ${vars.colors.darkGray};
    transform: rotate(-45deg);
  }
`

export default Select
