import { fromJS, Map } from 'immutable'
import { CLEAR_SEARCH_COURIER_USERS, SEARCH_COURIER_USERS } from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map()

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SEARCH_COURIER_USERS.SUCCESS:
      return fromJS(action.body)
    case CLEAR_SEARCH_COURIER_USERS:
      return initialState
    default:
      return state
  }
}
