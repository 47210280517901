import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: background 400ms;
  background: #000000;
  opacity: 0.45;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 2000;
`

export default Overlay
