import * as types from '../actionTypes'
import { api } from '../../http/httpHelper'
import { storePhone } from '../../storage/storage'
import { ROLE_CUSTOMER } from '../../utils/roles'
import { resetAllForms } from './formHelpers'
import { ThunkDispatch } from './baseHelpers'
import { AppStateType } from '../../utils/appStateReduxStore'

export const fetchUser = () => (dispatch: ThunkDispatch) => {
  return dispatch(api.asyncGet('/auth/user', types.GET_USER))
}
export const logout = () => (dispatch: ThunkDispatch, getState: () => AppStateType) => {
  const isCustomer = getState().getIn(['user', 'role']) === ROLE_CUSTOMER
  const logoutPromise = dispatch(api.asyncGet('/auth/logout', types.LOGOUT)).then((res) => {
    if (isCustomer) {
      location.href = res.readBody.redirectUrl
    }
    dispatch(resetAllForms())
    return Promise.resolve()
  })

  return logoutPromise
}

const logInAs = (type: string) => () => (dispatch: ThunkDispatch) => {
  return api.fetchGet(`/auth/user/${type}`).then(() => dispatch(fetchUser()))
}

export const requestToken = (phone: string) => (dispatch: ThunkDispatch) =>
  dispatch(api.asyncPostJson(types.REQUEST_TOKEN, '/auth/request-token', { phoneNumber: phone }))

export const verifyToken = (phone: string, token: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncPostJson(types.VERIFY_TOKEN, '/auth/verify-token', {
      phoneNumber: phone,
      token: token
    })
  ).then((r) => {
    storePhone(phone)
    if (r.ok) {
      return dispatch(fetchUser())
    } else return r
  })

export const loginAdminPwd = (password: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncPostJson(types.VERIFY_PWD, '/auth/verify-pwd', {
      password
    })
  ).then((r) => {
    if (r.ok) {
      return dispatch(fetchUser())
    } else return r
  })

export const setPassword =
  (password: string, token: string, replace: (location: string) => void) => (dispatch: ThunkDispatch) => {
    return dispatch(api.asyncPostJson(types.SET_PASSWORD, '/auth/set-pwd', { password, token })).then(() => {
      replace('/login')
    })
  }

export const logInAsPlanner = logInAs('planner')
export const logInAsReadOnlyPlanner = logInAs('read_only_planner')
export const logInAsPartner = logInAs('partner')
export const logInAsCustomer = logInAs('customer')
export const logInAsCustomsAgent = logInAs('customs_agent')
export const logInAsHandler = logInAs('handler')
export const logInAsAdmin = logInAs('admin')
export const logInAsPlusPlanner = logInAs('plus_planner')
