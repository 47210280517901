import { Collection } from 'immutable'
import isNumber from 'lodash/isNumber'

export enum MeasurementUnit {
  GRM = 'GRM', // g
  KGM = 'KGM', // kg
  MTQ = 'MTQ', // m3
  DMQ = 'DMQ', // dm3
  KMT = 'KMT', // km
  MTR = 'MTR', // m
  DMT = 'DMT', // dm
  CMT = 'CMT', // cm
  PPL = 'PPL' // ppl
}

export enum StandardUnitNotation {
  CMT = 'cm',
  KGM = 'kg'
}

export function convert(unitFrom: string, unitTo: string, value?: number | null) {
  if (isNumber(value) && unitTo && unitFrom) {
    const multiplier = getConversionMultiplier(unitFrom, unitTo)
    return value * multiplier
  } else {
    return value
  }
}

export function sum(values: Collection<number | string, number>): number {
  return values.reduce((acc, value) => acc + value, 0)
}

export function formatAmountWithUnit(value?: number, unit?: string): string {
  if (isNumber(value) && unit) {
    return `${formatAmount(value)} ${formatUnit(unit)}`
  } else if (isNumber(value) && !unit) {
    return formatAmount(value)
  } else {
    return ''
  }
}

export function formatUnit(unit: string): string {
  switch (unit.toUpperCase()) {
    case MeasurementUnit.GRM:
      return 'g'
    case MeasurementUnit.KGM:
      return 'kg'
    case MeasurementUnit.MTQ:
      return 'm³'
    case MeasurementUnit.DMQ:
      return 'dm³'
    case MeasurementUnit.KMT:
      return 'km'
    case MeasurementUnit.MTR:
      return 'm'
    case MeasurementUnit.DMT:
      return 'dm'
    case MeasurementUnit.CMT:
      return 'cm'
    default:
      return unit
  }
}

/**
 * This rounds a value to the given number of decimals. It is not always mathematically correct however.
 * Will round 1.3549999999999998 to 1.36 instead of 1.35 when rounding to two decimals.
 */
export function round(value: number, decimals: number): number {
  const scale = Math.pow(10, decimals)
  return Math.round((value + Number.EPSILON) * scale) / scale
}

function formatAmount(value: number): string {
  if (value > 10) {
    return value.toFixed(0)
  } else {
    return value.toFixed(1)
  }
}

function getConversionMultiplier(unitFrom: string, unitTo: string) {
  switch (unitFrom.toUpperCase() + unitTo.toUpperCase()) {
    case MeasurementUnit.GRM + MeasurementUnit.GRM:
      return 1
    case MeasurementUnit.GRM + MeasurementUnit.KGM:
      return 0.001
    case MeasurementUnit.KGM + MeasurementUnit.GRM:
      return 1000
    case MeasurementUnit.KGM + MeasurementUnit.KGM:
      return 1
    case MeasurementUnit.MTQ + MeasurementUnit.MTQ:
      return 1
    case MeasurementUnit.MTQ + MeasurementUnit.DMQ:
      return 1000
    case MeasurementUnit.DMQ + MeasurementUnit.MTQ:
      return 0.001
    case MeasurementUnit.DMQ + MeasurementUnit.DMQ:
      return 1
    case MeasurementUnit.MTR + MeasurementUnit.MTR:
      return 1
    case MeasurementUnit.MTR + MeasurementUnit.DMT:
      return 10
    case MeasurementUnit.MTR + MeasurementUnit.CMT:
      return 100
    case MeasurementUnit.DMT + MeasurementUnit.MTR:
      return 0.1
    case MeasurementUnit.DMT + MeasurementUnit.DMT:
      return 1
    case MeasurementUnit.DMT + MeasurementUnit.CMT:
      return 10
    case MeasurementUnit.CMT + MeasurementUnit.MTR:
      return 0.01
    case MeasurementUnit.CMT + MeasurementUnit.DMT:
      return 0.1
    case MeasurementUnit.CMT + MeasurementUnit.CMT:
      return 1
    case MeasurementUnit.KMT + MeasurementUnit.KMT:
      return 1
    case MeasurementUnit.KMT + MeasurementUnit.MTR:
      return 1000
    case MeasurementUnit.KMT + MeasurementUnit.DMT:
      return 10 * 1000
    case MeasurementUnit.KMT + MeasurementUnit.CMT:
      return 100 * 1000
    case MeasurementUnit.MTR + MeasurementUnit.KMT:
      return 0.001
    case MeasurementUnit.DMT + MeasurementUnit.KMT:
      return 0.1 * 0.001
    case MeasurementUnit.CMT + MeasurementUnit.KMT:
      return 0.01 * 0.001
    default:
      console.warn(`Unknown conversion from ${unitFrom} to ${unitTo}`)
      return 1
  }
}
