import { Collection, Map } from 'immutable'
import { GridRendererParams } from '../../components/grid/GridRenderers'
import { createImmutableEqualSelector } from '../../selectors/createImmutableEqualSelector'
import { Entity } from '../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../types/immutableTypes'

export type Comparator<T> = (v1: T, v2: T) => number

type DataType<T> = Collection<number, T>

export const defaultSortedSelector: <T>(state: DataType<T>, dataOrder: Map<number, number>) => DataType<T> =
  createImmutableEqualSelector(
    <T>(state: DataType<T>) => state,
    <T>(state: DataType<T>, dataOrder: Map<number, number>) => dataOrder,
    (state: any, dataOrder: Map<number, number>) => {
      return state.sort(lookupCompare(dataOrder))
    }
  )

export const sortedSelector: <T>(state: DataType<T>, sortFunction: Comparator<T>, descending: boolean) => DataType<T> =
  createImmutableEqualSelector(
    <T>(state: DataType<T>) => state,
    <T>(state: DataType<T>, sortFunction: Comparator<any>) => sortFunction,
    <T>(state: DataType<T>, sortFunction: Comparator<any>, descending: boolean) => descending,
    (state: any, sortFunction: Comparator<any>, descending) => {
      const sorted = state.sort(sortFunction)
      return descending ? sorted.reverse() : sorted
    }
  )

export function sortByColumn<T>(
  column: keyof T,
  valueRenderer?: (params: GridRendererParams) => string
): Comparator<ImmutableMap<T>> {
  return (v1: ImmutableMap<T>, v2: ImmutableMap<T>) => {
    if (valueRenderer) {
      return compare(
        valueRenderer({ column: column.toString(), value: v1.get(column), row: v1 }),
        valueRenderer({ column: column.toString(), value: v2.get(column), row: v2 })
      )
    } else {
      return compare(v1.get(column), v2.get(column))
    }
  }
}

export function compare<T>(a: T, b: T) {
  if (a === null && b === undefined) {
    return -1
  } else if (a === undefined && b === null) {
    return 1
  } else if (a === null || a === undefined) {
    return -1
  } else if (b === null || b === undefined) {
    return 1
  } else if (a === b) {
    return 0
  } else if (a > b) {
    return 1
  } else {
    return -1
  }
}

export function simpleCompare<T>(a: T, b: T) {
  if (a === b) {
    return 0
  } else if (a > b) {
    return 1
  } else {
    return -1
  }
}

export function lookupCompare<T>(dataOrder: Map<number, number>): Comparator<ImmutableMap<T>> {
  return (v1: Entity<T>, v2: Entity<T>) => simpleCompare(dataOrder.get(v1.get('id')), dataOrder.get(v2.get('id')))
}
