import { library } from '@fortawesome/fontawesome-svg-core'
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck'
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faExclamationTriangle as falExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faMapMarkerAltSlash } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAltSlash'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort'
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp'
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faMale } from '@fortawesome/free-solid-svg-icons/faMale'
import { faPallet } from '@fortawesome/free-solid-svg-icons/faPallet'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faArrowFromBottom } from '@fortawesome/pro-solid-svg-icons/faArrowFromBottom'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons/faArrowToBottom'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faPlane } from '@fortawesome/free-solid-svg-icons/faPlane'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch'
import { faRandom } from '@fortawesome/free-solid-svg-icons/faRandom'
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons/faCalendarTimes'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons/faSmileBeam'
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb'
import { faLink } from '@fortawesome/pro-regular-svg-icons'

export function initIconLibrary() {
  library.add(
    faSpinner,
    faTruck,
    faExchangeAlt,
    faTrash,
    faTrashAlt,
    faPencilAlt,
    faCheck,
    farCheck,
    faCheckCircle,
    falCheckCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faQuestionCircle,
    faInfoCircle,
    faExclamation,
    faExclamationTriangle,
    falExclamationTriangle,
    faExclamationCircle,
    faLink,
    faLock,
    faLockOpen,
    faClipboardCheck,
    faStar,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faMapMarkerAltSlash,
    faPlusCircle,
    faTimesCircle,
    faTimes,
    faBox,
    faEye,
    faEyeSlash,
    faCalendarAlt,
    farCalendarAlt,
    faDownload,
    faSort,
    faSortUp,
    faSortDown,
    faSignOutAlt,
    faMale,
    faPallet,
    faDollarSign,
    faCircle,
    faPlus,
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faLayerGroup,
    farSearch,
    faSearchPlus,
    faSearch,
    faEllipsisH,
    faEllipsisV,
    faFilter,
    faReply,
    faPhone,
    faArrowFromBottom,
    faArrowToBottom,
    faPlane,
    falTimes,
    falPlusCircle,
    faSyncAlt,
    faArrowLeft,
    faArrowRight,
    faExternalLink,
    farPen,
    faStopwatch,
    faRandom,
    faCalendarTimes,
    faEnvelope,
    faSmileBeam,
    faFile,
    faLightbulb,
    farSearch,
    farInfoCircle,
    farPlus,
    farTrashAlt
  )
}
