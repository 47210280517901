import { Map } from 'immutable'
import { AVAILABLE_DELIVERY_TIMES_ADD } from '../actions/actionTypes'

interface ActionType {
  type: string
  key: string
  value: any
}

const initialState = Map({})

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case AVAILABLE_DELIVERY_TIMES_ADD:
      return state.withMutations((mState) => {
        return mState.set(action.key, action.value)
      })
    default:
      return state
  }
}
