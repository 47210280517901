import { List } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { closeModal, setPageStateValue } from '../actions/creators/helpers'
import { H2, H3 } from '../primitives/Headings'
import { AppStateType } from '../utils/appStateReduxStore'
import { ColumnDefinition } from './grid/GridColumns'
import { Checkbox } from '../primitives/Forms'
import { PrimaryButton, SecondaryButton } from '../primitives/Button'
import i18next from 'i18next'
import { ShipmentProps } from '../types/coreEntitiesTypes'
import {
  getHiddenColumnsFromLocalStorage,
  storeHiddenColumnsToLocalStorage
} from './instantColumnsSettingsModalHelpers'
import ImmutableComponent from '../primitives/ImmutableComponent'
import { FlexRow } from '../primitives/Flex'

interface DispatchProps {
  setPageStateValue: (key: string, value: any) => void
  closeModal: (keepState: boolean) => void
}

interface StateProps {
  hiddenColumns: List<string>
}

interface OwnProps {
  columns: List<ColumnDefinition<ShipmentProps>>
  viewKey: string
}

type Props = StateProps & DispatchProps & OwnProps

const HIDDEN_COLUMNS = 'HIDDEN_COLUMNS'

class InstantColumnSettingsModal extends ImmutableComponent<Props> {
  onChanged = (event: any) => {
    const { hiddenColumns, viewKey, setPageStateValue } = this.props
    const dataIndex = event.target.name
    const newHiddenColumns = !event.target.checked
      ? hiddenColumns.concat([dataIndex])
      : hiddenColumns.filter((el) => el !== dataIndex)

    setPageStateValue(HIDDEN_COLUMNS, newHiddenColumns)
    storeHiddenColumnsToLocalStorage(viewKey, newHiddenColumns)
  }

  reset = () => {
    const { viewKey, setPageStateValue } = this.props
    const newHiddenColumns = List<string>()
    setPageStateValue(HIDDEN_COLUMNS, newHiddenColumns)
    storeHiddenColumnsToLocalStorage(viewKey, newHiddenColumns)
  }

  render() {
    const { hiddenColumns, columns, closeModal } = this.props

    return (
      <>
        <H2>{i18next.t('instant.columnSettings.settings')}</H2>
        <H3>{i18next.t('instant.columnSettings.hideColumns')}</H3>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '5px' }}>
          {columns
            .filter((oc) => !oc.hidden)
            .map((orderColumn) => (
              <div key={orderColumn.dataIndex} style={{ width: '33%', padding: '5px' }}>
                <Checkbox
                  checked={!hiddenColumns.includes(orderColumn.dataIndex)}
                  onChange={this.onChanged}
                  name={orderColumn.dataIndex}
                >
                  <span>{orderColumn.name}</span>
                </Checkbox>
              </div>
            ))}
        </div>
        <FlexRow justifyContent="end">
          <SecondaryButton type="button" onClick={this.reset}>
            {i18next.t('instant.columnSettings.reset')}
          </SecondaryButton>
          <PrimaryButton marginLeft type="button" onClick={() => closeModal(true)}>
            {i18next.t('instant.columnSettings.saveAndClose')}
          </PrimaryButton>
        </FlexRow>
      </>
    )
  }
}

export const getHiddenColumns = (state: any, viewKey: string) => {
  const userHiddenColumnsFromLocal = getHiddenColumnsFromLocalStorage(viewKey)
  return state.getIn(['pageState', `${HIDDEN_COLUMNS}.${viewKey}`], userHiddenColumnsFromLocal)
}

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state, ownProps) => {
    return {
      hiddenColumns: getHiddenColumns(state, ownProps.viewKey)
    }
  },
  {
    setPageStateValue,
    closeModal
  }
)(InstantColumnSettingsModal)
