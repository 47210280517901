import styled from 'styled-components'
import variables from '../styles/variables'

const ErrorContainer = styled.div<{ center?: boolean; noMargin?: boolean }>`
  padding: 1em;
  text-align: ${(props) => props.center && 'center'};
  background: ${variables.newColors.lightRed};
  color: ${variables.newColors.black};
  position: relative;
  max-height: 10rem;
  overflow: scroll;
  ${(props) => !props.noMargin && 'margin-bottom: 1em;'}
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`

export default ErrorContainer
