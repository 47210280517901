import { fromJS, Map } from 'immutable'
import { CLEAR_TOKEN_STATES, GET_USER, LOGOUT, REQUEST_TOKEN, VERIFY_TOKEN } from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map()

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_USER.SUCCESS:
      return fromJS(action.body)
    case LOGOUT.SUCCESS: {
      return Map()
    }
    case REQUEST_TOKEN.SUCCESS:
      return state.set('tokenRequested', true)
    case VERIFY_TOKEN.FAILURE:
      return state.set('verifyFailed', true)
    case CLEAR_TOKEN_STATES:
      return state.remove('tokenRequested').remove('verifyFailed')
    default:
      return state
  }
}
