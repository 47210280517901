import { AppStateType } from './appStateReduxStore'

export const ROLE_ADMIN = 'ADMIN'
export const ROLE_PLANNER = 'PLANNER'
export const ROLE_READ_ONLY_PLANNER = 'READ_ONLY_PLANNER'
export const ROLE_CUSTOMER = 'CUSTOMER'
export const ROLE_PLUS_PLANNER = 'PLUS_PLANNER'
export const ROLE_PARTNER = 'PARTNER'
export const ROLE_AIREXPRESS_HANDLER = 'HANDLER'
export const ROLE_AIREXPRESS_CUSTOMS_AGENT = 'CUSTOMS_AGENT'

export const adminOrPlannersWithWriteAccess = (role: string): boolean =>
  plannerTypesWithWriteAccess.concat(ROLE_ADMIN).includes(role.toUpperCase())

export const allPlannerTypes = [ROLE_PLANNER, ROLE_PLUS_PLANNER, ROLE_READ_ONLY_PLANNER, ROLE_PARTNER]
export const plannerTypesWithWriteAccess = [ROLE_PLANNER, ROLE_PLUS_PLANNER, ROLE_PARTNER]
export const strictAdminOrPlannerTypes = [ROLE_ADMIN, ROLE_PLANNER, ROLE_PLUS_PLANNER, ROLE_READ_ONLY_PLANNER]
export const plannerPlusOrAbove = [ROLE_ADMIN, ROLE_PLUS_PLANNER]

export const isAdminUser = (state: AppStateType): boolean => state.getIn(['user', 'role']) === ROLE_ADMIN
export const isPlannerPlusOrAbove = (state: AppStateType): boolean =>
  plannerPlusOrAbove.includes(state.getIn(['user', 'role']))
