import { Map } from 'immutable'

export function getDefaultPresetMeasurement(withSpecificationDescription?: boolean) {
  return withSpecificationDescription
    ? Map({
        presetDimension: '',
        weight: '',
        height: '',
        length: '',
        width: '',
        specificationDescription: '',
        volume: ''
      })
    : Map({ presetDimension: '', weight: '', height: '', length: '', width: '', volume: '' })
}

export function getDefaultPresetMeasurementForH2() {
  return Map({ packageId: '', weight: '', height: '', length: '', width: '' })
}
