import React, { useState } from 'react'
import variables from '../../../styles/variables'
import { IconFa, PlainButton } from '@glow/ui-components'
import { IRoutePointWithExtraDataProps } from '../../../query/instantCourierQuery'
import { Entity } from '../../../types/coreEntitiesTypes'
import { hourMinute } from '../../../utils/dateTime'
import i18next from 'i18next'
import { FormikValues } from 'formik'
import { Map } from 'immutable'
import { Row } from '../../../primitives/InstantGridStyles'
import { Break } from '../../../components/unit/UnitForm'
import { BreakForm } from '../../../components/break/BreakForm'
import { Duration } from 'luxon'

type BreakRowProps = {
  colSpan: number
  row: Entity<IRoutePointWithExtraDataProps>
  courierBreak: Map<string, string>
  editBreak: (values: FormikValues) => void
  updatedBreak?: Break
  newUnoptimizedBreak?: boolean
  deleteBreakFromRoute: (row: Entity<IRoutePointWithExtraDataProps>) => void
  canEditBreak: boolean
}

export const InstantBreakRow = ({
  colSpan,
  row,
  courierBreak,
  editBreak,
  updatedBreak,
  newUnoptimizedBreak,
  deleteBreakFromRoute,
  canEditBreak
}: BreakRowProps) => {
  const [editMode, setEditMode] = useState(false)
  const editBreakValues = (values: FormikValues) => {
    editBreak(values)
    setEditMode(false)
  }

  const breakDuration = Duration.fromISO(courierBreak.get('duration') || '').as('minutes')
  const updatedBreakDuration = updatedBreak?.duration && Duration.fromISO(updatedBreak?.duration || '').as('minutes')

  return (
    <Row rowBackgroundColor={variables.newColors.orbitGrey} rowBackgroundHoverColor={variables.newColors.orbitGrey}>
      <td colSpan={colSpan} style={{ overflow: 'visible' }}>
        {editMode ? (
          <BreakForm
            courierBreak={courierBreak}
            editBreakValues={editBreakValues}
            setEditMode={setEditMode}
            updatedBreak={updatedBreak}
            breakDuration={breakDuration}
            updatedBreakDuration={updatedBreakDuration}
          />
        ) : (
          <>
            <div className={'flex items-center justify-between px-4'}>
              <div className={'flex gap-6 items-center'} style={{ color: variables.newColors.black }}>
                <IconFa icon={['far', 'coffee']}></IconFa>
                {newUnoptimizedBreak ? (
                  <>
                    {updatedBreak && 'earliest' in updatedBreak ? (
                      <div>
                        <span style={{ color: variables.newColors.darkGrey }}>{i18next.t('courier.between')}:</span>{' '}
                        <span>{`${updatedBreak?.earliest} - ${updatedBreak?.latest}`}</span>
                      </div>
                    ) : (
                      <div>
                        <span style={{ color: variables.newColors.darkGrey }}>
                          {i18next.t('courier.maxWorkingTime')}:
                        </span>{' '}
                        <span>
                          {i18next.t('courier.hours', {
                            hours: Duration.fromISO(updatedBreak?.maxWorkingTime || '').hours
                          })}
                        </span>
                      </div>
                    )}
                    <div>
                      <span style={{ color: variables.newColors.darkGrey }}>{i18next.t('courier.breakTime')}:</span>{' '}
                      <span>
                        {updatedBreakDuration} {i18next.t('slot.minutes')}
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    <span style={{ color: variables.newColors.darkGrey }}>{i18next.t('courier.breakTime')}:</span>{' '}
                    <span>{`${hourMinute(row.get('arrival'))} - ${hourMinute(row.get('endTime') ?? null)}`}</span>
                  </div>
                )}
              </div>
              <div className={'flex gap-6'} style={{ color: variables.newColors.darkGrey }}>
                <PlainButton onClick={() => deleteBreakFromRoute(row)}>
                  <IconFa icon={['fas', 'trash']} />
                </PlainButton>
                {canEditBreak && (
                  <PlainButton onClick={() => setEditMode(true)}>
                    <IconFa icon={['fas', 'pen']} />
                  </PlainButton>
                )}
              </div>
            </div>
          </>
        )}
      </td>
    </Row>
  )
}
