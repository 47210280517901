import styled from 'styled-components'
import vars from '../styles/variables'

interface IArea {
  fullscreen?: boolean
  large?: boolean
  tall?: boolean
  listOverflow?: boolean
}

const Area = styled.div<IArea>`
  position: fixed;
  background-color: ${vars.colors.white};
  box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-width: ${(props) => (props.fullscreen ? '90vw' : props.large ? '1100px' : '1000px')};
  ${(props) => (props.tall ? 'min-height: 56vh;' : '')}
  max-height: 90vh;
  overflow-y: ${(props) => (props.listOverflow ? 'visible' : 'auto')};
  overflow-x: ${(props) => (props.listOverflow ? 'visible' : 'hidden')};
  margin: 0 auto;
  text-align: left;
  left: 0;
  right: 0;
  top: 50%;
  transition: transform 200ms;
  transform: translateY(-50%);
  z-index: 9999;
  padding: 30px;
`

export default Area
