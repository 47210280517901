import { List } from 'immutable'
import { Vehicle } from '../../../types/coreEntitiesTypes'

export const fuelTypesTranslationKeys = [
  'diesel',
  'gasoline',
  'natural_gas',
  'rme',
  'hvo',
  'ethanol',
  'bio_gas',
  'electric',
  'bicycle',
  'hydrogen'
]

export const filterAndSortVehicles = (vehicles: List<Vehicle>) => {
  const activeVehicles = vehicles.filter((vehicle) => vehicle.get('isActive'))
  return sortVehiclesByName(filterDuplicateVehicles(activeVehicles))
}

const filterDuplicateVehicles = (vehicles: List<Vehicle>) => {
  return vehicles.reduce((acc: List<Vehicle>, vehicle) => {
    const key = vehicleComparisonKey(vehicle)
    const existing = acc.find((v) => vehicleComparisonKey(v) === key)
    return existing ? acc : acc.push(vehicle)
  }, List<Vehicle>())
}

const vehicleComparisonKey = (vehicle: Vehicle) => {
  return `${vehicle.get('name')}|${vehicle.get('fuelType')}|${vehicle.get('capacity')}|${vehicle.get(
    'weightCapacity'
  )}|${vehicle.get('maxRangeKm')}|${vehicle.get('weightCapacity')}|${vehicle.get('volumeCapacity')}`
}

const sortVehiclesByName = (vehicles: List<Vehicle>) => {
  return vehicles.sortBy((v: Vehicle) => v.get('name', ''))
}
