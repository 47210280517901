import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk'
import { api, ConsumedResponse, QueryData } from '../../http/httpHelper'
import { httpDone } from '../../reducers/httpStatus'
import { AppStateType } from '../../utils/appStateReduxStore'
import { ActionType } from '../actionTypes'

export interface AnyData {
  [key: string]: any
}
export type ThunkResult<R> = ThunkAction<R, AppStateType, null, AnyAction>
export type ThunkDispatch = ReduxThunkDispatch<AppStateType, null, AnyAction>

export const fetchIfNotPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> =
  (actionType: ActionType, queryBody: QueryData) => (dispatch, getState) => {
    if (httpDone(getState, actionType, queryBody)) {
      return Promise.resolve(new Response(null, { status: 200 })) as Promise<ConsumedResponse>
    } else {
      return dispatch(api.asyncQuery(actionType, queryBody))
    }
  }

export const fetchEvenIfPresent: (
  actionType: ActionType,
  queryBody: QueryData
) => ThunkResult<Promise<ConsumedResponse>> = (actionType: ActionType, queryBody: QueryData) => (dispatch) =>
  dispatch(api.asyncQuery(actionType, queryBody))
