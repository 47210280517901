import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Trans } from 'react-i18next'
import Container from '../primitives/Container'
import { H2 } from '../primitives/Headings'

export const Loading: React.FC<{
  loadingText?: string
  loadingTextKey?: string
}> = ({ loadingText, loadingTextKey }) => (
  <main>
    <Container center>
      <H2 textAlign>
        <FontAwesomeIcon icon="spinner" pulse size="2x" />
        <br />
        <br />
        {loadingText}
        {!loadingText && <Trans i18nKey={loadingTextKey || 'loading'} />}
      </H2>
    </Container>
  </main>
)
