import { Label } from '../../primitives/Forms'
import i18next from 'i18next'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { required } from '../../utils/inputValidation'
import { ValidationError } from '../../primitives/ErrorMessages'
import React from 'react'
import { UnitSelect } from './UnitForm'
import { fuelTypesTranslationKeys } from '../../pages/planner/vehicle/utils'

export default ({ model }: { model: string }) => (
  <Label>
    <div>{i18next.t('courier.fuelType')} *</div>
    <UnitSelect expand white>
      <Control.select model={model} id="fuelType" validators={{ required }}>
        <option key={'fuelType-none'} value="">
          {i18next.t('courier.fuelType')}
        </option>
        {fuelTypesTranslationKeys.map((ft) => (
          <option key={`fuelType-${ft}`} value={ft}>
            {i18next.t(`courier.fuelTypes.${ft}`)}
          </option>
        ))}
      </Control.select>
    </UnitSelect>
    <Errors
      className="errors"
      model={model}
      show="touched"
      wrapper={ValidationError}
      messages={{ required: `${i18next.t('application.required')}` }}
    />
  </Label>
)
