import { Collection, Map } from 'immutable'
import { Entity } from '../../types/coreEntitiesTypes'
import { tuple } from '../../utils/tupleUtils'

export const reOrderData = <T>(data: Collection<number, Entity<T>>, dragIndex: number, dropIndex: number) => {
  const movedForward = dropIndex > dragIndex
  const result = data
    .valueSeq()
    .map((dataRow, index) => {
      if (movedForward) {
        if (index < dragIndex || index > dropIndex) return tuple(dataRow.get('id'), index)
        else if (index == dragIndex) return tuple(dataRow.get('id'), dropIndex)
        else return tuple(dataRow.get('id'), index - 1)
      } else {
        if (index > dragIndex || index < dropIndex) return tuple(dataRow.get('id'), index)
        else if (index == dragIndex) return tuple(dataRow.get('id'), dropIndex)
        else return tuple(dataRow.get('id'), index + 1)
      }
    })
    .toList()

  return Map(result)
}
