import { isBring } from '@glow/common'
import { SessionUser } from '../types/coreEntitiesTypes'

export const isAuthPath = () => location.pathname.indexOf('/auth/') !== -1

export const isOpenPath = () => location.pathname.indexOf('/open/') === 0

export const userRequiresPwdLogin = (user: SessionUser) => user.get('passwordLoginRequired')

const BRING_PRIVACY_POLICY_URL = 'https://www.bring.com/privacy-policy'
const GLOW_PRIVACY_POLICY_URL = 'https://glowtechnology.com/privacy-statement-for-glow'

export const getPrivacyPolicyUrl = () => (isBring() ? BRING_PRIVACY_POLICY_URL : GLOW_PRIVACY_POLICY_URL)
