import replace from 'lodash/replace'
import {
  ActionType,
  GET_DELIVERY_SERVICES_OPTIMUS,
  GET_DEPARTMENT_SERVICES_OPTIMUS,
  GET_HD_SERVICES_CHECKOUT,
  GET_PICKUP_SERVICES_OPTIMUS,
  GET_SERVICES_OPTIMUS,
  SAVED_SELECTED_ORDER_IDS,
  SEARCH_ORGANISATIONS_TWO,
  VALIDATE_POSTAL_CODE
} from '../actions/actionTypes'
import { AnyData, ThunkDispatch } from '../actions/creators/baseHelpers'
import { isReadOnlyUser } from '../actions/creators/helpers'
import { addNoAccessNotification } from '../actions/creators/helpersNotifications'
import { IErrorProps } from '../selectors/optimizeJobResultsSelector'
import { OrderIdType } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { validTrimmed } from '../utils/inputValidation'
import { isAuthPath, isOpenPath } from '../utils/loginUtils'

export interface ConsumedResponse extends Response {
  readBody?: any
}

export function Http(baseUrl: string) {
  const fullUrl = (path: string) => {
    return baseUrl + path
  }

  const fetchGet = (path: string): Promise<Response> =>
    fetch(fullUrl(path), {
      method: 'GET',
      credentials: 'include'
    })

  const fetchPost = (uriPath: string, body: AnyData) =>
    fetch(fullUrl(uriPath), {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })

  const asyncGet = (uriPath: string, actionType: ActionType) => asyncRequest(fetchGet(uriPath), actionType)

  const asyncPostRaw = (actionType: ActionType, uri: string, data: any, headers?: HeadersInit) =>
    asyncRequest(
      fetch(fullUrl(uri), {
        credentials: 'include',
        method: 'POST',
        body: data,
        headers
      }),
      actionType
    )

  const asyncRaw = (
    actionType: ActionType,
    method: string,
    path: string,
    body?: BodyInit | null,
    headers?: HeadersInit
  ) =>
    asyncRequest(
      fetch(fullUrl(path), {
        credentials: 'include',
        method,
        body,
        headers
      }),
      actionType
    )

  const asyncPostFormData = (actionType: ActionType, uri: string, data: FormData) => asyncPostRaw(actionType, uri, data)

  const asyncPostJson = (actionType: ActionType, uri: string, data: AnyData) =>
    asyncPostRaw(actionType, uri, JSON.stringify(data), { 'Content-Type': 'application/json' })

  const asyncQuery = (actionType: ActionType, data: QueryData) =>
    asyncRequest(
      fetch(fullUrl(`/query/${actionType.name}`), {
        credentials: 'include',
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(data)
      }),
      actionType,
      data
    )

  const asyncCommand =
    (actionType: ActionType, data: AnyData) => (dispatch: ThunkDispatch, getState: () => AppStateType) => {
      if (isReadOnlyUser(getState().get('user'))) {
        const msg = 'no access for user with role ' + getState().getIn(['user', 'role'])
        console.warn(msg)
        dispatch(addNoAccessNotification())
        throw msg
      } else return dispatch(asyncPostJson(actionType, `/command/${actionType.name}`, data))
    }

  const asyncDeleteCommand =
    (actionType: ActionType, data: AnyData, onSuccessRedirectUrl?: string, push?: (url: string) => void) =>
    async (dispatch: ThunkDispatch, getState: () => AppStateType) => {
      if (isReadOnlyUser(getState().get('user'))) {
        console.warn('no access for user with role', getState().getIn(['user', 'role']))
        return dispatch(addNoAccessNotification())
      }

      return dispatch(asyncCommand(actionType, data)).then(() => onSuccessRedirectUrl && push?.(onSuccessRedirectUrl))
    }

  return {
    fullUrl,
    fetchGet,
    fetchPost,
    asyncGet,
    asyncPostRaw,
    asyncRaw,
    asyncPostFormData,
    asyncPostJson,
    asyncQuery,
    asyncCommand,
    asyncDeleteCommand
  }
}

export type QueryData = {
  query: string
  params: AnyData
}

export const api = Http('/api')
export const openApi = Http('/openapi')
export const orderApi = Http('/glow-orders/api/external')
export const checkoutApi = Http('/checkout/api')
export interface ErrorType {
  body: any
  response: ConsumedResponse
  path: string
  url: string
  status: number
}

const paramsOrNull = (data?: AnyData) => (data ? data.params : null)

const asyncRequest = (requestFn: Promise<ConsumedResponse>, actionType: ActionType, data?: AnyData) => {
  return (dispatch: ThunkDispatch, getState: () => AppStateType) => {
    dispatch({
      type: actionType.REQUEST,
      params: paramsOrNull(data)
    })

    return requestFn
      .then((response) => {
        const contentType = response.headers.get('content-type')
        const r = contentType && contentType.indexOf('application/json') !== -1 ? response.json() : response.text()
        return r.then((jsonBody) => {
          if (response.ok) {
            dispatch({
              params: paramsOrNull(data),
              isEntity: actionType.isEntity,
              type: actionType.SUCCESS,
              status: response.status,
              body: jsonBody
            })

            response.readBody = jsonBody
            return response
          } else {
            const pathToCheck = window.location.pathname.split('?')[0] || window.location.pathname
            if (
              response.status === 401 &&
              pathToCheck !== '/' &&
              pathToCheck !== '/login' &&
              !isAuthPath() &&
              !isOpenPath() &&
              !getState()?.get('user')?.has('name')
            ) {
              window.location.href = '/?redirect-to=' + window.location.pathname
            } else {
              dispatch({
                type: actionType.FAILURE,
                status: response.status,
                body: jsonBody,
                url: response.url
              })
            }
            return Promise.reject({
              body: jsonBody,
              response: response,
              path: window.location.pathname,
              url: response.url,
              status: response.status
            })
          }
        })
      })
      .catch((err) => {
        console.error({
          type: 'HTTP-Failure',
          action: actionType.REQUEST,
          err: JSON.stringify(err)
        })
        return Promise.reject(err)
      })
  }
}

export const getFirstErrorMessage = (err: any): IErrorProps | undefined =>
  Array.isArray(err.body) ? (err.body.find((x: IErrorProps) => x.description !== '') as IErrorProps) : undefined

export const getOrganisationsFromTwo =
  (searchString: string, limit?: string, offset?: string) => (dispatch: ThunkDispatch) => {
    const searchUrl = `https://no.search.tillit.ai/search?q=${searchString || ''}&offset=${
      offset ? offset : '0'
    }&limit=${limit ? limit : '10'}`
    return dispatch(
      asyncRequest(
        fetch(searchUrl, {
          method: 'GET'
        }),
        SEARCH_ORGANISATIONS_TWO
      )
    )
  }

export const getStandardServicesForDepartment = (departmentAlystraId: string) => (dispatch: ThunkDispatch) => {
  return dispatch(
    orderApi.asyncGet(`/optimus/services?departmentAlystraId=${departmentAlystraId}`, GET_DEPARTMENT_SERVICES_OPTIMUS)
  )
}

export const getPickupServicesFromOptimus =
  (customerNumber: string, subcustomer: string | null, pickupCountryCode: string, pickupPostalCode: string) =>
  (dispatch: ThunkDispatch) => {
    const pickupPostalCodeTrimmed = validTrimmed(pickupPostalCode) ? replace(pickupPostalCode, ' ', '') : null

    const servicesUrl = `/optimus/services?alystraCustomerNo=${customerNumber || ''}&subcustomer=${
      subcustomer || ''
    }&pickupCountryCode=${pickupCountryCode || ''}&pickupPostalCode=${pickupPostalCodeTrimmed || ''}`

    return dispatch(orderApi.asyncGet(servicesUrl, GET_PICKUP_SERVICES_OPTIMUS))
  }

export const getDeliveryServicesFromOptimus =
  (customerNumber: string, subcustomer: string | null, deliveryCountryCode?: string, deliveryPostalCode?: string) =>
  (dispatch: ThunkDispatch) => {
    const deliveryPostalCodeTrimmed =
      deliveryPostalCode && validTrimmed(deliveryPostalCode) ? replace(deliveryPostalCode, ' ', '') : null

    const servicesUrl = `/optimus/services?alystraCustomerNo=${customerNumber || ''}&subcustomer=${
      subcustomer || ''
    }&deliveryCountryCode=${deliveryCountryCode || ''}&deliveryPostalCode=${deliveryPostalCodeTrimmed || ''}`
    return dispatch(orderApi.asyncGet(servicesUrl, GET_DELIVERY_SERVICES_OPTIMUS))
  }

export const getServicesFromOptimus =
  (
    customerNumber: string,
    subcustomer: string | null,
    pickupCountryCode: string,
    pickupPostalCode: string,
    deliveryCountryCode?: string,
    deliveryPostalCode?: string
  ) =>
  (dispatch: ThunkDispatch) => {
    const pickupPostalCodeTrimmed = validTrimmed(pickupPostalCode) ? replace(pickupPostalCode, ' ', '') : null
    const deliveryPostalCodeTrimmed =
      deliveryPostalCode && validTrimmed(deliveryPostalCode) ? replace(deliveryPostalCode, ' ', '') : null

    const servicesUrl = `/optimus/services?alystraCustomerNo=${customerNumber || ''}&subcustomer=${
      subcustomer || ''
    }&pickupCountryCode=${pickupCountryCode || ''}&pickupPostalCode=${
      pickupPostalCodeTrimmed || ''
    }&deliveryCountryCode=${deliveryCountryCode || ''}&deliveryPostalCode=${deliveryPostalCodeTrimmed || ''}`
    return dispatch(orderApi.asyncGet(servicesUrl, GET_SERVICES_OPTIMUS))
  }

export const getHdServices =
  (customerNumber: string, subcustomer: string | null, deliveryCountryCode: string, deliveryPostalCode: string) =>
  (dispatch: ThunkDispatch) => {
    const deliveryPostalCodeTrimmed =
      deliveryPostalCode && validTrimmed(deliveryPostalCode) ? replace(deliveryPostalCode, ' ', '') : null

    const servicesUrl = `/hd-services?customerNumber=${customerNumber}&subcustomer=${
      subcustomer || ''
    }&deliveryCountryCode=${deliveryCountryCode}&deliveryPostalCode=${deliveryPostalCodeTrimmed}`
    return dispatch(checkoutApi.asyncGet(servicesUrl, GET_HD_SERVICES_CHECKOUT))
  }

export const validatePostalCode = (postalCode: string, countryCode: string) => (dispatch: ThunkDispatch) =>
  dispatch(
    asyncRequest(
      fetch(`/openapi/validate/postalCode?countryCode=${countryCode}&postalCode=${postalCode}`, {
        method: 'GET',
        credentials: 'include'
      }),
      VALIDATE_POSTAL_CODE
    )
  ).then(
    (res) => res.readBody.isValid,
    (err) => {
      console.error('validatePostalCode', err)
      return false
    }
  )

export const asyncOptimusCreateOrders =
  (actionType: ActionType, data: AnyData) => (dispatch: ThunkDispatch, getState: () => AppStateType) => {
    if (isReadOnlyUser(getState().get('user'))) {
      const msg = 'no access for user with role ' + getState().getIn(['user', 'role'])
      console.warn(msg)
      dispatch(addNoAccessNotification())
      throw msg
    } else return dispatch(orderApi.asyncPostJson(actionType, '/create-order', data))
  }

export const saveSelectedOrderIds = (selectedOrderIds: OrderIdType[]) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: SAVED_SELECTED_ORDER_IDS,
    selectedOrderIds,
    url: document.location.href
  })
}
