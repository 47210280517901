import { Map } from 'immutable'
import { TURN_OFF_WEBSOCKETS } from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map({ active: true })

export const websocket = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TURN_OFF_WEBSOCKETS:
      return state.set('active', false)
    default:
      return state
  }
}
