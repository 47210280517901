import styled from 'styled-components'
import variables from '../styles/variables'

export const WhiteBox = styled.div`
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 3px;
  background-color: ${variables.colors.white};
`

export const PaddedWhiteBox = styled(WhiteBox)`
  padding: 5px;
`

interface InstantSlotOverviewProps {
  vertical?: boolean
  small?: boolean
  medium?: boolean
}

// There's been some problems with drag and drop related to the flexible height of this container (don't know the
// actual cause), so it's important that we use a fixed height here, and not min-height and max-height.
export const InstantSlotOverviewContainer = styled(PaddedWhiteBox)<InstantSlotOverviewProps>`
  width: ${(props) => (props.vertical ? '175px' : 'initial')};
  height: ${(props) => (props.vertical ? '100%' : props.small ? '76px' : props.medium ? '138px' : '196px')};
  margin-top: ${(props) => (props.vertical ? '0px' : '10px')};
  margin-bottom: ${(props) => (props.vertical ? '0px' : '10px')};
  overflow-y: auto;
`

export const FilterBar = styled(PaddedWhiteBox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  min-height: 60px;
`

export const BottomStuff = styled(PaddedWhiteBox)`
  min-width: 500px;
  height: 35vh;
  min-height: 300px;
  resize: vertical;
  overflow-y: auto;
`

interface ResizableContainerProps {
  direction: 'vertical' | 'horizontal'
}

export const ResizableContainer = styled.div<ResizableContainerProps>`
  resize: ${(props) => props.direction};
  overflow: auto;
`
