import { fromJS, List, Map } from 'immutable'
import { GET_ARTICLES } from '../actions/actionTypes'

const initialState = Map()

interface ArticlesDataAction {
  type: typeof GET_ARTICLES.name
  body: Map<number, List<unknown>>
}

export default function reducer(state = initialState, action: ArticlesDataAction) {
  switch (action.type) {
    case GET_ARTICLES.SUCCESS:
      return state.set('data', fromJS(action.body).valueSeq())
    default:
      return state
  }
}
