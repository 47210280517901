import { List, Map } from 'immutable'

const USER_HIDDEN_COLUMNS = 'USER_HIDDEN_COLUMNS'

const DEFAULT_HIDDEN_COLUMNS = Map({
  InstantOrdersGrid: List([
    'customerRef',
    'pickupContactPerson',
    'recipientRef',
    'specificationDescription',
    'shipmentId',
    'custAlystraSubcustomer',
    'note',
    'source',
    'deliveryContactPerson',
    'custContact'
  ]),
  ShipmentPageGrid: List([
    'customerRef',
    'pickupContactPerson',
    'recipientRef',
    'specificationDescription',
    'pickupZipArea',
    'deliveryZipArea',
    'shipmentId',
    'note',
    'isReturned',
    'netCustomerPrice',
    'source',
    'deliveryContactPerson',
    'custContact',
    'plannedDeliveryDate',
    'slotName',
    'originalPickupAddress',
    'originalPickupZipCode',
    'originalPickupZipArea',
    'originalPickupCountry',
    'originalDeliveryAddress',
    'originalDeliveryZipCode',
    'originalDeliveryZipArea',
    'originalDeliveryCountry',
    'originalDeliveryTimeEarliest',
    'originalDeliveryTimeLatest'
  ]),
  CustomerShipmentPageGrid: List([
    'customerRef',
    'pickupContactPerson',
    'recipientRef',
    'specificationDescription',
    'pickupZipArea',
    'deliveryZipArea',
    'shipmentId',
    'isReturned',
    'netCustomerPrice',
    'custContact'
  ])
})

export const storeHiddenColumnsToLocalStorage = (viewKey: string, hiddenColumns: List<string>): void =>
  localStorage.setItem(`${USER_HIDDEN_COLUMNS}.${viewKey}`, JSON.stringify(hiddenColumns.toJS()))

export const getHiddenColumnsFromLocalStorage = (viewKey: string) => {
  const ls = localStorage.getItem(`${USER_HIDDEN_COLUMNS}.${viewKey}`)
  return ls !== null ? List(JSON.parse(ls)) : DEFAULT_HIDDEN_COLUMNS.get(viewKey, List())
}
