import { fromJS, List, Map } from 'immutable'
import { UPLOAD_EXCEL_RECIPIENTS } from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map({
  recipients: List()
})

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case UPLOAD_EXCEL_RECIPIENTS.SUCCESS:
      return Map({
        recipients: fromJS(action.body)
      })
    default:
      return state
  }
}
