import { fromJS, List, Map } from 'immutable'
import { GET_ARTICLES_IN_GROUP } from '../actions/actionTypes'

const initialState = Map()

interface ArticleGroupDataAction {
  type: typeof GET_ARTICLES_IN_GROUP.name
  body: List<unknown>
}

export default function reducer(state = initialState, action: ArticleGroupDataAction) {
  switch (action.type) {
    case GET_ARTICLES_IN_GROUP.SUCCESS:
      return state.set('data', fromJS(action.body).valueSeq())
    default:
      return state
  }
}
