import { List, Map, Seq, Set } from 'immutable'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { DateTime, Interval } from 'luxon'
import { UnitIdType, DepartmentIdType, Slot, SlotIdType } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { CustomerIdType } from '../domain/customer'
import { nameIsDashOrEmpty, nameStartsWithAlphabet, nameStartsWithNumber } from '../utils/stringUtils'
import { sortByName } from '../pages/instant/bookingOrderFunctions'

export const slotsSelector: (state: AppStateType) => List<Slot> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'slots']),
  (slots) => (slots ? slots.valueSeq() : List())
)

export const slotsAsMapSelector: (state: AppStateType) => Map<SlotIdType, Slot> = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['entities', 'slots']),
  (slots) => (slots ? slots : Map())
)

export const slotByIdSelector: (state: AppStateType, slotId: SlotIdType) => Slot = createImmutableEqualSelector(
  (state: AppStateType, slotId: SlotIdType) => state.getIn(['entities', 'slots', slotId]),
  (slot) => slot || Map()
)

const slotsForDateSelector: (state: AppStateType, currentDate: DateTime) => List<Slot> = createImmutableEqualSelector(
  slotsSelector,
  (state: AppStateType, currentDate: DateTime) => currentDate.toISODate(),
  (slots, currentDate: string) =>
    slots.filter((it: Slot) => currentDate === DateTime.fromISO(it.get('timeFrom')).toISODate())
)

export const sortedSlotsForDateAndDepartmentSelector: (
  state: AppStateType,
  currentDate: DateTime,
  departmentId: DepartmentIdType
) => List<Slot> = createImmutableEqualSelector(
  slotsForDateSelector,
  (state: AppStateType, currentDate: DateTime, departmentId: DepartmentIdType) => departmentId,
  (slots, departmentId) => {
    const slotsForDepartment = slots.filter((slot) => slot.get('departmentId') === departmentId)
    const sortedNamedSlots = slotsForDepartment.filter(nameStartsWithAlphabet).sort(sortByName)
    const sortedNumberedSlots = slotsForDepartment.filter(nameStartsWithNumber).sort(sortByName)
    const sortedSlotsWithEmptyNameOrDash = slotsForDepartment.filter(nameIsDashOrEmpty).sort(sortByName)
    return sortedNamedSlots.concat(sortedNumberedSlots).concat(sortedSlotsWithEmptyNameOrDash)
  }
)

export const slotsByDateAndDepartmentIdSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType,
  currentDate: DateTime
) => List<Slot> = createImmutableEqualSelector(
  (state: AppStateType, departmentId: DepartmentIdType, currentDate: DateTime) =>
    state
      .getIn(['entities', 'slots'])
      .filter(
        (it: Slot) =>
          it.get('departmentId') === departmentId &&
          currentDate.toISODate() === DateTime.fromISO(it.get('timeFrom')).toISODate()
      ),
  (slots) => slots.valueSeq().sortBy((s: Slot) => s.get('id'))
)

export const slotsByDateAndDepartmentIdsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  currentDate: DateTime
) => List<Slot> = createImmutableEqualSelector(
  (state: AppStateType, departmentIds: List<DepartmentIdType>, currentDate: DateTime) =>
    state
      .getIn(['entities', 'slots'])
      .filter(
        (it: Slot) =>
          departmentIds.contains(it.get('departmentId')) &&
          currentDate.toISODate() === DateTime.fromISO(it.get('timeFrom')).toISODate()
      ),
  (slots) => slots.valueSeq().sortBy((s: Slot) => s.get('id'))
)
export const slotsByDateAndDepartmentIdsAsMapSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  currentDate: DateTime
) => Map<number, Slot> = createImmutableEqualSelector(
  (state: AppStateType, departmentIds: List<DepartmentIdType>, currentDate: DateTime) =>
    state
      .getIn(['entities', 'slots'])
      .filter(
        (it: Slot) =>
          departmentIds.contains(it.get('departmentId')) &&
          currentDate.toISODate() === DateTime.fromISO(it.get('timeFrom')).toISODate()
      ),
  (slots) => slots.sortBy((s: Slot) => s.get('id'))
)

export const slotsCourierIdsSetSelector = createImmutableEqualSelector(slotsByDateAndDepartmentIdSelector, (slots) =>
  Set(slots.map((it) => it.get('courierId')))
)

export const slotsByCourierIdSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType,
  currentDate: DateTime
) => Map<UnitIdType, List<Slot>> = createImmutableEqualSelector(
  slotsByDateAndDepartmentIdSelector,
  (slots) => slots.groupBy((it) => it.get('courierId')).toMap() as Map<UnitIdType, List<Slot>>
)

export const slotsByCourierIdSelectorForDepartments: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>,
  currentDate: DateTime
) => Map<UnitIdType, List<Slot>> = createImmutableEqualSelector(
  slotsByDateAndDepartmentIdsSelector,
  (slots) => slots.groupBy((it) => it.get('courierId')).toMap() as Map<UnitIdType, List<Slot>>
)

export const slotsByDateAndCustomerIdsSelector: (
  state: AppStateType,
  customerIds: List<CustomerIdType>,
  currentDate: DateTime
) => List<Slot> = createImmutableEqualSelector(
  (state: AppStateType, customerIds: List<CustomerIdType>, currentDate: DateTime) =>
    state
      .getIn(['entities', 'slots'])
      .filter(
        (it: Slot) =>
          currentDate.toISODate() === DateTime.fromISO(it.get('timeFrom')).toISODate() &&
          customerIds.contains(it.get('custId'))
      ),
  (slots) => slots.valueSeq()
)

export const slotsWithinTimeRangeSelector: (slots: List<Slot>, interval: Interval) => List<Slot> =
  createImmutableEqualSelector(
    (slots: List<Slot>, interval: any) =>
      slots.filter((slot) => {
        const from = DateTime.fromISO(slot.get('timeFrom'))
        const to = DateTime.fromISO(slot.get('timeTo'))
        const slotInterval = Interval.fromDateTimes(from, to)
        return interval.overlaps(slotInterval)
      }),
    (result) => result
  )

export const slotsByDateAndUnitsSelector: (
  state: AppStateType,
  currentDate: DateTime,
  unitIds: Seq.Indexed<UnitIdType>
) => List<Slot> = createImmutableEqualSelector(
  slotsForDateSelector,
  (state: AppStateType, currentDate: DateTime, unitIds: Seq.Indexed<UnitIdType>) => unitIds,
  (slots: List<Slot>, unitIds) => slots.filter((slot) => unitIds.includes(slot.get('courierId')))
)

export const slotsByIdsSelector: (state: AppStateType, slotIds: List<SlotIdType>) => Map<SlotIdType, Slot> =
  createImmutableEqualSelector(
    (state: AppStateType) => (state.getIn(['entities', 'slots']) as Map<SlotIdType, Slot>) || Map(),
    (state: AppStateType, slotIds: List<SlotIdType>) => slotIds,
    (slots, slotIds) => slots.filter((slot: Slot) => slotIds.includes(slot.get('id')))
  )
