import { Set } from 'immutable'
import { createImmutableEqualSelector } from '../selectors/createImmutableEqualSelector'
import { setCourierLocation } from '../actions/creators/helpers'
import { DateTime } from 'luxon'
import throttle from 'lodash/throttle'
import { slotsSelector } from '../selectors/slotSelectors'
import { Store } from 'redux'
import { isNullOrUndefined } from '../types/immutableTypes'

export function initCourierLocationWatcher(store: Store) {
  if (!navigator.geolocation) {
    console.error('Current browser does not support geolocation')
    return
  }

  const slotsStatesSelector = createImmutableEqualSelector(slotsSelector, (slots) =>
    Set(slots.map((it) => it.get('state')))
  )

  let watchId: number | null = null

  store.subscribe(() => {
    const state = store.getState()
    if (isNullOrUndefined(state)) {
      console.error('state could not be populated from store, this should never happen!!')
      return
    }

    const slotsStates = slotsStatesSelector(state)

    if (state.getIn(['user', 'role']) === 'COURIER' && slotsStates.has('IN_PROGRESS')) {
      if (watchId !== null) {
        return
      }

      const courierId = state.getIn(['user', 'courierId'])
      if (typeof courierId !== 'number') {
        console.error('Not tracking location, courierId was not a number', courierId)
        return
      }

      const doPositionCall = throttle((pos) => {
        setCourierLocation(
          courierId,
          pos.coords.latitude,
          pos.coords.longitude,
          pos.coords.accuracy,
          DateTime.fromMillis(pos.timestamp || new Date().getTime()).toISO()
        )(store.dispatch)
      }, 60000)

      watchId = navigator.geolocation.watchPosition(
        (pos) => {
          doPositionCall(pos)
        },
        (err) => {
          console.error('Geolocation error occurred', err)
        },
        {
          enableHighAccuracy: true, // We only care about actual GPS data
          timeout: Infinity, // We want to wait until we have actual GPS data
          maximumAge: 0 // We don't want cached values
        }
      )
    }
  })
}
