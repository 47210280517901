import { List } from 'immutable'
import toString from 'lodash/toString'
import { Consignment } from '../../types/coreEntitiesTypes'
import { getServiceCode, selectedService } from '../../utils/alystraUtils'
import { IOptimusService } from '../../domain/optimusService'

export function getServiceId(
  firstConsignment: Consignment,
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>
) {
  const service = findService(firstConsignment, services, customizedServices)
  if (service) {
    return getServiceCode(service)
  } else {
    return ''
  }
}

export function findService(
  firstConsignment: Consignment,
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>
) {
  const xmlServiceCode = firstConsignment.get('xmlServiceCode')
  const serviceCode = firstConsignment.get('serviceCode')
  const vasCodes = firstConsignment.get('vasCodes')

  const customizedService = filterServicesBasedOnAlystraServiceCodeWithPlaceholders(customizedServices, serviceCode)
  if (!customizedService.isEmpty()) {
    return customizedService.get(0)
  }

  const service = filterServicesBasedOnAlystraServiceCodeWithPlaceholders(services, serviceCode)
  if (!service.isEmpty()) {
    return service.get(0)
  }

  if (vasCodes.isEmpty()) {
    return selectedService(services, customizedServices, xmlServiceCode, List())
  } else {
    return selectedService(services, customizedServices, xmlServiceCode, vasCodes)
  }
}

function filterServicesBasedOnAlystraServiceCodeWithPlaceholders(services: List<IOptimusService>, serviceCode: string) {
  return services.filter((service) => {
    return getPossibleServicesWithEvening(service, getPossibleServicesWithWeight(service)).contains(serviceCode)
  })
}

function getPossibleServicesWithWeight(service: IOptimusService) {
  if (toString(service.get('alystraServiceCode')).includes('{WEIGHT}')) {
    return List(['25', '50', '150', '500', '1000', '1500', '3000', '5000', '8000']).map((codeWithWeight) => {
      return service.get('alystraServiceCode').replace('{WEIGHT}', codeWithWeight)
    })
  } else {
    return List([service.get('alystraServiceCode')])
  }
}

function getPossibleServicesWithEvening(service: IOptimusService, possibleServiceCodesWithWeight: List<string>) {
  if (toString(service.get('alystraServiceCode')).includes('{EVENING}')) {
    return possibleServiceCodesWithWeight
      .map((it) => {
        return it.replace('{EVENING}', '-E')
      })
      .concat(
        possibleServiceCodesWithWeight.map((it) => {
          return it.replace('{EVENING}', '-D')
        })
      )
  } else {
    return possibleServiceCodesWithWeight
  }
}
