import React, { ReactElement, useState } from 'react'
import {
  required,
  requiredImmutable,
  validNumber,
  validNumberOrEmpty,
  validNumberWithinRange
} from '../../utils/inputValidation'
import { H2 } from '../../primitives/Headings'
import i18next from 'i18next'
import ErrorContainer from '../../primitives/ErrorContainer'
import { Label, TextAreaInput } from '../../primitives/Forms'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import { ValidationError } from '../../primitives/ErrorMessages'
import ValidationInput from '../ValidationInput'
import Select from '../../primitives/Select'
import { vehicleType } from '../../pages/instant/bookingOrderFunctions'
import FuelTypeInput from './FuelTypeInput'
import ColorInput from './ColorInput'
import RangeSliderSingle from '../../pages/planner/RangeSliderSingle'
import { speedFactorHeader } from '../../utils/slider'
import { PrimaryButton } from '../../primitives/Button'
import { List, Map } from 'immutable'
import { UnitStartStop } from './UnitStartStopLocation'
import { CapacityInput } from './CapacityInput'
import { ImmutableMap, ImmutableMapFactory } from '../../types/immutableTypes'
import { Half } from '../../primitives/Layout'
import styled from 'styled-components'
import { CourierUserWithActionProps } from './CourierUserGrid'
import { CourierUserId } from '../../types/coreEntitiesTypes'
import { isBring } from '@glow/common'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../utils/appStateReduxStore'
import { validBreakTimeWindow } from './breakInputValidation'
import { BreakFormFragment } from './BreakFormFragment'
import { BreakType } from '../../actions/creators/helpers'
import { isEmpty } from '../../utils/stringUtils'

interface Props {
  model: string
  heading: string
  showReturnCourierMap: boolean
  lat: number
  lng: number
  errorMessages: List<string> | null
  onSubmit: (unit: ImmutableMap<UnitFormProps>) => void
  changeFormValue: (model: string, value: any) => void
  children?: ReactElement | ReactElement[]
  initialValues?: { vehicleControl: string }
}

export interface UnitFormProps {
  name: string
  vehicleType: string
  alystraId: string
  capacity: string
  weightCapacity: string
  volumeCapacity: string
  speedFactor: [number]
  serviceTimeFactor: [number]
  isActive: boolean
  startLocation: Map<string, number>
  endLocation: Map<string, number>
  shouldDriverNotReturn: boolean
  fuelType: string
  color: string
  courierId?: number
  maxRangeKm: string
  courierUsers: Map<CourierUserId, ImmutableMap<CourierUserWithActionProps>>
  isUnit: boolean
  registrationNumber: string
  commercialTrafficEligibility?: boolean
  vehicleBrand: string
  vehicleModel: string
  vehicleControl: string
  vehicleControlComment: string
  euroClass: string
  co2: string
  breakTimeWindowDuration: string
  breakEarliest: string
  breakLatest: string
  breakType: string
  breakMaxWorkingTime: string
  breakWorkingTimeDuration: string
}

export interface UnitFormPropsType extends ImmutableMap<UnitFormProps> {}

export type Break = TimeWindowBreak | WorkingTimeBreak

export type TimeWindowBreak = {
  type: string
  earliest: string
  latest: string
  duration: string
} | null

export type WorkingTimeBreak = {
  type: string
  duration: string
  maxWorkingTime: string
} | null

export const initialUnitFormState = ImmutableMapFactory<UnitFormProps>({
  startLocation: Map({ lat: 0, lng: 0 }),
  endLocation: Map({ lat: 0, lng: 0 }),
  speedFactor: [1.0],
  serviceTimeFactor: [1.0],
  isActive: true,
  capacity: '',
  fuelType: '',
  weightCapacity: '',
  volumeCapacity: '',
  color: '#ffffff',
  alystraId: '',
  name: '',
  vehicleType: '',
  shouldDriverNotReturn: false.valueOf(),
  maxRangeKm: '',
  courierUsers: Map(),
  isUnit: true,
  registrationNumber: '',
  vehicleBrand: '',
  vehicleModel: '',
  vehicleControl: '',
  vehicleControlComment: '',
  euroClass: '',
  co2: '',
  breakEarliest: '',
  breakLatest: '',
  breakTimeWindowDuration: '',
  breakType: BreakType.NoBreakTime,
  breakMaxWorkingTime: '',
  breakWorkingTimeDuration: ''
})

export const UnitSelect = styled(Select)`
  select {
    padding: 0.75rem;
  }
`

enum VehicleControlType {
  APPROVED = 'approved',
  NOT_APPROVED = 'notApproved'
}

const euroClassType = List.of('euroEL', 'euro5', 'euro6')
const vehicleControlType = List.of(VehicleControlType.APPROVED, VehicleControlType.NOT_APPROVED)

export default ({
  model,
  heading,
  showReturnCourierMap,
  lat,
  lng,
  errorMessages,
  onSubmit,
  changeFormValue,
  children,
  initialValues
}: Props) => {
  const [vehicleControlValue, setVehicleControlValue] = useState(initialValues?.vehicleControl || '')

  const formValues = useSelector((state: AppStateType) => {
    return state.getIn([model])
  })

  const validTimeWindow = () => {
    const earliest = formValues.get('breakEarliest')
    const latest = formValues.get('breakLatest')
    const breakDuration = formValues.get('breakTimeWindowDuration')
    return (
      formValues.get('breakType') !== BreakType.TimeWindow ||
      (required(earliest) && required(latest) && validBreakTimeWindow(earliest, latest, breakDuration))
    )
  }

  const requiredIfTimeWindow = (value: string) => {
    return (
      formValues.get('breakType') !== BreakType.TimeWindow || !isEmpty(value) || (validTimeWindow() && required(value))
    )
  }

  const requiredIfWorkingTimeDuration = (value: string) => {
    return formValues.get('breakType') !== BreakType.WorkingTime || required(value)
  }

  const requiredIfMaxWorkingTime = (value: string) => {
    return formValues.get('breakType') !== BreakType.WorkingTime || required(value)
  }

  return (
    <Form
      model={model}
      onSubmit={onSubmit}
      validators={{
        name: { required },
        capacity: { required, validNumberWithinRange },
        speedFactor: { validNumber },
        serviceTimeFactor: { validNumber },
        startLocation: { required },
        endLocation: { required },
        alystraId: { required },
        vehicleType: { required },
        courierUsers: { requiredImmutable },
        breakTimeWindowDuration: { requiredIfTimeWindow, validNumberOrEmpty },
        breakEarliest: { validTimeWindow },
        breakLatest: { validTimeWindow },
        breakMaxWorkingTime: { requiredIfWorkingTimeDuration: requiredIfWorkingTimeDuration },
        breakWorkingTimeDuration: { requiredIfMaxWorkingTime, validNumberOrEmpty }
      }}
    >
      <H2>{heading}</H2>
      {errorMessages && <ErrorContainer>{errorMessages.join(', ')}</ErrorContainer>}
      <div style={{ display: 'flex', clear: 'both' }}>
        <Half marginRight>
          <Label>
            {i18next.t('courier.name')} *
            <Control.text model=".name" id="name" component={ValidationInput} />
            <Errors
              className="errors"
              model=".name"
              show="touched"
              wrapper={ValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </Label>
          <Label>
            <div>{i18next.t('instant.booking.vehicleType')} *</div>
            <UnitSelect expand white>
              <Control.select model=".vehicleType" id="vehicleType" validators={{ required }}>
                <option key={0} value={''}>
                  {i18next.t('instant.booking.selectVehicleType')}
                </option>
                {vehicleType.map((vt) => {
                  return (
                    <option key={`vehicleType-${vt}`} value={vt}>
                      {i18next.t(`instant.booking.vehicleTypes.${vt}`)}
                    </option>
                  )
                })}
              </Control.select>
              <Errors
                className="errors"
                model=".vehicleType"
                show="touched"
                wrapper={ValidationError}
                messages={{ required: `${i18next.t('application.required')}` }}
              />
            </UnitSelect>
          </Label>
          <CapacityInput model=".capacity" isRequired={true} />
          <CapacityInput model=".weightCapacity" isRequired={true} />
          <Label>
            {i18next.t('courier.registrationNumber')}
            <Control.text
              model=".registrationNumber"
              id="registrationNumber"
              component={ValidationInput}
              placeholder={i18next.t('courier.registrationNumber')}
            />
          </Label>
          <Label>
            {i18next.t('courier.vehicleBrand')}
            <Control.text
              model=".vehicleBrand"
              id="vehicleBrand"
              component={ValidationInput}
              placeholder={i18next.t('courier.vehicleBrand')}
            />
          </Label>
          <Label>
            <div>{i18next.t('courier.vehicleControl')}</div>
            <UnitSelect expand white>
              <Control.select
                id="vehicleControl"
                changeAction={(model: string, value: string) => {
                  setVehicleControlValue(value)
                  changeFormValue(model, value)
                }}
                model=".vehicleControl"
              >
                <option value=""></option>
                {vehicleControlType.map((vcType) => (
                  <option value={vcType} key={vcType}>
                    {i18next.t(`courier.${vcType}`)}
                  </option>
                ))}
              </Control.select>
            </UnitSelect>
          </Label>
          <Label>
            <Control.checkbox model=".commercialTrafficEligibility" />
            {'   '}
            {i18next.t('courier.commercialTrafficEligibility')}
          </Label>
        </Half>
        <Half marginRight>
          <Label>
            {isBring() ? i18next.t('courier.alystraId') : i18next.t('courier.unitId')} *
            <Control.text model=".alystraId" id="alystraId" component={ValidationInput} />
            <Errors
              className="errors"
              model=".alystraId"
              show="touched"
              wrapper={ValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </Label>
          <FuelTypeInput model=".fuelType" />
          <CapacityInput model=".maxRangeKm" isRequired={false} />
          <CapacityInput
            model=".volumeCapacity"
            isRequired={true}
            changeAction={(model: string, value: string) => {
              changeFormValue(model, value.replace(',', '.'))
            }}
          />
          <Label>
            {i18next.t('courier.vehicleModel')}
            <Control.text
              model=".vehicleModel"
              id="vehicleModel"
              component={ValidationInput}
              placeholder={i18next.t('courier.vehicleModel')}
            />
          </Label>
          <Label>
            <div>{i18next.t('courier.euroClass')}</div>
            <UnitSelect expand white>
              <Control.select id="euroClass" model=".euroClass">
                <option value=""></option>
                {euroClassType.map((euroClass) => (
                  <option value={euroClass} key={euroClass}>
                    {i18next.t(`courier.euroClassTypes.${euroClass}`)}
                  </option>
                ))}
              </Control.select>
            </UnitSelect>
          </Label>
          <Label>
            {i18next.t('courier.co2')}
            <Control.text model=".co2" id="co2" component={ValidationInput} placeholder={i18next.t('courier.co2')} />
          </Label>
          {vehicleControlValue == VehicleControlType.NOT_APPROVED && (
            <Label>
              {i18next.t('courier.vehicleControlComment')} *
              <Control.text
                model=".vehicleControlComment"
                id="vehicleControlComment"
                validators={{ required }}
                component={TextAreaInput}
                placeholder={i18next.t('courier.vehicleControlComment')}
              />
              <Errors
                className="errors"
                model=".vehicleControlComment"
                show="touched"
                wrapper={ValidationError}
                messages={{ required: `${i18next.t('application.required')}` }}
              />
            </Label>
          )}
        </Half>
      </div>
      <ColorInput
        onReset={() => {
          changeFormValue(model + '.color', '#ffffff')
        }}
      />
      <Label>
        {i18next.t('courier.speedFactor')} ({i18next.t('courier.speedFactorExplanation')})
        <Control model=".speedFactor" component={RangeSliderSingle} rangeStep={0.1} min={0.5} max={2} />
        {speedFactorHeader}
        <Errors
          className="errors"
          model=".speedFactor"
          wrapper={ValidationError}
          messages={{
            required: `${i18next.t('application.required')}`,
            validNumber: `${i18next.t('error.notAValidNumber')}`
          }}
        />
      </Label>
      <label className="w-[98%] block">
        {i18next.t('courier.serviceTimeFactor')} ({i18next.t('courier.serviceTimeFactorExplanation')})
        <Control model=".serviceTimeFactor" component={RangeSliderSingle} rangeStep={0.1} min={0.5} max={2} />
        {speedFactorHeader}
        <Errors
          className="errors"
          model=".serviceTimeFactor"
          wrapper={ValidationError}
          messages={{
            required: `${i18next.t('application.required')}`,
            validNumber: `${i18next.t('error.notAValidNumber')}`
          }}
        />
      </label>

      <BreakFormFragment changeFormValue={changeFormValue} model={model} formValues={formValues} />

      <Label>
        <Control.checkbox model=".isActive" />
        {'   '}
        {i18next.t('courier.isActive')}
      </Label>

      <UnitStartStop showReturnCourierMap={showReturnCourierMap} lat={lat} lng={lng} />

      {children}

      <Label>{i18next.t('application.required')}</Label>
      <PrimaryButton type="submit">{i18next.t('unit.saveUnit')}</PrimaryButton>
    </Form>
  )
}
