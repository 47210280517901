import React, { InputHTMLAttributes } from 'react'
import { Input } from '../primitives/Forms'

type Props = {
  half?: boolean
  right?: boolean
  roundedCorners?: boolean
  icon?: string
} & InputHTMLAttributes<HTMLInputElement>
const ValidationInput: React.FC<Props> = (props) => {
  return (
    <div>
      <div>
        <Input {...props} />
      </div>
    </div>
  )
}

export default ValidationInput
