import Immutable, { Collection, isCollection, List, Map } from 'immutable'

export function ImmutableMapFactory<T>(obj: T): ImmutableMap<T> {
  return Map(obj as { [key: string]: any }) as ImmutableMap<T>
}

export interface ImmutableMap<T> extends Map<any, any> {
  getIn(keyPath: Iterable<unknown>): any
  getIn(keyPath: Iterable<unknown>, notSetValue?: unknown): any
  get<K extends keyof T>(name: K): T[K]
  get<K extends keyof T>(name: K, notSetValue: T[K]): T[K]
  set<K extends keyof T>(name: K, value: T[K]): this
  merge<K extends keyof T>(...collections: Iterable<[K, T[K]]>[]): Map<K, T[K]>
  merge<K extends keyof T>(...collections: { K: T[K] }[]): Map<K, T[K]>
  update<K extends keyof T>(key: K, notSetValue: T[K], updater: (value: T[K]) => T[K]): this
  update<K extends keyof T>(key: K, updater: (value: T[K]) => T[K]): this
  update<R>(updater: (value: this) => R): R
}

export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined
}

export function isCollectionOf<K, V>(maybeCollection: unknown): maybeCollection is Collection<K, V> {
  return isCollection(maybeCollection)
}

export function chunked<T>(list: List<T>, chunkSize: number): List<List<T>> {
  return Immutable.Range(0, list.count(), chunkSize)
    .map((chunkStart) => list.slice(chunkStart, chunkStart + chunkSize))
    .toList()
}
