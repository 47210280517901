import { UserAccessKey } from '@glow/entity-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { appNeedsRefresh } from '../checkRevision'
import { AppStateType } from '../utils/appStateReduxStore'
import lazy from '../utils/lazyWithRetry'
import { isAuthPath, userRequiresPwdLogin } from '../utils/loginUtils'
import { ROLE_PLUS_PLANNER } from '../utils/roles'
import { generateFullTitle } from '../utils/titleGenerator'
import { Loading } from './Loading'

const LoginHomePage = lazy(() => import('../pages/auth/LoginHomePage'))
const AdminLayout = lazy(() => import('../layout/AdminLayout'))
const AdminPlanners = lazy(() => import('../pages/admin/users/AdminUsersPage'))
const AdminDepartments = lazy(() => import('../pages/admin/AdminDepartmentsPage'))
const PageNotFound = lazy(() => import('../pages/NotFound'))
const PlannerLayout = lazy(() => import('../layout/PlannerLayout'))
const PlannerSelectDepartmentPage = lazy(() => import('../pages/planner/PlannerSelectDepartmentPage'))

interface Props {
  titleKey?: string
  layout?: React.ComponentType<any>
  component: React.ComponentType<any>
  roles?: string[]
  access?: UserAccessKey[]
}

export const AppRoute = ({ titleKey, ...rest }: Props): React.ReactElement | null => {
  document.title = generateFullTitle(titleKey)

  if (appNeedsRefresh) {
    console.warn('App will now refresh')
    window.location.reload()
    return <Loading loadingTextKey={'newAppVersion'} />
  }

  return <RouteElement {...rest} />
}

const RouteElement = ({ layout, component, roles, access, ...props }: Props) => {
  const location = useLocation()
  const user = useSelector((state: AppStateType) => state.get('user'))
  const userRole = user && user.get('role')

  const adjustComp =
    layout === AdminLayout && userRole === ROLE_PLUS_PLANNER && component === AdminDepartments
      ? AdminPlanners
      : component

  if (userRole && !userRequiresPwdLogin(user)) {
    if (location.pathname === '/login') {
      const comp = React.createElement(PlannerSelectDepartmentPage, props)
      return React.createElement(PlannerLayout, props, comp)
    } else if (access && access.some((a) => user.get('access')?.includes(a))) {
      const comp = React.createElement(adjustComp, props)
      return layout ? React.createElement(layout, props, comp) : comp
    } else if (roles && !roles.includes(userRole)) {
      return React.createElement(PageNotFound, props)
    } else if (!roles && access && !access.some((a) => user.get('access')?.includes(a))) {
      return React.createElement(PageNotFound, props)
    } else {
      const comp = React.createElement(adjustComp, props)
      return layout ? React.createElement(layout, props, comp) : comp
    }
  } else if (userRole && isAuthPath()) {
    const comp = React.createElement(adjustComp, props)
    return layout ? React.createElement(layout, props, comp) : comp
  } else {
    const comp = React.createElement(adjustComp, props)
    return location.pathname === '/'
      ? React.createElement(LoginHomePage, props)
      : layout
        ? React.createElement(layout, props, comp)
        : comp
  }
}
