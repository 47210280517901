import { fromJS, List } from 'immutable'
import { AnyAction } from 'redux'
import { GET_RECURRING_EXECUTED_DATES } from '../actions/actionTypes'

const initialState = List<string>()

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_RECURRING_EXECUTED_DATES.SUCCESS:
      return List(fromJS(action.body))
    default:
      return state
  }
}
