import { fromJS, Map } from 'immutable'
import {
  AUTOSUGGEST_ADDRESSES,
  AUTOSUGGEST_CUSTOMERS,
  AUTOSUGGEST_ORGANISATIONS,
  AUTOSUGGEST_SUBCUSTOMERS,
  CLEAR_SEARCH_ADDRESSES,
  CLEAR_SEARCH_CUSTOMERS,
  GET_SUBCUSTOMERS,
  SEARCH_ADDRESSES,
  SEARCH_CUSTOMERS,
  SEARCH_ORGANISATIONS_TWO
} from '../actions/actionTypes'
import { customerNumberAndSubcustomer } from '../pages/instant/bookingOrderFunctions'

const initialState = Map({
  customers: Map(),
  fetchedCustomersByAlystraId: Map(),
  addresses: Map()
})

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case CLEAR_SEARCH_CUSTOMERS:
      return state.setIn([AUTOSUGGEST_CUSTOMERS], Map())
    case CLEAR_SEARCH_ADDRESSES:
      return state.setIn([AUTOSUGGEST_ADDRESSES], Map())
    case SEARCH_CUSTOMERS.SUCCESS:
      return state.withMutations((mState) => {
        const s = mState.setIn([AUTOSUGGEST_CUSTOMERS], Map())
        return fromJS(action.body).map((v: Map<string, any>, k: { toString: () => unknown }) => {
          return s
            .setIn([AUTOSUGGEST_CUSTOMERS, k.toString()], v)
            .setIn(
              [
                'fetchedCustomersByAlystraId',
                customerNumberAndSubcustomer(v.get('alystraId'), v.get('alystraSubcustomer'))
              ],
              v
            )
        })
      })
    case SEARCH_ADDRESSES.SUCCESS:
      return state.withMutations((mState) => {
        const s = mState.setIn([AUTOSUGGEST_ADDRESSES], Map())
        return fromJS(action.body)
          .get('addresses')
          .map((v: unknown, k: { toString: () => unknown }) => {
            return s.setIn([AUTOSUGGEST_ADDRESSES, k.toString()], v)
          })
      })
    case SEARCH_ORGANISATIONS_TWO.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn([AUTOSUGGEST_ORGANISATIONS], fromJS(action.body.data.items))
      })
    case GET_SUBCUSTOMERS.SUCCESS:
      return state.set(AUTOSUGGEST_SUBCUSTOMERS, fromJS(action.body))
    default:
      return state
  }
}
