import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Popover, ArrowContainer, PopoverPosition, Rect } from 'react-tiny-popover'
import { Input } from '../../primitives/Forms'
import vars from '../../styles/variables'

interface FilterPopoverProps {
  isOpen: boolean
  children: JSX.Element
  filterValue: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  clear: () => void
  close: () => void
}

export const FilterPopover = ({ onChange, filterValue, isOpen, close, clear, children }: FilterPopoverProps) => {
  return (
    <Popover
      isOpen={isOpen}
      positions={['top']}
      onClickOutside={close}
      padding={0}
      containerStyle={{
        zIndex: '10000'
      }}
      content={(rendererArgs) => (
        <FilterInput filterValue={filterValue} onChange={onChange} clear={clear} close={close} {...rendererArgs} />
      )}
    >
      {children}
    </Popover>
  )
}

interface FilterInputProps {
  filterValue: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  close: () => void
  clear: () => void
  position?: PopoverPosition
  childRect: Rect
  popoverRect: Rect
}

const FilterInput = ({ filterValue, onChange, close, clear, position, childRect, popoverRect }: FilterInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  })

  return (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={vars.colors.gray5}
      arrowSize={10}
      arrowStyle={{}}
    >
      <div style={{ zIndex: 10000, position: 'relative', display: 'inline-block' }}>
        <Input
          ref={inputRef}
          autoFocus={true}
          placeholder={i18next.t('instant.instantGrid.filterPlaceholder')}
          value={filterValue}
          onChange={onChange}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            (event.key === 'Enter' || event.key == 'Escape') && close()
          }
          style={{ boxShadow: 'none', border: `1px solid ${vars.colors.gray5}` }}
        />
        <a
          onClick={() => {
            if (filterValue === '') {
              close()
            } else {
              clear()
            }
            inputRef.current && inputRef.current.focus()
          }}
          style={{
            cursor: 'pointer',
            float: 'right',
            position: 'absolute',
            bottom: '11px',
            right: '11px',
            color: vars.colors.gray5
          }}
        >
          <FontAwesomeIcon icon="times" />
        </a>
      </div>
    </ArrowContainer>
  )
}
