import styled from 'styled-components'

export const Half = styled.div<{ marginRight?: boolean; top?: boolean }>`
  width: ${(props) => (props.marginRight ? '46%' : '50%')};
  display: inline-block;
  margin-right: ${(props) => props.marginRight && '4%'};
  vertical-align: ${(props) => props.top && 'top'};
`

export const Third = styled.div`
  width: 33.33333%;
  display: inline-block;
  vertical-align: top;
  position: relative;
`

export const Right = styled.div`
  float: right;
`

export const FlexDiv = styled.div<{ noMargin?: boolean; padding?: boolean; spaceBetween?: boolean }>`
  display: flex;
  padding: ${(props) => (props.padding ? '0.5em' : '0')};
  justify-content: ${(props) => (props.spaceBetween ? 'space-between;' : 'unset')};
  margin-left: ${(props) => (props.noMargin ? '0' : '0.5em')};
  margin-right: ${(props) => (props.noMargin ? '0' : '0.5em')};
`
