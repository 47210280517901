import { fromJS, List, Map } from 'immutable'
import { SAVED_SELECTED_ORDER_IDS, ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'
import { OrderIdType } from '../types/coreEntitiesTypes'

const initialState = Map({
  url: '',
  selectedOrderIds: List()
})

interface SaveSelectedOrderIdsAction {
  type: typeof SAVED_SELECTED_ORDER_IDS
  url: string
  selectedOrderIds: OrderIdType[]
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
  body: Map<number, List<unknown>>
}

type PreadviceFollowupDataAction = LocationChangeAction | SaveSelectedOrderIdsAction

export default function reducer(state = initialState, action: PreadviceFollowupDataAction) {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE_ACTION:
      if (document.location.href.includes('shipments-search') && document.location.href.endsWith('details')) {
        return state
      } else if (state.get('url') === document.location.href) {
        return state
      }
      return initialState
    case SAVED_SELECTED_ORDER_IDS:
      return state.set('selectedOrderIds', fromJS(action.selectedOrderIds)).set('url', action.url)
    default:
      return state
  }
}
