import { fromJS, List } from 'immutable'
import { GET_UNRESOLVED_ADDRESSES } from '../actions/actionTypes'
import { AnyAction } from 'redux'
import { UnresolvedAddress } from '../domain/unresolvedAddress'

const initialState = List<UnresolvedAddress>()

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_UNRESOLVED_ADDRESSES.SUCCESS:
      return fromJS(action.body)
    case GET_UNRESOLVED_ADDRESSES.FAILURE:
      return initialState
    default:
      return state
  }
}
