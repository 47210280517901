import { List } from 'immutable'
import { googleAPIKey, isBring } from '@glow/common'

const googleApiKey = googleAPIKey()

export const GOOGLE_MAP_PROPS = {
  id: 'script-loader',
  channel: '55475_glow_frontend',
  googleMapsApiKey: googleApiKey,
  libraries: ['geometry', 'drawing', 'places', 'directions']
}

export const courierIcon = isBring()
  ? {
      url: '/transportericon.png',
      size: { width: 50, height: 30 },
      origin: { x: 0, y: 10 },
      anchor: { x: 0, y: 25 },
      scaledSize: { width: 50, height: 50 }
    }
  : {
      url: '/glow-courier-icon.png',
      size: { width: 50, height: 30 },
      anchor: { x: 0, y: 25 },
      scaledSize: { width: 50, height: 30 }
    }

// the type of param should be replaced by a proper type backed by interface once the corresponding JSX is also type-scripted
export function groupMarkersOnPosition(
  markers: List<{ id: string; lat: number; lng: number; information: any; colors: string[]; highlight?: any }>
) {
  return markers.reduce((result: any[], marker) => {
    const existingMarker = result.find(({ lat, lng }) => {
      const roundedLat = Math.round(lat * 8000) / 8000
      const roundedLng = Math.round(lng * 8000) / 8000
      const roundedMarkerLat = Math.round(marker.lat * 8000) / 8000
      const roundedMarkerLng = Math.round(marker.lng * 8000) / 8000
      return roundedLat === roundedMarkerLat && roundedLng === roundedMarkerLng
    })

    if (!existingMarker) {
      return [...result, marker]
    }

    const existingMarkerIndex = result.indexOf(existingMarker)

    const updatedMarker = {
      ...existingMarker,
      ids: (existingMarker.ids || []).concat(marker.id),
      information: existingMarker.information.concat(marker.information),
      colors: marker.colors.concat(existingMarker.colors),
      highlight: marker.highlight || existingMarker.highlight
    }

    return [...result.slice(0, existingMarkerIndex), updatedMarker, ...result.slice(existingMarkerIndex + 1)]
  }, [])
}
