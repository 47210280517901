import { DateTime } from 'luxon'
import React, { CSSProperties } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import styled from 'styled-components'
import { Input } from '../../primitives/Forms'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import vars from '../../styles/variables'
import { localizedDayPickerProps } from '../../utils/dateTime'
import { formatDate } from './RecurringOrderForm'

interface Props {
  format?: string
  onChange: (date: string) => void
  placeholder?: string
  style?: CSSProperties
  value?: string
  inputProps?: object
  dayPickerInputProps?: object
  right?: boolean
  alignRightBottom?: boolean
  disabled?: boolean
  preventSubmitOnEnter?: boolean
}

export default class DatePicker extends ImmutableComponent<Props> {
  onDayChange = (date: Date) => {
    if (this.props.onChange) {
      if (date) {
        this.props.onChange(formatDate(date))
      } else {
        this.props.onChange('')
      }
    }
  }

  onChange = (e: React.FocusEvent<HTMLDivElement>) => {
    this.props.onChange(e.target.nodeValue || '')
  }

  render(): React.ReactNode {
    const {
      format = 'yyyy-MM-dd',
      style,
      inputProps = {},
      dayPickerInputProps = {},
      right = false,
      alignRightBottom = false,
      preventSubmitOnEnter = false
    } = this.props

    const modifiedInputProps = preventSubmitOnEnter
      ? {
          onKeyDown: (e: KeyboardEvent) => e.key === 'Enter' && e.preventDefault(),
          ...inputProps
        }
      : inputProps

    return (
      <DayPickerInput
        dayPickerProps={{ ...localizedDayPickerProps, ...dayPickerInputProps }}
        inputProps={{
          component: Input,
          disabled: this.props.disabled,
          style: { ...dayPickerInputStyle, ...style },
          ...modifiedInputProps
        }}
        overlayComponent={createDayPickerOverlay(right, alignRightBottom)}
        parseDate={parserFor(format)}
        formatDate={formatterFor(format)}
        format={format}
        onDayChange={this.onDayChange}
        {...this.props}
        onChange={this.onChange}
      />
    )
  }
}

function parserFor(format: string) {
  return (dateStr: string) => {
    const parsed = DateTime.fromFormat(dateStr, format)
    return parsed.isValid ? parsed.toJSDate() : undefined
  }
}

function formatterFor(format: string) {
  return (date: Date) => DateTime.fromJSDate(date).toFormat(format)
}

const createDayPickerOverlay =
  (right?: boolean, alignRightBottom?: boolean) =>
  ({ children, ...props }: any) => {
    return (
      <div style={{ position: 'relative' }}>
        <DayPickerCustomOverlay right={right} alignRightBottom={alignRightBottom} {...props}>
          {children}
        </DayPickerCustomOverlay>
      </div>
    )
  }

interface DayPickerCustomOverlayProps {
  right?: boolean
  alignRightBottom?: boolean
}

const DayPickerCustomOverlay = styled.div<DayPickerCustomOverlayProps>`
  position: absolute;
  left: ${(props) => (props.right ? '90px' : props.alignRightBottom ? undefined : 0)};
  right: ${(props) => (props.alignRightBottom ? 0 : undefined)};
  ${(props) => props.right && 'bottom: 0;'};
  ${(props) => props.alignRightBottom && 'top: 0;'};
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
`

export const dayPickerInputStyle = {
  background: vars.colors.white,
  width: '100px',
  height: vars.inputs.height,
  padding: '0.6rem',
  color: vars.colors.black,
  borderRadius: '4px',
  boxShadow: `0 0 1px ${vars.colors.black}`,
  outline: 0,
  textAlign: 'center'
}
