import { Map } from 'immutable'
import { ADD_NOTIFICATION, DISMISS_ALL_NOTIFICATIONS, DISMISS_NOTIFICATION } from '../actions/actionTypes'
import { Notification } from '../types/notificationTypes'

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION
  id: string
  props: Notification
}

interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION
  id: string
}

interface DismissAllNotificationsAction {
  type: typeof DISMISS_ALL_NOTIFICATIONS
}

const initialState = Map()

type NotificationAction = AddNotificationAction | DismissNotificationAction | DismissAllNotificationsAction

export default function reducer(state = initialState, action: NotificationAction) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return state.set(action.id, action.props.set('id', action.id))
    case DISMISS_NOTIFICATION:
      return state.delete(action.id)
    case DISMISS_ALL_NOTIFICATIONS:
      return initialState
    default:
      return state
  }
}
