import { Map } from 'immutable'
import { ADD_FEEDBACK_NOTIFICATION, DISMISS_FEEDBACK_NOTIFICATION } from '../actions/actionTypes'

const initialState = Map()

interface DismissFeedbackNotificationAction {
  type: typeof DISMISS_FEEDBACK_NOTIFICATION
}

interface AddFeedbackNotificationAction {
  type: typeof ADD_FEEDBACK_NOTIFICATION
  id: string
}

type FeedbackNotificationAction = AddFeedbackNotificationAction | DismissFeedbackNotificationAction

export const feedbackNotifications = (state = initialState, action: FeedbackNotificationAction) => {
  switch (action.type) {
    case ADD_FEEDBACK_NOTIFICATION:
      return state.set(action.id, 'true')
    case DISMISS_FEEDBACK_NOTIFICATION:
      return initialState
    default:
      return state
  }
}
