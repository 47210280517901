import { fromJS, Map } from 'immutable'
import { GET_CUSTOMERS_WITH_TIME_MATRIX } from '../actions/actionTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { ShdMatrix } from '../pages/admin/ShdAdmin'

interface ShdMatrixActionType {
  type: string
  body: Map<number, ImmutableMap<ShdMatrix>>
}

const initialState = Map({
  customersWithTimeMatrix: Map()
})

export default (state = initialState, action: ShdMatrixActionType) => {
  switch (action.type) {
    case GET_CUSTOMERS_WITH_TIME_MATRIX.SUCCESS:
      return state.withMutations((mState) => {
        return mState.set('customersWithTimeMatrix', fromJS(action.body).toMap())
      })
    default:
      return state
  }
}
