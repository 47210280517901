import { List } from 'immutable'
import trim from 'lodash/trim'
import { FilterProps } from '../../reducers/filterReducer'

interface MatchFilter {
  kind: 'match'
  search: string
}

interface NumericRangeFilter {
  kind: 'range'
  fromInclusive: number
  toInclusive: number
}

export function matchFilter(search: string): Filter {
  return {
    kind: 'match',
    search
  }
}

export function numericRangeFilter(fromInclusive: number, toInclusive: number): Filter {
  return {
    kind: 'range',
    fromInclusive,
    toInclusive
  }
}

export type Filter = MatchFilter | NumericRangeFilter

export function matchesOneOf(filters: List<Filter>, str: string): boolean {
  return filters.some((filter) => matchesFilter(filter, str))
}

export function matchesFilter(filter: Filter, str: string): boolean {
  switch (filter.kind) {
    case 'match':
      return matches(filter.search, str)
    case 'range':
      return withinRange(filter.fromInclusive, filter.toInclusive, str)
  }
}

export const matches = (search: string, str: string) => {
  const strLower = str.toLowerCase()
  const searchLower = search.toLowerCase()
  let j = 0 // remembers position of last found character

  // consider each search character one at a time
  for (let i = 0; i < searchLower.length; i++) {
    const l = searchLower[i]
    if (l == ' ') continue // ignore spaces

    j = strLower.indexOf(l, j) // search for character & update position
    if (j == -1) return false // if it's not found, exclude this item
    j++
  }
  return true
}

const strToNum = (str: string) => parseInt(str.replace(/\D+/g, ''), 10)

export const withinRange = (fromInclusive: number, toInclusive: number, str: string) => {
  const number = strToNum(str)
  if (!isNaN(number)) {
    return fromInclusive <= number && number <= toInclusive
  }
  return false
}

export const filterSeparator = '&'

export function parseFilters(filterInput: string): FilterProps {
  const filters: Filter[] = filterInput
    .split(filterSeparator)
    .map(trim)
    .map((str) => {
      const res = str.split('-')
      const from = res[0]
      const to = res[1]
      if (res.length > 1) {
        return numericRangeFilter(
          from === '' ? Number.MIN_SAFE_INTEGER : parseInt(from, 10),
          to === '' ? Number.MAX_SAFE_INTEGER : parseInt(to, 10)
        )
      } else {
        return matchFilter(res[0])
      }
    })
  return {
    input: filterInput,
    filters: List(filters)
  }
}
