export const getStoredPhone = () => localStorage.getItem('userPhone')

export const storePhone = (phone: string) => localStorage.setItem('userPhone', phone)

export const getStoredFilter = () => {
  try {
    const filter = localStorage.getItem('liveFilter')
    return filter ? JSON.parse(filter) : null
  } catch (e) {
    return null
  }
}

export const storeLiveFilter = ({
  showActive,
  showCompleted,
  showNotStarted
}: {
  showActive: boolean
  showCompleted: boolean
  showNotStarted: boolean
}) =>
  localStorage.setItem(
    'liveFilter',
    JSON.stringify({
      showActive: showActive,
      showCompleted: showCompleted,
      showNotStarted: showNotStarted
    })
  )

export const storeLiveFilterValue = (key: string, value: boolean) => {
  const filter = getStoredFilter()
  filter[key] = value
  storeLiveFilter(filter)
}
