import { Map } from 'immutable'
import toString from 'lodash/toString'
import replace from 'lodash/replace'
import { StandardUnitNotation } from '../domain/measurementUnits'

export function isEmpty(val: string | undefined | null) {
  return val === undefined || val == null || val.length <= 0
}

export function isNotEmpty(val: string | undefined | null) {
  return !isEmpty(val)
}

export function nonEmptyOrNull(str: string | undefined | null) {
  return isEmpty(str) ? null : str
}

export function isBlank(value: string) {
  return isEmpty(replace(value, ' ', ''))
}

export const addNorwegianPrefixIfNone = (phone: string | undefined | null) =>
  phone && (phone.startsWith('+') || phone.startsWith('00') ? phone : '+47 ' + phone)

export function formatWeightDimensionsForPackages(
  id: string | number | undefined | null,
  weight: string | number | undefined | null,
  length: string | number | undefined | null,
  width: string | number | undefined | null,
  height: string | number | undefined | null
) {
  let formattedDetails = ''
  const isWeightPresent = isNotEmpty(weight?.toString())
  const areDimensionsPresent = !(
    isEmpty(length?.toString()) ||
    isEmpty(width?.toString()) ||
    isEmpty(height?.toString())
  )
  if (isEmpty(id?.toString())) {
    return formattedDetails
  } else {
    formattedDetails += id
    if (isWeightPresent) {
      formattedDetails += ' (' + weight + ' ' + StandardUnitNotation.KGM
    }

    if (areDimensionsPresent && isWeightPresent) {
      formattedDetails += ', ' + length + ' x ' + width + ' x ' + height + ' ' + StandardUnitNotation.CMT + ')'
    } else if (areDimensionsPresent && !isWeightPresent) {
      formattedDetails += ' (' + length + ' x ' + width + ' x ' + height + ' ' + StandardUnitNotation.CMT + ')'
    } else if (!areDimensionsPresent && isWeightPresent) {
      formattedDetails += ')'
    }
  }
  return formattedDetails
}

export function formatWeightDimensionsForShipments(
  id: string | number | undefined | null,
  weight: string | number | undefined | null
) {
  let formattedDetails = ''
  const isWeightPresent = isNotEmpty(weight?.toString())
  if (isEmpty(id?.toString())) {
    return formattedDetails
  } else {
    formattedDetails += id
    if (isWeightPresent) {
      formattedDetails += ' (' + weight + ' ' + StandardUnitNotation.KGM + ')'
    }
  }
  return formattedDetails
}

export function nameStartsWithAlphabet(a: Map<string, string | undefined>) {
  const name = toString(a.get('name'))
  if (name === '' || name === '-') {
    return false
  }
  return isNaN(parseInt(name))
}

export function nameStartsWithNumber(a: Map<string, string | undefined>) {
  const name = toString(a.get('name'))
  if (name === '' || name === '-') {
    return false
  }
  return !isNaN(parseInt(name))
}

export function nameIsDashOrEmpty(a: Map<string, string | undefined>) {
  const name = toString(a.get('name'))
  return name === '' || name === '-'
}

export function stripWhiteSpace(str: string) {
  return replace(str, /\s+/, '')
}

export const camelCaseToSplit = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    .join(' ')
