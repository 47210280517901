import { Map, Set } from 'immutable'
import { GRID_CLEAR_SAVED, GRID_INIT, GRID_SAVE_IDS } from '../actions/actionTypes'
import { GridInitAction } from './gridReducer'

export interface GridSaveIdsAction {
  type: typeof GRID_SAVE_IDS
  id: string
  ids: Set<number>
}

export interface GridClearSaveIdsAction {
  type: typeof GRID_CLEAR_SAVED
  id: string
}

type SavedIdsActionType = GridInitAction | GridSaveIdsAction | GridClearSaveIdsAction

const initialState = Map()

const initialGridState = Map({
  savedIds: Set<number>()
})

export default function reducer(state = initialState, action: SavedIdsActionType) {
  switch (action.type) {
    case GRID_INIT:
      return state.set(action.id, initialGridState)
    case GRID_SAVE_IDS:
      return state.setIn(
        [action.id, 'savedIds'],
        (state.getIn([action.id, 'savedIds']) as Set<number>).union(action.ids)
      )
    case GRID_CLEAR_SAVED:
      return state.setIn([action.id, 'savedIds'], Set())
    default:
      return state
  }
}
