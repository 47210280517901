import React from 'react'
import { DragSource, DragSourceMonitor } from 'react-dnd'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { Shipment } from '../../types/coreEntitiesTypes'
import InstantGridRow, { InstantGridRowProps } from './InstantGridRow'
import isFunction from 'lodash/isFunction'

interface InstantGridDraggableRowProps<T> extends InstantGridRowProps<T> {
  isDragging?: boolean
  isOver?: boolean
  dragPreview?: string
  connectDragPreview?: (img: HTMLImageElement) => void
  connectDragSource?: (element: JSX.Element) => void
}

interface DragDropProps {
  row: Shipment
  id: number
  index: number
  getDropResult: () => any
  onDragEnd: (resultId: number, row: Shipment, id: number, index: number, resultIndex: number) => void
}

const InstantGridRowSource = {
  beginDrag(props: DragDropProps) {
    return {
      id: props.id,
      index: props.index
    }
  },
  endDrag(props: DragDropProps, monitor: DragSourceMonitor<unknown, DragDropProps>) {
    const dropResult = monitor.getDropResult()
    if (dropResult && dropResult.id && isFunction(props.onDragEnd)) {
      props.onDragEnd(dropResult.id, props.row, props.id, props.index, dropResult.index)
    }
  }
}

class InstantGridDraggableRow<T> extends ImmutableComponent<InstantGridDraggableRowProps<T>> {
  componentDidMount() {
    const { connectDragPreview, dragPreview } = this.props

    if (dragPreview && connectDragPreview) {
      const img = new Image()
      img.src = dragPreview
      img.onload = () => connectDragPreview(img)
    }
  }

  render() {
    const { connectDragSource, setRef, ...restProps } = this.props

    // TODO Name
    const setReff = (el: any) => {
      setRef && setRef(el)
      connectDragSource && el && connectDragSource(el)
    }

    return <InstantGridRow isDragEnabled={true} setRef={setReff} {...restProps} />
  }
}

export default DragSource('order', InstantGridRowSource, (connect: any, monitor: DragSourceMonitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))(InstantGridDraggableRow)
