import { Map } from 'immutable'
import {
  RE_RENDER_MAP,
  SET_FILTER,
  SET_MAP_POSITION,
  SET_TIME_FILTER,
  TOGGLE_MAP_AUTO_PAN,
  SET_SLOT_FILTER
} from '../actions/actionTypes'

const initialState = Map({
  autoPan: true,
  reRenderMap: undefined as number | undefined,
  selectedPolygon: '',
  position: {
    lat: undefined as number | undefined,
    lng: undefined as number | undefined,
    zoom: 0
  },
  time: Map({
    from: 4,
    to: 24
  }),
  filter: Map({
    pickups: false,
    deliveries: true,
    assigned: true,
    unassigned: true
  }),
  slotFilter: Map({
    showActive: true,
    showNotStarted: true,
    showCompleted: true
  })
})

interface ActionProp {
  type:
    | typeof SET_MAP_POSITION
    | typeof TOGGLE_MAP_AUTO_PAN
    | typeof RE_RENDER_MAP
    | typeof SET_TIME_FILTER
    | typeof SET_FILTER
    | typeof SET_SLOT_FILTER
  position: {
    lat: number | undefined
    lng: number | undefined
    zoom: number
  }
  autoPan: boolean
  reRenderMap: number | undefined
  from: string
  to: string
  name: string
  value: string
}

export default function reducer(state = initialState, action: ActionProp) {
  switch (action.type) {
    case SET_MAP_POSITION:
      return state.set('position', action.position)

    case TOGGLE_MAP_AUTO_PAN:
      return state.set('autoPan', action.autoPan)

    case RE_RENDER_MAP:
      return state.set('reRenderMap', action.reRenderMap)

    case SET_TIME_FILTER:
      return state.setIn(['time', 'from'], action.from).setIn(['time', 'to'], action.to)

    case SET_FILTER:
      return state.setIn(['filter', action.name], action.value)

    case SET_SLOT_FILTER:
      return state.setIn(['slotFilter', action.name], action.value)

    default:
      return state
  }
}
