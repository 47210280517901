import { fromJS, Map } from 'immutable'
import { CLEAR_SEARCH_UNITS, REMOVE_FROM_UNIT_SEARCH, SEARCH_UNITS } from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map()

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SEARCH_UNITS.SUCCESS:
      return fromJS(action.body)
    case REMOVE_FROM_UNIT_SEARCH:
      return state.set(
        'units',
        (state.get('units') as Map<number, any>).filter((v, k) => k != action.unitId)
      )
    case CLEAR_SEARCH_UNITS:
      return initialState
    default:
      return state
  }
}
