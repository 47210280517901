import { useLoadScript } from '@react-google-maps/api'
import { GOOGLE_MAP_PROPS } from '../utils/mapUtils'
import React from 'react'
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const GooglePlacesAutocompleteWrapper = (props: any) => {
  // @ts-expect-error
  const { isLoaded } = useLoadScript({ ...GOOGLE_MAP_PROPS })

  return isLoaded ? <GooglePlacesAutocomplete {...props} /> : <FontAwesomeIcon icon="spinner" pulse size="1x" />
}
