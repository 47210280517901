import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import vars from '../styles/variables'

function getWidth(prop: { half?: boolean; inline?: boolean; small?: boolean }) {
  if (prop.small) {
    return '10em'
  }
  if (prop.half) {
    return '48%'
  }
  if (prop.inline) {
    return 'auto'
  }
  return '98%'
}

function getMargin(prop: { noMargin?: boolean; half?: boolean; inline?: boolean; small?: boolean }) {
  if (prop.noMargin) {
    return '0'
  }
  if (prop.half || prop.small) {
    return '2%'
  }
  if (prop.inline) {
    return '4%'
  }
  return '0'
}

export const LabelText = styled.div`
  margin-bottom: 4px;
`

export const LabelNew = styled.label`
  color: ${vars.colors.gray11};
  font-weight: bold;
  display: inline-block;
`

type StyledDivProps = {
  inline?: any
  half?: boolean
  wide?: boolean
  noMargin?: boolean
  small?: boolean
  fontWeight?: number
  color?: string
  style?: React.CSSProperties
}

export const StyledDiv = (props: PropsWithChildren<StyledDivProps>) => (
  <div
    style={{
      width: props.wide ? '100%' : getWidth(props),
      marginRight: getMargin(props),
      marginBottom: props.noMargin ? '0' : '1rem',
      float: props.inline ? undefined : 'left',
      display: props.inline ? 'inline-block' : 'block',
      color: props.color || vars.newColors.darkGrey,
      fontSize: '0.9rem',
      ...props.style
    }}
  >
    {props.children}
  </div>
)

export const Label = styled.label<{
  inline?: any
  half?: boolean
  wide?: boolean
  noMargin?: boolean
  small?: boolean
  fontWeight?: number
  color?: string
}>`
  width: ${(props) => getWidth(props)};
  margin-right: ${(props) => getMargin(props)};
  float: ${(props) => (props.inline ? '' : 'left')};
  width: ${(props) => props.wide && '100%'};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  color: ${(props) => props.color || vars.newColors.darkGrey};
  font-size: 0.9rem;
  margin-bottom: ${(props) => (props.noMargin ? '0' : '1rem')};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
`

export const FlexLabel = styled.label<{
  inline?: any
  half?: boolean
  wide?: boolean
  noMargin?: boolean
  small?: boolean
}>`
  width: ${(props) => getWidth(props)};
  margin-right: ${(props) => getMargin(props)};
  display: flex;
  font-size: 1rem;
`

export const SimpleLabel = styled.label<{
  inline?: any
  half?: boolean
  wide?: boolean
  noMargin?: boolean
  small?: boolean
}>`
  color: gray;
  font-size: 0.9rem;
`

export const InputDivider = styled.hr<{ half?: boolean; noMargin?: boolean; small?: boolean }>`
  width: ${(props) => getWidth(props)};
  height: 1px;
  background: ${vars.colors.gray6};
  border: none;
  box-shadow: none;
  float: left;
  margin: 1rem 0 1.5rem;
`

export const InputVerticalSpacer = styled.div`
  margin: 1rem 0;
`

export const TextAreaInput = styled.textarea<{ half?: boolean; right?: boolean }>`
  background: ${vars.colors.white};
  width: ${(props) => (props.half ? '50%' : '100%')};
  padding: 0.75rem;
  outline: 0;
  color: ${vars.colors.black};
  float: ${(props) => (props.right ? 'right' : 'none')};
  border-radius: 4px;
  box-shadow: 0 0 1px ${vars.colors.black};

  @media (max-width: 640px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 4px ${vars.colors.black};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 4px ${vars.colors.secondary};
  }

  &:disabled {
    background: ${vars.colors.gray};
    color: gray;
  }
`

export const SearchInput = styled.input<{ right?: boolean }>`
  background: ${vars.newColors.cloudBlue} url(/searchIcon.png) no-repeat 9px center;
  padding: 0.65rem;
  border-radius: 1.5rem;
  float: ${(props) => (props.right ? 'right' : 'none')};
  width: 300px;
  padding-left: 45px;
  box-shadow: 0 0 1px ${vars.colors.black};
  outline: 0;

  ::placeholder {
    color: ${vars.colors.black};
    opacity: 1;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 4px ${vars.colors.black};
  }
`

export const Input = styled.input<{ half?: boolean; right?: boolean; roundedCorners?: boolean; icon?: string }>`
  background: ${(props) => `${vars.colors.white} ${props.icon ? `url(${props.icon}) no-repeat left` : ''}`};
  width: ${(props) => (props.half ? '50%' : '100%')};
  padding: 0.75rem;
  color: ${vars.colors.black};
  float: ${(props) => (props.right ? 'right' : 'none')};
  border-radius: 4px;
  box-shadow: 0 0 1px ${vars.colors.black};
  ${(props) =>
    props.icon &&
    `background-size: 1rem;
  background-position-x: 0.75rem;
  padding-left: 2.5rem;`}

  @media (max-width: 640px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 4px ${vars.colors.black};
    outline: 0;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 4px ${vars.colors.secondary};
    outline: 0;
  }

  &:disabled {
    background: ${vars.colors.gray};
    color: gray;
  }

  &[aria-invalid='true'] {
    color: ${vars.colors.darkRed};
  }
`

export const InputForLabel = styled.input<{ half?: boolean; right?: boolean; roundedCorners?: boolean; icon?: string }>`
  background: ${vars.colors.white};
  width: ${(props) => (props.half ? '50%' : '100%')};
  color: ${vars.colors.black};
  float: ${(props) => (props.right ? 'right' : 'none')};
  padding: 0 4px;
  margin-bottom: 4px;
  outline: none;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const TextAreaInputWithLabel = styled.textarea<{ half?: boolean; right?: boolean }>`
  background: ${vars.colors.white};
  width: ${(props) => (props.half ? '50%' : '100%')};
  color: ${vars.colors.black};
  float: ${(props) => (props.right ? 'right' : 'none')};
  padding: 0 4px;
  margin-bottom: 4px;
  outline: none;
  resize: none;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const Textarea = styled.textarea`
  background: ${vars.colors.white};
  width: 100%;
  padding: 0.5rem;
  height: 400px;
  border: 2px solid ${vars.colors.gray};
`

export const TextWithLabel = styled.div<{ extraPadding?: boolean }>`
  background: ${vars.colors.softerYellow};
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 1px ${vars.colors.black};
  padding: ${(props) => (props.extraPadding ? '10px' : '8px')};
  border: 1px solid ${vars.colors.yellow};
`

const StyledRadio = styled.input.attrs(() => ({
  type: 'radio'
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  + span {
    cursor: pointer;
    margin-right: 8px;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    border-radius: 10px;
    border: 1px solid ${vars.colors.primaryGrey};
    background-color: ${vars.colors.white};
  }

  :disabled + span {
    opacity: 0.5;
  }

  :checked + span {
    border-color: ${vars.newColors.primary};
    background-color: ${vars.newColors.primary};

    ::after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: ${vars.colors.white};
    }
  }

  :active + span {
    border-color: ${vars.newColors.primary};
  }

  :hover:not([disabled]) + span {
    border-color: ${vars.newColors.primary};
  }
`

export const StyledCheckbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))<{ useStandardGreen?: boolean }>`
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  + span {
    cursor: pointer;
    margin-right: 8px;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    line-height: 16px;
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    border-radius: 2px;
    border-sizing: border-box;
    color: ${vars.colors.black};
    border: 1px solid ${vars.colors.primaryGrey};
    background-color: ${vars.colors.white};

    ::after {
      content: '\u00A0';
    }
  }

  :disabled + span {
    opacity: 0.5;
  }

  :checked + span {
    border-color: ${(props) => (props.useStandardGreen ? vars.newColors.primaryGreen : vars.newColors.primary)};
    background-color: ${(props) => (props.useStandardGreen ? vars.newColors.primaryGreen : vars.newColors.primary)};

    ::after {
      content: '✓';
      color: ${vars.colors.white};
    }
  }

  :focus + span {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
  }

  :hover:not([disabled]) + span {
    border-color: ${(props) => (props.useStandardGreen ? vars.newColors.primaryGreen : vars.newColors.primary)};
  }
`

export const Radio = (props: any) => (
  <FlexLabel style={{ cursor: 'pointer', ...props.style }}>
    <StyledRadio {...props} style={null} children={null} /> {/* eslint-disable-line react/no-children-prop */}
    <span />
    {props.children}
  </FlexLabel>
)

export const Checkbox = (props: any) => (
  <FlexLabel style={{ cursor: 'pointer', ...props.style }}>
    <StyledCheckbox {...props} style={null} children={null} /> {/* eslint-disable-line react/no-children-prop */}
    <span />
    {props.children}
  </FlexLabel>
)
