import { fromJS, List, Map } from 'immutable'
import { AnyAction } from 'redux'
import {
  GET_SUGGESTED_UNITS_FOR_LOCATION,
  GET_SUGGESTED_UNITS_ON_DELIVERY,
  GET_SUGGESTED_UNITS_ON_PICKUP
} from '../actions/actionTypes'

const initialState = List<string>()

export function suggestedUnitsForLocationReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_SUGGESTED_UNITS_FOR_LOCATION.SUCCESS:
      return List(fromJS(action.body))
    default:
      return state
  }
}

export function suggestedUnitsOnPickupReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_SUGGESTED_UNITS_ON_PICKUP.SUCCESS:
      return state.withMutations((mState) =>
        fromJS(action.body).map((v: unknown, k: unknown) => mState.setIn([k], v) || Map())
      )
    default:
      return state
  }
}

export function suggestedUnitsOnDeliveryReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_SUGGESTED_UNITS_ON_DELIVERY.SUCCESS:
      return state.withMutations((mState) =>
        fromJS(action.body).map((v: unknown, k: unknown) => mState.setIn([k], v) || Map())
      )
    default:
      return state
  }
}
