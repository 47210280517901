import { fromJS, List } from 'immutable'
import { AnyAction } from 'redux'
import { GET_NUMBER_SERIES_VALUES, ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'
import { ImmutableMap, ImmutableMapFactory } from '../types/immutableTypes'

interface NumberSeriesStateProps {
  shipmentIds: List<number>
  packageIds: List<number>
  isLoadingNumberSeries: boolean
}

interface NumberSeriesState extends ImmutableMap<NumberSeriesStateProps> {}

const initialState = ImmutableMapFactory<NumberSeriesStateProps>({
  shipmentIds: List(),
  packageIds: List(),
  isLoadingNumberSeries: false
}) as NumberSeriesState

export default function reducer(state: NumberSeriesState = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_NUMBER_SERIES_VALUES.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('shipmentIds', fromJS(action.body.GSIN) as List<number>)
          .set('packageIds', fromJS(action.body.SSCC) as List<number>)
          .set('isLoadingNumberSeries', false)
      )
    case GET_NUMBER_SERIES_VALUES.REQUEST:
      return state.set('isLoadingNumberSeries', true)
    case GET_NUMBER_SERIES_VALUES.FAILURE:
      return state.set('isLoadingNumberSeries', false)
    case ROUTER_LOCATION_CHANGE_ACTION:
      return initialState
    default:
      return state
  }
}
