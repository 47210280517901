import { List, Map } from 'immutable'
import { SAVED_ADMIN_PREADVICE_SEARCH_PHRASE, ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'

const initialState = Map({
  adminPreadviceSearchPhrase: '',
  adminPreadviceFilterOption: ''
})

interface SaveSelectedOrderIdsAction {
  type: typeof SAVED_ADMIN_PREADVICE_SEARCH_PHRASE
  searchPhrase: string
  filterOption: string
}

interface LocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE_ACTION
  body: Map<number, List<unknown>>
}

type InvoicingDataAction = LocationChangeAction | SaveSelectedOrderIdsAction

export default function reducer(state = initialState, action: InvoicingDataAction) {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE_ACTION:
      if (document.location.href.includes('admin/preadvice')) {
        return state
      }
      return initialState
    case SAVED_ADMIN_PREADVICE_SEARCH_PHRASE:
      return state
        .set('adminPreadviceSearchPhrase', action.searchPhrase)
        .set('adminPreadviceFilterOption', action.filterOption)
    default:
      return state
  }
}
