import { GET_HD_SERVICES_CHECKOUT } from '../actions/actionTypes'
import { Collection, fromJS, Map } from 'immutable'
import { ImmutableMap } from '../types/immutableTypes'
import { AnyData } from '../actions/creators/baseHelpers'

const initialState: HdServicesStateImmutable = Map({
  hdServices: Map(),
  errors: [],
  isLoading: false
} as HdServicesState)

export interface HdServicesState {
  hdServices: Collection<unknown, unknown>
  errors: unknown
  isLoading: boolean
}

interface ActionProp {
  type: string
  body: AnyData
  payload: { isFirstRendering: boolean }
}

interface HdServicesStateImmutable extends ImmutableMap<HdServicesState> {}

export default (state: HdServicesStateImmutable = initialState, action: ActionProp): HdServicesStateImmutable => {
  switch (action.type) {
    case GET_HD_SERVICES_CHECKOUT.SUCCESS:
      return state.withMutations((s) =>
        s
          .set('hdServices', fromJS(action.body.deliveryServices))
          .set('errors', fromJS(action.body.errors))
          .set('isLoading', false)
      )
    case GET_HD_SERVICES_CHECKOUT.REQUEST:
      return state.set('isLoading', true)
    case GET_HD_SERVICES_CHECKOUT.FAILURE:
      return state.set('isLoading', false)
    default:
      return state
  }
}
