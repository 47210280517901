import { fromJS, Map } from 'immutable'
import {
  CLEAR_OPTIMIZING_DATA,
  GET_LATEST_OPTIMIZING_ID_BY_SLOT,
  GET_OPTIMIZING_DATA,
  GET_OPTIMIZING_ERROR_DATA,
  GET_OPTIMIZING_REQUEST_DATA,
  GET_OPTIMIZING_RESPONSE_DATA
} from '../actions/actionTypes'

const initialState = Map({
  optimizingMap: Map(),
  optimizingIdForSlots: Map(),
  showRow: { request: false, response: false, error: false }
})

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case CLEAR_OPTIMIZING_DATA:
      return initialState
    case GET_OPTIMIZING_DATA.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
          return mState.setIn(['optimizingMap', action.params['from'], k.toString()], v)
        })
      })
    case GET_OPTIMIZING_REQUEST_DATA.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn(['optimizingRequestData', action.params['id']], action.body.request)
      })
    case GET_OPTIMIZING_RESPONSE_DATA.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn(['optimizingResponseData', action.params['id']], action.body.response)
      })
    case GET_OPTIMIZING_ERROR_DATA.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn(['optimizingErrorData', action.params['id']], action.body['errorResponse'])
      })
    case GET_LATEST_OPTIMIZING_ID_BY_SLOT.REQUEST:
      return state.withMutations((mState) => {
        return mState.setIn(['optimizingIdForSlots', action.params['slot']], '')
      })
    case GET_LATEST_OPTIMIZING_ID_BY_SLOT.SUCCESS:
      return state.withMutations((mState) => {
        return mState.setIn(['optimizingIdForSlots', action.params['slot']], action.body)
      })
    default:
      return state
  }
}
