import { ImmutableMap } from '../types/immutableTypes'
import i18next from 'i18next'
import { CustomerIdType } from './customer'

export type DeliveryTimeWindowIdType = number | undefined

export interface DeliveryTimeWindow extends ImmutableMap<DeliveryTimeWindowProps> {}

export interface DeliveryTimeWindowProps {
  id: DeliveryTimeWindowIdType
  customerId: CustomerIdType
  postalCode: string
  dayOfWeek: number
  timeFrom: string
  timeTo: string
  pickupTimeFrom: string
  pickupTimeTo: string
  zoneNumber: number
  billingType: BillingType
  cutoffDay: number
  cutoffTime: string
  serviceCode: string
}

export enum BillingType {
  DAY = 'DAY',
  EVENING = 'EVENING',
  WEEKEND = 'WEEKEND',
  WEEKEND_EVENING = 'WEEKEND_EVENING'
}

export function getDisplayNameForBillingType(billingType: BillingType) {
  switch (billingType) {
    case BillingType.DAY:
      return i18next.t('billingType.day')
    case BillingType.EVENING:
      return i18next.t('billingType.evening')
    case BillingType.WEEKEND:
      return i18next.t('billingType.weekend')
    case BillingType.WEEKEND_EVENING:
      return i18next.t('billingType.weekendEvening')
    default:
      console.log('unknown billing type:-', billingType, '-')
      return ''
  }
}
