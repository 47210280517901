import { Collection } from 'immutable'
import React from 'react'
import { DragSource, DragSourceMonitor } from 'react-dnd'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { Entity } from '../../types/coreEntitiesTypes'
import InstantGridGroupRow, { InstantGridGroupRowProps } from './InstantGridGroupRow'
import isFunction from 'lodash/isFunction'

interface DragDropProps<T> {
  group: Collection<number, Entity<T>>
  id: number
  index: number
  getDropResult: () => any
  onDragEnd: (
    resultId: number,
    row: Collection<number, Entity<T>>,
    id: number,
    index: number,
    resultIndex: number
  ) => void
}

const InstantGridRowSource = {
  beginDrag<T>(props: DragDropProps<T>) {
    return {
      id: props.id,
      index: props.index
    }
  },
  endDrag<T>(props: DragDropProps<T>, monitor: DragSourceMonitor<unknown, DragDropProps<T>>) {
    const dropResult = monitor.getDropResult()
    if (dropResult && dropResult.id && isFunction(props.onDragEnd)) {
      props.onDragEnd(dropResult.id, props.group, props.id, props.index, dropResult.index)
    }
  }
}

class InstantGridDraggableGroupRow<T> extends ImmutableComponent<InstantGridGroupRowProps<T>> {
  componentDidMount() {
    const { connectDragPreview, dragPreview } = this.props

    if (dragPreview && connectDragPreview) {
      const img = new Image()
      img.src = dragPreview
      img.onload = () => connectDragPreview(img)
    }
  }

  render() {
    const { connectDragSource, ...restProps } = this.props

    const setRef = (el: any) => connectDragSource && connectDragSource(el)

    return <InstantGridGroupRow setRef={setRef} isDragEnabled={true} {...restProps} />
  }
}

export default DragSource('order', InstantGridRowSource, (connect: any) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview()
}))(InstantGridDraggableGroupRow)
