import React, { CSSProperties } from 'react'
import TimeField from 'react-simple-timefield'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import vars from '../../styles/variables'

interface Props {
  format?: string
  onChange: (time: string) => void
  placeholder?: string
  style?: CSSProperties
  value?: string
  disabled?: boolean
  preventSubmitOnEnter?: boolean
}

export default class TimePicker extends ImmutableComponent<Props> {
  onTimeChange = (_event: any, time: string) => {
    this.props.onChange(time || '')
  }

  render(): React.ReactNode {
    const input =
      this.props.preventSubmitOnEnter === true ? (
        <input disabled={this.props.disabled} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} />
      ) : (
        <input disabled={this.props.disabled} />
      )

    return (
      <TimeField
        value={this.props.value || ''}
        input={input}
        style={{
          background: `${vars.colors.white}`,
          width: '100%',
          padding: '0.6rem',
          color: vars.colors.black,
          borderRadius: '4px',
          boxShadow: `0 0 1px ${vars.colors.black}`,
          outline: 0,
          textAlign: 'center',
          '&:hover': {
            // Does not work as intended
            boxShadow: `0 0 4px ${vars.colors.black}`
          },
          ...this.props.style
        }}
        onChange={this.onTimeChange}
      />
    )
  }
}
