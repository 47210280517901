import { List, Map } from 'immutable'
import {
  CLEAR_ALL_COLUMN_FILTERS,
  CLEAR_COLUMN_FILTER,
  CLEAR_OPTIMIZE_RESULTS,
  HIDE_POPOVER,
  SET_COLUMN_FILTER,
  TOGGLE_POPOVER
} from '../actions/actionTypes'
import { Filter, parseFilters } from '../pages/instant/filterFunctions'
import { ImmutableMap, ImmutableMapFactory } from '../types/immutableTypes'

export interface TogglePopoverAction {
  type: typeof TOGGLE_POPOVER
  gridId: string
  column: string
}

export interface HidePopoverAction {
  type: typeof HIDE_POPOVER
  gridId: string
  column: string
}

export interface SetFilterAction {
  type: typeof SET_COLUMN_FILTER
  gridId: string
  column: string
  filter: string
}

export interface ClearFilterAction {
  type: typeof CLEAR_COLUMN_FILTER
  gridId: string
  column: string
}

export interface ClearAllFiltersAction {
  type: typeof CLEAR_ALL_COLUMN_FILTERS
  gridId: string
}

export interface ClearOptimizeJobResultAction {
  type: typeof CLEAR_OPTIMIZE_RESULTS
}

interface FilterReducerStateProps {
  [gridId: string]: FilterStateProps
}

export interface FilterStateProps {
  popovers?: Map<string, boolean>
  filters?: Map<string, FilterProps>
}

export interface FilterProps {
  input: string
  filters: List<Filter>
}

export interface FilterState extends ImmutableMap<FilterStateProps> {}

export type FilterActionType =
  | TogglePopoverAction
  | HidePopoverAction
  | SetFilterAction
  | ClearFilterAction
  | ClearAllFiltersAction

const initialState = ImmutableMapFactory<FilterReducerStateProps>({})

export default function reducer(state: FilterState = initialState, action: FilterActionType): FilterState {
  switch (action.type) {
    case TOGGLE_POPOVER:
      return state.updateIn([action.gridId, 'popovers'], (prev) =>
        Map({ [action.column]: !(prev as Map<string, boolean>)?.get(action.column) || false })
      )
    case HIDE_POPOVER:
      return state.setIn([action.gridId, 'popovers'], Map())
    case SET_COLUMN_FILTER:
      return state.setIn([action.gridId, 'filters', action.column], parseFilters(action.filter))
    case CLEAR_COLUMN_FILTER:
      return state.removeIn([action.gridId, 'filters', action.column])
    case CLEAR_ALL_COLUMN_FILTERS:
      return state.setIn([action.gridId, 'filters'], Map())
    default:
      return state
  }
}
