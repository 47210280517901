export function initErrorHandlers() {
  initLogger()
}

function initLogger() {
  window.onerror = function (messageOrEvent, source, lineno, colno, error) {
    console.log({
      type: 'Runtime',
      messageOrEvent,
      source,
      lineno,
      colno,
      error: JSON.stringify(error)
    })
    return false
  }
}
