import styled from 'styled-components'
import vars from '../styles/variables'
import ErrorContainer from './ErrorContainer'
import React from 'react'
import { List } from 'immutable'

interface ErrorMessagesProps {
  errorMessages: List<string> | null
  noMargin?: boolean
}

export const ErrorMessages = (props: ErrorMessagesProps) => {
  return (
    <ErrorContainer center noMargin={props.noMargin}>
      {props.errorMessages && props.errorMessages.map((msg: string, key) => <div key={key}>{msg}</div>)}
    </ErrorContainer>
  )
}

export const MaybeErrorMessages = (props: ErrorMessagesProps) => {
  if (!props.errorMessages || props.errorMessages.size === 0) {
    return null
  }

  return <ErrorMessages {...props} />
}

export const ValidationError = styled.div<{ marginLeft?: boolean }>`
  color: ${vars.newColors.deepRed};
  font-size: 1em;
  margin-left: ${(props) => props.marginLeft && '0.5em'};
`

export const SmallValidationError = styled.div`
  color: ${vars.newColors.deepRed};
  font-size: 0.8em;
`

export const ErrorMessage = styled.div`
  color: ${vars.newColors.deepRed};
`
