import { Collection, fromJS, Map } from 'immutable'
import {
  CLEAR_ORDER_IDS_ON_MOVED_ORDERS,
  COPY_DEVIATED_ORDER,
  DELETED_ORDERS_EXTENDED_SEARCH,
  GET_CONSIGNMENTS_FOR_INVOICE_HANDLING,
  GET_CONSIGNMENTS_FOR_PRE_ADVICE_FOLLOWUP,
  GET_CONSIGNMENTS_FOR_RETURN_HANDLING,
  SEARCH_CONSIGNMENTS,
  SEARCH_DELETED_ORDERS,
  SEARCH_ORDERS
} from '../actions/actionTypes'
import { AnyData } from '../actions/creators/baseHelpers'
import { ConsignmentIdType, OrderIdType } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'

const initialState: SearchConsignmentImmutable = Map({
  consignments: Map(),
  deletedOrders: Map(),
  orderSearchs: Map(),
  orders: Map()
} as SearchConsignmentState)

interface SearchConsignmentState {
  consignments: Collection<unknown, any>
  deletedOrders: Collection<unknown, any>
  orderSearchs: AnyData
  orders: AnyData
}

interface ActionProp {
  type:
    | typeof SEARCH_CONSIGNMENTS.SUCCESS
    | typeof SEARCH_DELETED_ORDERS.SUCCESS
    | typeof DELETED_ORDERS_EXTENDED_SEARCH.SUCCESS
    | typeof SEARCH_ORDERS.SUCCESS
    | typeof CLEAR_ORDER_IDS_ON_MOVED_ORDERS
    | typeof COPY_DEVIATED_ORDER.SUCCESS
  body: SearchConsignmentState
  payload: { orderIds: OrderIdType[]; consignmentIds?: ConsignmentIdType[] }
}

interface SearchConsignmentImmutable extends ImmutableMap<SearchConsignmentState> {}

export default function reducer(state = initialState, action: ActionProp) {
  switch (action.type) {
    case SEARCH_CONSIGNMENTS.SUCCESS:
    case SEARCH_DELETED_ORDERS.SUCCESS:
    case DELETED_ORDERS_EXTENDED_SEARCH.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: any, k: any) => {
          return mState.setIn([k.toString()], v.keySeq())
        })
      })
    case SEARCH_ORDERS.SUCCESS:
      return state.withMutations((mState) => mState.set('orderSearchs', fromJS(action.body.orderSearchs).toList()))
    case GET_CONSIGNMENTS_FOR_INVOICE_HANDLING.SUCCESS:
      return state.withMutations((mState) => mState.set('orderSearchs', fromJS(action.body.orderSearchs).toList()))
    case GET_CONSIGNMENTS_FOR_RETURN_HANDLING.SUCCESS:
    case GET_CONSIGNMENTS_FOR_PRE_ADVICE_FOLLOWUP.SUCCESS:
      return state.withMutations((mState) => mState.set('orderSearchs', fromJS(action.body.orderSearchs).toList()))
    case CLEAR_ORDER_IDS_ON_MOVED_ORDERS:
      const orderIds = action.payload.orderIds
      const consigmentIds = action.payload.consignmentIds?.map((c) => c + '') ?? []

      return state.withMutations((mState) =>
        mState
          .set(
            'orderSearchs',
            mState.get('orderSearchs')?.filter((x: AnyData) => !orderIds.includes(x.get('orderId'))) ?? Map()
          )
          .set(
            'consignments',
            mState.get('consignments')?.filter((consignmentId: string) => !consigmentIds.includes(consignmentId)) ??
              Map()
          )
      )

    case COPY_DEVIATED_ORDER.SUCCESS: {
      const searchResults = state.get('orderSearchs')
      const order = Object.values(action.body.orders)[0]

      if (!order || !order.parentId) {
        return state
      }

      const parentIndex = searchResults.findIndex((o: AnyData) => o.orderId === order.parentId)

      if (!parentIndex) {
        return state
      }

      return state.set('orderSearchs', searchResults.insert(parentIndex, Map({ orderId: order.id })))
    }
    default:
      return state
  }
}
