import React from 'react'
import { DragSource, DragSourceMonitor, DropTarget, DropTargetMonitor } from 'react-dnd'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { Shipment } from '../../types/coreEntitiesTypes'
import InstantGridRow, { InstantGridRowProps } from './InstantGridRow'
import variables from '../../styles/variables'
import isFunction from 'lodash/isFunction'

interface InstantGridDroppableRowProps<T> extends InstantGridRowProps<T> {
  connectDragPreview?: (img: HTMLImageElement) => void
  connectDragSource?: (element: JSX.Element) => void
  connectDropTarget?: (element: JSX.Element) => JSX.Element
  isOver?: boolean
  dragPreview?: string
  isDragging?: boolean
  item: any
}

interface DragDropProps {
  row: Shipment
  id: number
  index: number
  getDropResult: () => any
  moveRow: (dragIndex: number, hoverIndex: number) => void
  onDragEnd: (resultId: number, row: Shipment, id: number, index: number, resultIndex: number) => void
}

const InstantGridRowSource = {
  beginDrag(props: DragDropProps) {
    return {
      id: props.id,
      index: props.index
    }
  },
  endDrag(props: DragDropProps, monitor: DragSourceMonitor<unknown, DragDropProps>) {
    const dropResult = monitor.getDropResult()
    if (dropResult && dropResult.id && isFunction(props.onDragEnd)) {
      props.onDragEnd(dropResult.id, props.row, props.id, props.index, dropResult.index)
    }
  }
}

class InstantGridDroppableRow<T> extends ImmutableComponent<InstantGridDroppableRowProps<T>> {
  render() {
    const { connectDragSource, connectDropTarget, isOver, item, ...restProps } = this.props

    const borderStyle = `2px solid ${variables.newColors.primary}`

    const setRef = (el: any) => {
      connectDragSource && connectDropTarget && connectDragSource(connectDropTarget(el))
    }

    const dragIndex = item?.index || 0
    const dropIndex = this.props?.index || 0

    return (
      <InstantGridRow
        style={{
          borderTop: isOver && dragIndex > dropIndex ? borderStyle : '',
          borderBottom: isOver && dropIndex > dragIndex ? borderStyle : ''
        }}
        setRef={setRef}
        isDragEnabled={true}
        {...restProps}
      />
    )
  }
}

const gridTarget = {
  drop(props: DragDropProps, monitor: DropTargetMonitor) {
    if (monitor.didDrop()) {
      // Drop event has been handled by a nested drop-target
      // Not relevant here, but it's a nice safety feature to keep
      return
    }
    return {
      id: props.id,
      index: props.index
    }
  }
}

function collect(connect: any, monitor: DropTargetMonitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    item: monitor.getItem()
  }
}

export default DropTarget(
  'order',
  gridTarget,
  collect
)(
  DragSource('order', InstantGridRowSource, (connect: any) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview()
  }))(InstantGridDroppableRow)
)
