import { getAddressFromPlace, getInitialAddress } from '@glow/common-components'
import i18next from 'i18next'
import uniqueId from 'lodash/uniqueId'
import React, { CSSProperties } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { AutocompleteContainer, SuggestionActive, SuggestionInactive } from '../primitives/Autocomplete'
import { Input, SearchInput } from '../primitives/Forms'
import { isNotEmpty } from '../utils/collectionUtils'

interface Props {
  address: string
  countryCode: string
  onFoundAddress: (value: any) => void
  onComplete: (value: any) => void
  searchAroundArea: { lat: number; lng: number }
  rounded: boolean
  disabled: boolean
  style: CSSProperties
}

interface State {
  address: string
  ariaControlsId: string
}
export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      address: props.address ? props.address : '',
      ariaControlsId: uniqueId('ariaControlsId')
    }
  }

  componentDidMount() {
    if (this.props.address) {
      getInitialAddress(this.props.address, this.props.countryCode, (address) => {
        const addressFromPlace = address ? getAddressFromPlace(address) : null
        this.props.onFoundAddress && addressFromPlace && this.props.onFoundAddress(addressFromPlace)
      })
    }
  }

  handleSelect(address: any) {
    geocodeByAddress(address).then((results: any[]) => {
      const address = getAddressFromPlace(results[0])
      this.props.onComplete && this.props.onComplete(address)
    })
  }

  render() {
    const { searchAroundArea, rounded, disabled, style } = this.props
    const placeholder = i18next.t('application.autoComplete')

    const searchOptions = searchAroundArea
      ? {
          location: new google.maps.LatLng(searchAroundArea.lat, searchAroundArea.lng), // eslint-disable-line no-undef
          radius: 10000,
          types: ['address']
        }
      : undefined

    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={this.state.address}
        onChange={(address: any) => this.setState({ address })}
        onSelect={(address: any) => this.handleSelect(address)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
          <div>
            {rounded ? (
              <SearchInput
                style={style}
                {...getInputProps({ placeholder })}
                disabled={disabled}
                aria-controls={this.state.ariaControlsId}
              />
            ) : (
              <Input
                {...getInputProps({ placeholder })}
                disabled={disabled}
                aria-controls={this.state.ariaControlsId}
              />
            )}
            {isNotEmpty(suggestions) && (
              <AutocompleteContainer id={this.state.ariaControlsId}>
                {suggestions.map((suggestion: { active: any; placeId: any; description: any }) => {
                  return suggestion.active ? (
                    <SuggestionActive {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                      <span>{suggestion.description}</span>
                    </SuggestionActive>
                  ) : (
                    <SuggestionInactive {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                      <span>{suggestion.description}</span>
                    </SuggestionInactive>
                  )
                })}
              </AutocompleteContainer>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
