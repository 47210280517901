import { applyMiddleware, compose, createStore } from 'redux'
import reducers from './reducers'
import { rafScheduler } from '../middleware/rafScheduler'
import { thunk } from 'redux-thunk'
import { wsMiddleware } from '../middleware/ws'

// @ts-expect-error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(reducers(), composeEnhancers(applyMiddleware(rafScheduler, thunk, wsMiddleware)))

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
