import styled from 'styled-components'
import vars from '../styles/variables'

export const PrimaryButton = styled.button<{
  marginBottom?: boolean
  marginLeft?: boolean
  expand?: boolean
  background?: string
}>`
  background: ${(props) => props.background ?? vars.colors.darkGray};
  color: ${vars.newColors.white};
  display: ${(props) => (props.expand ? 'block' : 'inline-block')};
  width: ${(props) => (props.expand ? '100%' : 'auto')};
  height: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 40px;
  margin-bottom: ${(props) => (props.marginBottom ? '1rem' : '0')};
  margin-left: ${(props) => (props.marginLeft ? '1rem' : '0')};

  svg {
    color: ${vars.newColors.white};
  }

  :enabled:hover:not(:focus, :active) {
    opacity: 0.85;
  }

  :enabled:active {
    background: ${vars.newColors.black};
  }

  &:disabled {
    background: ${vars.newColors.orbitGrey};
    color: ${vars.newColors.stellarGrey};
    cursor: default;

    svg {
      color: ${vars.newColors.spaceGrey};
    }
  }
`

export const SecondaryButton = styled.button<{ marginLeft?: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  height: ${vars.inputs.height};
  background-color: transparent;
  color: ${vars.newColors.black};
  border: 1px solid ${vars.newColors.black};
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  margin-left: ${(props) => (props.marginLeft ? '1rem' : '0')};

  svg {
    color: ${vars.newColors.black};
  }

  :enabled:hover:not(:focus, :active) {
    box-shadow: 0 0 0 1px ${vars.newColors.black};
  }

  :enabled:active {
    box-shadow: 0 0 0 1px ${vars.newColors.black};
    opacity: 0.6;
  }

  :disabled {
    border-color: ${vars.newColors.spaceGrey};
    color: ${vars.newColors.spaceGrey};
    cursor: default;

    svg {
      color: ${vars.newColors.spaceGrey};
    }
  }
`

export const TertiaryButton = styled.button<{ marginLeft?: boolean }>`
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  height: ${vars.inputs.height};
  background-color: transparent;
  color: ${vars.newColors.black};
  border: 1px solid ${vars.newColors.stellarGrey};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  margin-left: ${(props) => (props.marginLeft ? '1rem' : '0')};

  svg {
    color: ${vars.newColors.darkGrey};
  }

  :enabled:hover:not(:focus, :active) {
    box-shadow: 0 0 0 1px ${vars.newColors.black};
    border: 1px solid ${vars.newColors.black};
  }

  :enabled:active {
    box-shadow: 0 0 0 1px ${vars.newColors.stellarGrey};
    opacity: 0.6;
  }

  :disabled {
    border-color: ${vars.newColors.spaceGrey};
    color: ${vars.newColors.spaceGrey};
    cursor: default;

    svg {
      color: ${vars.newColors.spaceGrey};
    }
  }
`

export const ToggleButton = styled.button<{ selected?: boolean; marginLeft?: boolean }>`
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  height: ${vars.inputs.height};
  background-color: ${(props) => (props.selected ? vars.newColors.spaceGrey : 'transparent')};
  color: ${vars.newColors.black};
  border: 1px solid ${vars.newColors.stellarGrey};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
  margin-left: ${(props) => (props.marginLeft ? '1rem' : '0')};

  svg {
    color: ${vars.newColors.darkGrey};
  }

  :enabled:hover:not(:focus) {
    box-shadow: 0 0 0 1px ${vars.newColors.black};
    border: 1px solid ${vars.newColors.black};
  }

  :disabled {
    border-color: ${vars.newColors.spaceGrey};
    color: ${vars.newColors.spaceGrey};
    cursor: default;

    svg {
      color: ${vars.newColors.spaceGrey};
    }
  }
`

export const LinkButton = styled.button`
  cursor: pointer;
  text-decoration: underline;
`

export const DropdownButton = styled.button`
  background: ${vars.colors.white};
  box-sizing: border-box;
  height: 3.5rem;
  min-width: 19rem;
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9rem;
  cursor: pointer;

  :focus {
    outline: 0;
  }

  :enabled:active {
    box-shadow: ${vars.boxShadow.medium};
  }

  :disabled {
    background: ${vars.colors.gray12};
    border-color: ${vars.colors.gray13};
    color: ${vars.colors.gray14};
    cursor: default;
  }

  :enabled:hover {
    background-color: ${vars.newColors.skyBlue};
  }
`
