import { Map } from 'immutable'
import {
  MODAL_CLOSE,
  MODAL_CLOSE_KEEP_PAGE_STATE,
  PAGE_STATE_ADD,
  PAGE_STATE_CLEAR,
  ROUTER_LOCATION_CHANGE_ACTION
} from '../actions/actionTypes'
import { AnyAction } from 'redux'

const initialState = Map({})

export const pageState = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case PAGE_STATE_ADD:
      return state.set(action.key, action.value)
    case PAGE_STATE_CLEAR:
    case MODAL_CLOSE:
    case ROUTER_LOCATION_CHANGE_ACTION:
      return initialState
    case MODAL_CLOSE_KEEP_PAGE_STATE:
      return state
    default:
      return state
  }
}
