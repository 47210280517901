import React, { ButtonHTMLAttributes, CSSProperties, FunctionComponent } from 'react'
import styled from 'styled-components'
import groupSpaceGraySvg from '../assets/icons/groupSpaceGray.svg'
import groupDarkSvg from '../assets/icons/groupDarkGray.svg'
import multilegSpaceGraySvg from '../assets/icons/multilegSpaceGray.svg'
import multilegDarkGraySvg from '../assets/icons/multilegDarkGray.svg'

import { PrimaryButton, SecondaryButton, TertiaryButton } from './Button'
import { Icon } from './InstantGridStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonIcon = styled(Icon)`
  bottom: 2px;
  margin-right: 0.5em;
`

export const OptimizeButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <TertiaryButton {...props}>
    <FontAwesomeIcon icon="sync-alt" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </TertiaryButton>
)

export const AcceptButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <PrimaryButton {...props}>
    <FontAwesomeIcon icon="check" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </PrimaryButton>
)

export const CancelButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <SecondaryButton {...props}>
    <FontAwesomeIcon icon="times" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </SecondaryButton>
)

export const RemoveButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <TertiaryButton {...props}>
    <FontAwesomeIcon icon="trash" style={{ marginRight: '0.5em' }} />
    {props.children}
  </TertiaryButton>
)

export const SplitButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <IconButton
    buttonComponent={TertiaryButton}
    icon={multilegDarkGraySvg}
    iconDisabled={multilegSpaceGraySvg}
    {...props}
  />
)

export const GroupButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <IconButton buttonComponent={TertiaryButton} icon={groupDarkSvg} iconDisabled={groupSpaceGraySvg} {...props} />
)

export const MapButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <TertiaryButton {...props}>
    <FontAwesomeIcon icon="map-marked-alt" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </TertiaryButton>
)

export const SearchButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <PrimaryButton {...props}>
    <FontAwesomeIcon icon="search" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </PrimaryButton>
)

export const SearchSecondaryButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <SecondaryButton {...props}>
    <FontAwesomeIcon icon="search" />
    <span style={{ paddingLeft: '0.5em' }}>{props.children}</span>
  </SecondaryButton>
)

interface Props {
  buttonComponent: FunctionComponent
  icon: any
  iconDisabled: any
  iconStyle?: CSSProperties
}

export const IconButton = (props: Props & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const children = (
    <div>
      <ButtonIcon src={props.disabled ? props.iconDisabled : props.icon} style={props.iconStyle} />
      {props.children}
    </div>
  )
  return React.createElement<ButtonHTMLAttributes<HTMLButtonElement>>(props.buttonComponent, props, children)
}
