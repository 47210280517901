import range from 'lodash/range'
import React from 'react'
import variables from '../styles/variables'

const minSpeedFactor = 0.5
const maxSpeedFactor = 2
const speedFactorStep = 0.1
const widthOfFactor = (100 / (maxSpeedFactor - minSpeedFactor)) * speedFactorStep
const speedFactorRange = range(minSpeedFactor, maxSpeedFactor, speedFactorStep).map((sf) => `${sf.toFixed(1)}`)
export const speedFactorHeader = speedFactorRange.map((sf, index) => (
  <div
    key={`calendar_${index}`}
    className={`inline-block text-xs text-left border-solid border-l border-b border-t-0 border-r-0 border-gray-300 text-[${variables.colors.black}]`}
    style={{
      width: `${widthOfFactor}%`
    }}
  >
    {sf}
  </div>
))

export const startOfDay = 4
export const endOfDay = 24
