import styled from 'styled-components'
import vars from '../styles/variables'

// From GLOW design system
export type HeadingSize = 'display' | 'xl' | 'l' | 'm' | 's'

const defaultH1Size = 'xl'
const defaultH2Size = 'l'
const defaultH3Size = 'm'
const defaultH4Size = 's'

const headingSizeStyle = (size: HeadingSize) => {
  switch (size) {
    case 'display':
      return `
        font-size: 2rem;
        font-weight: 700;
      `
    case 'xl':
      return `
        font-size: 1.75rem;
        font-weight: 700;
      `
    case 'l':
      return `
        font-size: 1.5rem;
        font-weight: 700;
      `
    case 'm':
      return `
        font-size: 1.125rem;
        font-weight: 700;
      `
    case 's':
      return `
        font-size: 1.125rem;
        font-weight: 600;
      `
  }
}

interface IHeading {
  size?: HeadingSize
  textAlign?: boolean
  noMargin?: boolean
  marginBottom?: string
  color?: string
  topPadding?: boolean
}

export const H1 = styled.h1<IHeading>`
  ${(props) => headingSizeStyle(props.size || defaultH1Size)}
  margin-top: 0;
  color: ${vars.newColors.black};
  text-align: ${(props) => props.textAlign && 'center'};
  margin-bottom: ${(props) => (props.noMargin ? '0' : 'rem')};

  img {
    transform: translateY(-2px);
  }
`

export const H2 = styled.h2<IHeading>`
  ${(props) => headingSizeStyle(props.size || defaultH2Size)}
  margin: 0;
  color: ${vars.newColors.black};
  margin-bottom: ${(props) => (props.noMargin ? '0' : '1rem')};
  text-align: ${(props) => (props.textAlign ? 'center' : 'left')};
  ${(props) => props.topPadding && 'padding-top: 1rem;'}
  img {
    transform: translateY(-2px);
  }
`

export const H3 = styled.h3<IHeading>`
  ${(props) => headingSizeStyle(props.size || defaultH3Size)}
  margin-top: 0;
  color: ${(props) => (props.color ? props.color : vars.newColors.black)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '.5rem')};

  img {
    transform: translateY(-2px);
  }
`

export const H4 = styled.h3<IHeading>`
  ${(props) => headingSizeStyle(props.size || defaultH4Size)}
  margin-top: 0;
  color: ${(props) => (props.color ? props.color : vars.newColors.black)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '1rem')};

  img {
    transform: translateY(-2px);
  }
`
