import delay from 'lodash/delay'
import { ThunkDispatch } from './actions/creators/baseHelpers'

let currentRevision: string | null = null
const waitTime = 30 * 1000
export let appNeedsRefresh = false

export const checkRevision = (dispatch: ThunkDispatch) => {
  fetch('/revision', { credentials: 'include' }).then(
    (res) => {
      res.text().then((revision) => {
        if (currentRevision && currentRevision !== revision) {
          appNeedsRefresh = true
          console.log('New revision ' + currentRevision + ' => ' + revision, appNeedsRefresh)
        }
        currentRevision = revision
        delay(() => {
          checkRevision(dispatch)
        }, waitTime)
      })
    },
    () => {
      console.error('Could not load revision, frontend-backend down?')
      delay(() => {
        checkRevision(dispatch)
      }, waitTime)
    }
  )
}
