import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import isFunction from 'lodash/isFunction'
import toString from 'lodash/toString'
import React, { CSSProperties, ReactNode, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import dragIcon from '../../assets/icons/dragIcon24px.png'
import { ColumnDefinition } from '../../components/grid/GridColumns'
import { Checkbox } from '../../primitives/Forms'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import { GroupProps, PopoverButton, Row } from '../../primitives/InstantGridStyles'
import vars from '../../styles/variables'
import { Entity, Shipment } from '../../types/coreEntitiesTypes'
import { InstantGridAction, noop, noop2 } from './InstantGrid'

interface InstantGridColumnProps {
  selected: boolean
  id?: number
  value?: string
  checked?: boolean
  disabled?: boolean
  onChange?: () => void
  onClick?: () => void
  style?: CSSProperties
  title?: string
  showContent?: boolean
}

interface InstantGridPriorityColumnProps {
  selected: boolean
  priority: string
  clickable?: boolean
  style?: CSSProperties
  children?: ReactNode
}

const PriorityColors: { [key: string]: string } = {
  normal: '',
  high: vars.colors.pink
}

// TODO: 10px is the intrinsic width of the icon at time of writing this comment (can we do it better?)
// Set draggable to false to prevent Firefox from opening the image file on drop.
const dragImage = <img className="min-w-[10px] ml-1 inline" src={dragIcon} alt="drag&drop" draggable={false} />
const assignTooltip = i18next.t('instant.instantGrid.assignColumnTooltip', 'Click to assign shipment')
const AssignIcon = <FontAwesomeIcon icon="plus" title={assignTooltip} />
const unassignTooltip = i18next.t('instant.instantGrid.unassignColumnTooltip', 'Click to unassign shipment')
const UnassignIcon = <FontAwesomeIcon icon="sign-out-alt" title={unassignTooltip} />
const dragTooltip = i18next.t('instant.instantGrid.dragColumnTooltip', 'Click and drag to assign to courier')
const deleteTooltip = i18next.t('instant.instantGrid.deleteColumnTooltip', 'Click to delete')
const deleteIcon = <FontAwesomeIcon icon="trash" title={deleteTooltip} />
const editTooltip = i18next.t('instant.instantGrid.editColumnTooltip', 'Click to edit')
const editIcon = <FontAwesomeIcon icon="pencil-alt" title={editTooltip} />
const actionsIcon = <FontAwesomeIcon icon="ellipsis-v" title={editTooltip} />

export const stopPropagating = (callback?: (event: React.SyntheticEvent) => void) => (event: React.SyntheticEvent) => {
  event.stopPropagation()
  callback && callback(event)
}

export const InstantGridMultiSelectCheckbox = (props: InstantGridColumnProps) => (
  <Checkbox
    id={`rowSelector_${props.id}`}
    type="checkbox"
    value={props.value}
    checked={props.checked}
    onChange={props.onChange}
    disabled={props.disabled}
    onClick={stopPropagating()}
    data-cy="checkbox"
  />
)

export class InstantGridPriorityColumn extends ImmutableComponent<InstantGridPriorityColumnProps> {
  render() {
    const { clickable = false, priority, style, children } = this.props
    return (
      <td
        style={{
          // Margins here should match those in InstantGridStyles!
          margin: '-8px 5px -8px -8px',
          // This is the _one_ where we might actually want to set width (since it has no content...)
          width: '18px',
          textAlign: 'center',
          cursor: clickable ? 'pointer' : 'inherit',
          backgroundColor: PriorityColors[priority],
          ...style
        }}
      >
        {children}
      </td>
    )
  }
}

interface InstantGridDragColumnProps {
  selected: boolean
  style?: CSSProperties
}

export function InstantGridDragColumn(props: InstantGridDragColumnProps) {
  return (
    <td
      title={dragTooltip}
      style={{
        cursor: 'move',
        ...props.style
      }}
    >
      {dragImage}&nbsp;
    </td>
  )
}

export function InstantGridUnassignColumn(props: InstantGridColumnProps) {
  return (
    <td>
      {props.showContent && (
        <button
          type="button"
          title={unassignTooltip}
          style={{
            cursor: 'pointer'
          }}
          onClick={stopPropagating(props.onClick)}
        >
          {UnassignIcon}&nbsp;
        </button>
      )}
    </td>
  )
}

export function InstantGridAssignColumn(props: InstantGridColumnProps) {
  return (
    <td>
      <button
        type="button"
        title={assignTooltip}
        style={{
          cursor: 'pointer'
        }}
        onClick={stopPropagating(props.onClick)}
      >
        {AssignIcon}&nbsp;
      </button>
    </td>
  )
}

export function InstantGridDeleteColumn(props: InstantGridColumnProps) {
  return (
    <td>
      <button
        type="button"
        title={deleteTooltip}
        style={{
          cursor: 'pointer'
        }}
        onClick={stopPropagating(props.onClick)}
      >
        {deleteIcon}&nbsp;
      </button>
    </td>
  )
}

export function InstantGridEditColumn(props: InstantGridColumnProps) {
  return (
    <td>
      <button
        type="button"
        title={editTooltip}
        style={{
          cursor: 'pointer'
        }}
        onClick={stopPropagating(props.onClick)}
      >
        {editIcon}&nbsp;
      </button>
    </td>
  )
}

interface InstantGridActionsColumnProps<T> {
  row: Entity<T>
  actions: List<InstantGridAction<T>>
}

export function InstantGridActionsColumn<T>({ row, actions }: InstantGridActionsColumnProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      positions={['bottom', 'top']}
      align="end"
      containerStyle={{
        zIndex: '10001'
      }}
      content={(rendererArgs) => (
        <ArrowContainer {...rendererArgs} arrowColor={vars.colors.gray5} arrowSize={10}>
          <div
            style={{
              boxShadow: '-10px 10px 10px rgba(0, 0, 0, 0.15)',
              border: `1px solid ${vars.colors.gray5}`,
              borderRadius: '4px',
              backgroundColor: vars.colors.white,
              display: 'flex',
              flexDirection: 'column',
              padding: '4px 0'
            }}
          >
            {actions
              .filter((action) => isFunction(action.action))
              .map(({ icon, text, action, title, enabled }) => {
                const isDisabled = enabled && !enabled(row)
                const showTitle = isDisabled || (isDisabled && title)
                return (
                  <PopoverButton
                    key={`${text}_${title}`}
                    disabled={isDisabled}
                    title={showTitle ? title : ''}
                    onClick={stopPropagating(() => {
                      setIsOpen(false)
                      action && action(row)
                    })}
                  >
                    {icon} {text}
                  </PopoverButton>
                )
              })}
          </div>
        </ArrowContainer>
      )}
    >
      <td style={{ textAlign: 'center' }}>
        <button
          type="button"
          onClick={stopPropagating(() => setIsOpen(!isOpen))}
          style={{
            minWidth: '30px',
            flex: '30 0 auto',
            width: '30px',
            cursor: 'pointer'
          }}
        >
          {actionsIcon}
        </button>
      </td>
    </Popover>
  )
}

export interface InstantGridRowProps<T> {
  row: Entity<T>
  columns: List<ColumnDefinition<T>>
  stateKey?: string
  onEditRow?: (rowId: number, row: Entity<T>) => void
  onClickRow?: (row: Entity<T>) => void
  onDoubleClickRow?: (row: Entity<T>) => void
  overflow?: boolean
  height?: string
  defaultColumnWidth?: number
  isMultiSelectEnabled: boolean
  selectionDisabled?: boolean
  isAssignable?: boolean
  isEditable: boolean
  isDeletable: boolean
  isSelected: boolean
  isUnassignable: boolean | ((row: Entity<T>) => boolean)
  style?: CSSProperties
  onSelectOrderRow?: (row: Entity<T>, columnName?: string) => (event?: React.MouseEvent) => void
  selectedRows?: Map<string, Set<number>>
  onAssignRow?: (row: Entity<T>) => void
  onDeleteRow?: (row: Entity<T>) => void
  onUnassignRow?: (row: Entity<T>) => void
  priority?: string
  onDragEnd?: (resultId: number, row: Shipment, id: number, index: number, resultIndex: number) => void
  index: number
  id: number
  isOverCurrent?: boolean
  isDragNDropEnabled: boolean
  isDropEnabled: boolean
  backgroundColor?: string
  actions?: List<InstantGridAction<T>>
  rowBackgroundColor?: string | undefined
  rowBackgroundHoverColor?: string | undefined
  isDragEnabled?: boolean
  setRef?: (el: any) => void // TODO: Any
  groupProps?: GroupProps
  disabledRows?: Set<number>
}

export default class InstantGridRow<T> extends ImmutableComponent<InstantGridRowProps<T>> {
  render() {
    const {
      row,
      columns,
      stateKey,
      isEditable,
      isMultiSelectEnabled,
      isAssignable,
      onAssignRow = noop,
      isDeletable,
      isUnassignable,
      onUnassignRow = noop,
      onDeleteRow = noop,
      onClickRow,
      onDoubleClickRow,
      onEditRow = noop,
      onSelectOrderRow = noop2,
      priority,
      selectionDisabled = false,
      actions,
      rowBackgroundColor,
      rowBackgroundHoverColor,
      selectedRows,
      isDragEnabled = false,
      isSelected,
      setRef,
      groupProps,
      style,
      disabledRows = Set()
    } = this.props

    const isRowAssignable = row.has('isAssignable') ? row.get('isAssignable') : true
    const isRowSelectable = row.has('isSelectable') ? row.get('isSelectable') : true
    const isRowDeletable = row.has('isDeletable') ? row.get('isDeletable') : true
    const isRowEditable = row.has('isEditable') ? row.get('isEditable') : true
    const isRowClickable = !!onClickRow
    const isDisabled = (disabledRows && disabledRows.has(row.get('id'))) || false

    return (
      <Row
        group={groupProps}
        ref={setRef}
        isClickable={isRowClickable}
        onClick={() => onClickRow && onClickRow(row)}
        onDoubleClick={() => onDoubleClickRow && onDoubleClickRow(row)}
        selected={isSelected}
        rowBackgroundColor={rowBackgroundColor}
        rowBackgroundHoverColor={rowBackgroundHoverColor}
        data-cy="instantGridRow"
      >
        {priority && <InstantGridPriorityColumn selected={isSelected} priority={priority} style={style} />}
        {isDragEnabled && <InstantGridDragColumn selected={isSelected} style={style} />}
        {isMultiSelectEnabled && (
          <td style={style}>
            {isRowSelectable && (
              <InstantGridMultiSelectCheckbox
                selected={isSelected}
                id={row.get('id')}
                value={`${isSelected}`}
                checked={isSelected}
                onChange={onSelectOrderRow(row)}
                disabled={selectionDisabled || isDisabled}
              />
            )}
          </td>
        )}
        {columns.map((column) => {
          if (column.hidden) {
            return null
          }
          const s: CSSProperties = {
            width: column.width || '',
            maxWidth: column.maxWidth || column.width || '150px',
            minWidth: column.minWidth || column.width || '',
            ...column.style,
            ...style
          }
          // @ts-ignore
          const value = row.get(column.dataIndex)
          const title = column.title
            ? isFunction(column.valueRenderer)
              ? column.valueRenderer({ column: toString(column.dataIndex), row, value })
              : toString(value)
            : undefined
          const isColumnSelected =
            selectedRows && selectedRows.get(toString(column.dataIndex), Set()).has(row.get('id'))
          const rendered = isFunction(column.renderer)
            ? column.renderer({
                column: toString(column.dataIndex),
                value,
                row,
                isColumnSelected: isColumnSelected,
                callbackFn: this.props.onSelectOrderRow
              })
            : value
          return (
            <td style={s} title={title} key={`${stateKey}_${row.get('id')}_${String(column.dataIndex)}_${column.name}`}>
              <>{rendered}</>
            </td>
          )
        })}
        {isUnassignable && (
          <InstantGridUnassignColumn
            showContent={typeof isUnassignable == 'function' ? isUnassignable(row) : true}
            selected={isSelected}
            onClick={() => onUnassignRow(row)}
          />
        )}
        {isAssignable &&
          (isRowAssignable ? (
            <InstantGridAssignColumn selected={isSelected} onClick={() => onAssignRow(row)} />
          ) : (
            <td />
          ))}

        {isDeletable &&
          (isRowDeletable ? (
            <InstantGridDeleteColumn selected={isSelected} onClick={() => onDeleteRow(row)} />
          ) : (
            <td />
          ))}
        {isEditable &&
          (isRowEditable ? (
            <InstantGridEditColumn selected={isSelected} onClick={() => onEditRow(row.get('id'), row)} />
          ) : (
            <td />
          ))}
        {actions ? <InstantGridActionsColumn row={row} actions={actions} /> : null}
      </Row>
    )
  }
}
