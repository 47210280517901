import { List, Map } from 'immutable'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'
import { ColumnDefinition } from '../components/grid/GridColumns'

const gridsSelector = createImmutableEqualSelector(
  (state: AppStateType) => state.getIn(['grid']),
  (grids) => grids
)

export const gridByIdSelector = createImmutableEqualSelector(
  gridsSelector,
  (state: AppStateType, gridKey: string) => gridKey,
  (grids, gridKey) => (grids ? grids.get(gridKey, Map()) : Map())
)

export const selectedIdsByGridKeySelector = createImmutableEqualSelector(
  gridByIdSelector,
  (grids) => grids?.get('selectedIds') || List()
)

export const sortedColumnKeyByGridKeySelector: (
  state: AppStateType,
  gridKey: string,
  defaultSortingColumn: string
) => string = createImmutableEqualSelector(
  gridByIdSelector,
  (state: AppStateType, gridKey: string, defaultSortingColumn: string) => defaultSortingColumn,
  (grids: Map<string, any>, defaultSortingColumn: string) => grids?.get('sortedColumnKey') || defaultSortingColumn
)

export const isSortingOrderDescendingSelector: (
  state: AppStateType,
  gridKey: string,
  defaultSortingColumn: string,
  isDefaultSortingOrderDescending: boolean
) => boolean = createImmutableEqualSelector(
  gridByIdSelector,
  (state: AppStateType, gridKey: string, defaultSortingColumn: string, isDefaultSortingOrderDescending: boolean) =>
    isDefaultSortingOrderDescending,
  (grids: Map<string, any>, isDefaultSortingOrderDescending: boolean) =>
    grids?.get('sortDescending') || isDefaultSortingOrderDescending
)

export const filterColumnsSelector: <T>(
  columns: List<ColumnDefinition<T>>,
  hiddenColumns: List<keyof T>
) => List<ColumnDefinition<T>> = createImmutableEqualSelector(
  (columns: List<ColumnDefinition<any>>) => columns,
  (columns: List<ColumnDefinition<any>>, hiddenColumns: List<unknown>) => hiddenColumns,
  (columns: List<ColumnDefinition<any>>, hiddenColumns: List<unknown>): List<ColumnDefinition<any>> =>
    columns.filter((column) => !hiddenColumns.includes(column.dataIndex) || column.hidden)
)
