import { fromJS, Map } from 'immutable'
import {
  GET_AIR_EXPRESS_RECURRING_SCHEDULES,
  GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE
} from '../actions/actionTypes'
import { ImmutableMap, ImmutableMapFactory } from '../types/immutableTypes'

interface AirExpressActionType {
  type: string
  body: {
    price: string
    airexpressRecurringTemplates: string
  }
}

interface AirExpressStateProps {
  isLoadingPrice: boolean
  airexpressRecurringTemplates: any
  airexpressRecurringTemplatesForDate: any
  isLoadingRecurringSchedules: boolean
  airexpressRecurringTemplatesOrder: any
}

interface AirExpressState extends ImmutableMap<AirExpressStateProps> {}

const initialState = ImmutableMapFactory<AirExpressStateProps>({
  isLoadingPrice: false,
  airexpressRecurringTemplates: Map(),
  airexpressRecurringTemplatesForDate: Map(),
  isLoadingRecurringSchedules: false,
  airexpressRecurringTemplatesOrder: Map()
}) as AirExpressState

export default function reducer(state: AirExpressState = initialState, action: AirExpressActionType) {
  switch (action.type) {
    case GET_AIR_EXPRESS_RECURRING_SCHEDULES.SUCCESS:
      return state.set('isLoadingRecurringSchedules', false)
    case GET_AIR_EXPRESS_RECURRING_TEMPLATES_FOR_DATE.SUCCESS:
      return state.set('airexpressRecurringTemplatesForDate', fromJS(action.body).get('airexpressRecurringTemplates'))
    default:
      return state
  }
}
