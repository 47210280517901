import i18next from 'i18next'
import { List, Map } from 'immutable'
import { DateTime } from 'luxon'
import React from 'react'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import styled from 'styled-components'
import { AcceptButton } from '../../primitives/Buttons'
import { SmallValidationError } from '../../primitives/ErrorMessages'
import { Input, LabelNew, LabelText } from '../../primitives/Forms'
import ImmutableComponent from '../../primitives/ImmutableComponent'
import Select from '../../primitives/Select'
import vars from '../../styles/variables'
import { ImmutableMapFactory } from '../../types/immutableTypes'
import { required } from '../../utils/inputValidation'
import DatePicker from './DatePicker'
import { RecurringOrderProps, RecurringOrderType } from './recurringOrderTypes'

interface Props {
  onSubmit: (form: RecurringOrderType) => any
  formValues: RecurringOrderType
  mergeFormValues: (model: string, newVals: Map<string, any>) => void
}

const days = List(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])

const CheckboxText = styled.span`
  margin-left: 0.4em;
`

export default class RecurringOrderForm extends ImmutableComponent<Props> {
  constructor(props: Props) {
    super(props)
    this.state = { submitting: false }
  }

  static initialState: RecurringOrderType = ImmutableMapFactory<RecurringOrderProps>({
    name: '',
    frequency: 'WEEKLY',
    startDate: '',
    endDate: '',
    holidays: false,
    dispatchAsGroup: false,
    paidTogether: false,
    fixedOrder: false,
    days: Map(),
    orders: List()
  }).merge(days.reduce((acc, d) => acc.set(d, false), Map()))

  onSubmit = (values: RecurringOrderType) => {
    const timer = setTimeout(() => this.setState({ submitting: true }), 200)
    this.props.onSubmit(values).finally(() => {
      clearTimeout(timer)
      this.setState({ submitting: false })
    })
  }

  updatePaidTogether = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dispatchAsGroup = e.target.checked
    const paidTogether = this.props.formValues.get('paidTogether')
    const fixedOrder = this.props.formValues.get('fixedOrder')
    this.props.mergeFormValues(
      'recurringOrderForm',
      Map({
        paidTogether: dispatchAsGroup && paidTogether,
        fixedOrder: dispatchAsGroup && fixedOrder
      })
    )
  }

  render(): React.ReactNode {
    return (
      <Form
        model="recurringOrderForm"
        onSubmit={this.onSubmit}
        style={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '20em 13em minmax(6em, auto) auto',
          justifyContent: 'start',
          gridColumnGap: '1em'
        }}
      >
        <LabelNew htmlFor="recurringOrderForm.name" style={{ gridRowStart: 1, gridColumnStart: 1 }}>
          <LabelText>{i18next.t('instant.recurring.name')}</LabelText>
        </LabelNew>
        <div style={{ gridRowStart: 2, gridColumnStart: 1 }}>
          <Control.text
            model=".name"
            id="recurringOrderForm.name"
            component={Input}
            placeholder={i18next.t('instant.recurring.name')}
            validators={{ required }}
            style={{
              height: '40px'
            }}
          />
          <Errors
            className="errors"
            model=".name"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: i18next.t('application.required') }}
          />
        </div>
        <LabelNew htmlFor="recurringOrderForm.frequency" style={{ gridRowStart: 1, gridColumnStart: 2 }}>
          <LabelText>{i18next.t('instant.recurring.frequency')}</LabelText>
        </LabelNew>
        <Select expand white style={{ gridRowStart: 2, gridColumnStart: 2 }}>
          <Control.select
            model=".frequency"
            id="recurringOrderForm.frequency"
            defaultValue={'weekly'}
            style={{
              color: vars.colors.black,
              height: '40px'
            }}
          >
            <option value="WEEKLY">{i18next.t('instant.recurring.frequencies.weekly')}</option>
            <option value="SECOND_WEEK">{i18next.t('instant.recurring.frequencies.second_week')}</option>
            <option value="THIRD_WEEK">{i18next.t('instant.recurring.frequencies.third_week')}</option>
          </Control.select>
        </Select>
        <LabelNew htmlFor="recurringOrderForm.day" style={{ gridRowStart: 1, gridColumnStart: 3 }}>
          <LabelText>{i18next.t('instant.recurring.daysOfWeek')}</LabelText>
        </LabelNew>
        <div style={{ gridRowStart: 2, gridColumnStart: 3 }}>
          {days.map((day, i) => (
            <LabelNew key={day} style={{ marginLeft: i == 0 ? '0' : '0.5em' }}>
              <Control.checkbox model={`.${day}`} id={`recurringOrderForm.${day}`} value={day} defaultValue={false} />
              <CheckboxText>{i18next.t(`weekdaysShort.${day}`)}</CheckboxText>
            </LabelNew>
          ))}
        </div>
        <LabelNew style={{ gridRowStart: 2, gridColumnStart: 4 }}>
          <Control.checkbox model=".holidays" id={'recurringOrderForm.holidays'} defaultValue={false} />
          <CheckboxText>{i18next.t('instant.recurring.includeHolidays')}</CheckboxText>
        </LabelNew>
        <LabelNew htmlFor="recurringOrderForm.startDate" style={{ gridRowStart: 1, gridColumnStart: 5 }}>
          <LabelText>{i18next.t('instant.recurring.startDate')}</LabelText>
        </LabelNew>
        <div style={{ gridRowStart: 2, gridColumnStart: 5 }}>
          <Control.input
            model=".startDate"
            id={'recurringOrderForm.startDate'}
            component={DatePicker}
            validators={{ required }}
            placeholder={'YYYY-MM-DD'}
          />
          <Errors
            className="errors"
            model=".startDate"
            show="touched"
            wrapper={SmallValidationError}
            messages={{ required: i18next.t('application.required') }}
          />
        </div>
        <LabelNew htmlFor="recurringOrderForm.endDate" style={{ gridRowStart: 1, gridColumnStart: 6 }}>
          <LabelText>{i18next.t('instant.recurring.endDate')}</LabelText>
        </LabelNew>
        <div style={{ gridRowStart: 2, gridColumnStart: 6 }}>
          <Control.input
            model=".endDate"
            id={'recurringOrderForm.endDate'}
            component={DatePicker}
            placeholder={'YYYY-MM-DD'}
          />
        </div>
        <div style={{ gridRowStart: 2, gridColumnStart: 7 }}>
          <div>
            <LabelNew htmlFor="recurringOrderForm.dispatchAsGroup">
              <Control.checkbox
                model=".dispatchAsGroup"
                id={'recurringOrderForm.dispatchAsGroup'}
                defaultValue={false}
                onChange={this.updatePaidTogether}
              />
              <CheckboxText>{i18next.t('instant.recurring.dispatchAsGroup')}</CheckboxText>
            </LabelNew>
          </div>
          <div>
            <LabelNew htmlFor="recurringOrderForm.paidTogether">
              <Control.checkbox
                model=".paidTogether"
                id={'recurringOrderForm.paidTogether'}
                defaultValue={false}
                disabled={!this.props.formValues.get('dispatchAsGroup')}
              />
              <CheckboxText>{i18next.t('instant.paidTogether')}</CheckboxText>
            </LabelNew>
          </div>
          <div>
            <LabelNew htmlFor="recurringOrderForm.fixedOrder">
              <Control.checkbox
                model=".fixedOrder"
                id={'recurringOrderForm.fixedOrder'}
                defaultValue={false}
                disabled={!this.props.formValues.get('dispatchAsGroup')}
              />
              <CheckboxText>{i18next.t('instant.fixedOrder')}</CheckboxText>
            </LabelNew>
          </div>
        </div>
        <AcceptButton disabled={this.state.submitting} type="submit" style={{ gridRowStart: 2, gridColumnStart: 8 }}>
          {i18next.t('instant.recurring.save')}
        </AcceptButton>
      </Form>
    )
  }
}

export const formatDate = formatterFor('yyyy-MM-dd')

function formatterFor(format: string = 'yyyy-MM-dd') {
  return (date: Date) => DateTime.fromJSDate(date).toFormat(format)
}
