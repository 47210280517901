import { LeafletMap, LeafletMapProps } from '@glow/common-components'
import classNames from 'classnames'
import React from 'react'

interface Props extends LeafletMapProps {
  className?: string
}

export const AbsolutePositionedMap = ({ className, ...props }: Props) => (
  <div className={classNames('overflow-hidden relative h-[300px] w-full z-[1]', className)}>
    <LeafletMap className="absolute h-[300px] w-full" {...props} />
  </div>
)
