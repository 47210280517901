import styled from 'styled-components'
import vars from '../styles/variables'

export const DEFAULT_ROW_HEIGHT_PX = 30

export const Grid = styled.div<{ overflowY?: boolean; height?: string; minHeight?: string; isResizeable?: boolean }>`
  background-color: ${vars.colors.white};
  font-size: 0.8rem;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '100%')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '100%')};
  overflow-y: ${(props) => (props.overflowY ? 'auto' : '')};
  resize: ${(props) => (props.isResizeable ? 'vertical' : 'none')};

  @media print {
    white-space: pre-wrap;
    text-align: unset;
    resize: none;
  }
`

interface IRow {
  isDragging?: boolean
  selected?: boolean
  expanded?: boolean
  isClickable?: boolean
  rowBackgroundColor?: string
  rowBackgroundHoverColor?: string
  group?: GroupProps
}

const TableStyling = {
  borderRadius: 8,
  padding: 2,
  edgePadding: 5,
  spacing: 0
}

export const Row = styled.tr<IRow>`
  box-sizing: border-box;
  height: ${DEFAULT_ROW_HEIGHT_PX}px;
  border-bottom: 1px solid rgba(34, 62, 55, 0.12);
  background-color: ${(props) =>
    props.rowBackgroundColor
      ? props.rowBackgroundColor
      : props.selected
        ? vars.newColors.cloudBlue
        : vars.colors.white};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  overflow: hidden;
  color: ${vars.colors.gridText};
  font-family: 'Open Sans', sans-serif;
  align-items: center;

  @media screen {
    :hover td {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.11);
      transform: scale(
        1
      ); // This is kind of a "hack" to get the box-shadow above the next line. Without this the box-shadow will not be shown.
      background-color: ${(props) =>
        props.rowBackgroundHoverColor
          ? props.rowBackgroundHoverColor
          : props.isClickable
            ? props.selected
              ? vars.newColors.cloudBlue
              : vars.colors.gridHoverRow
            : vars.colors.gridHoverRow};
    }

    td {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background-color: ${(props) =>
        props.rowBackgroundColor
          ? props.rowBackgroundColor
          : props.selected
            ? vars.newColors.cloudBlue
            : props.group?.inGroup
              ? vars.colors.gray10
              : ''};
      border: none;
      border-bottom: 1px solid rgba(34, 62, 55, 0.12);
      border-top: 0px;
      border-style: solid none;
      padding: ${TableStyling.padding}px;
    }
  }

  @media print {
    color: black;
    background-color: white;

    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 11px;

    td {
      border: 1px solid #e9e9e9;
      padding: ${TableStyling.padding}px;
      white-space: normal;
      text-overflow: initial;
      overflow: auto;
    }
  }
`

export const Line = styled.hr`
  border: 1px solid ${vars.colors.gray12};
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`

export const Icon = styled.img`
  position: static;
  bottom: 4px;
  display: inline;
`

export const Panel = styled.div`
  border: 1px solid ${vars.colors.gray13};
  border-radius: 4px;
  background-color: ${vars.colors.gray10};
  padding: 16px 24px 16px 24px;
`

interface TableProps {
  isFullscreen: boolean
}

export const Table = styled.table<TableProps>`
  border-collapse: separate;
  border-spacing: 0 ${TableStyling.spacing}px;
  width: ${(props) => (props.isFullscreen ? '100%' : '')};
  position: relative;

  @media print {
    width: 100%;
    border-collapse: collapse;
  }
`

export const TH = styled.th`
  padding: ${TableStyling.padding}px;
  position: sticky;
  background: white;
  top: 0;

  @media print {
    text-align: left;
    height: 32px;
    padding: 5px;
    background: #f4f4f4;
    border: 1px solid #e9e9e9;
    top: 0;
  }
`

export interface GroupProps {
  inGroup: boolean
  firstInGroup: boolean
  lastInGroup: boolean
}

export const PopoverButton = styled.button`
  background: ${vars.colors.white};
  color: ${vars.colors.black};
  text-align: left;
  display: block;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 15px;
  white-space: nowrap;
  :focus {
    outline: 0;
  }
  border-left: 4px solid ${vars.colors.white};

  :enabled:active {
    box-shadow: ${vars.boxShadow.small};
  }

  :disabled {
    background: ${vars.colors.gray12};
    border-color: ${vars.colors.gray13};
    color: ${vars.colors.gray14};
    cursor: default;
  }

  :enabled:hover {
    background-color: ${vars.newColors.cloudBlue};
    border-left: 4px solid ${vars.newColors.primary};
  }
`
