import React, { ReactNode, RefObject } from 'react'
import { Map } from 'immutable'
import { DEFAULT_ROW_HEIGHT_PX } from '../../primitives/InstantGridStyles'

interface Props {
  items: Map<string, any>
  totalItemsCount: number
  children?: ReactNode[]
  scrollRef?: RefObject<HTMLDivElement>
  height: string | undefined
}

interface State {
  attachedScroller: boolean
  recordHeight: number
  recordsPerBody: number
  displayStart: number
  displayEnd: number
  scroll: number
}

const OFFSET_ITEMS_NUMBER = 10

export default class InstantGridInfiniteScroll extends React.Component<Props, State> {
  scrollRef: RefObject<HTMLDivElement> | undefined = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)
    const height =
      this.scrollRef?.current?.clientHeight || isNaN(Number(props.height)) ? 850 : Number(props.height) || 850
    const recordsPerBody = Math.floor((height - 2) / DEFAULT_ROW_HEIGHT_PX)

    this.state = {
      recordHeight: DEFAULT_ROW_HEIGHT_PX,
      recordsPerBody: recordsPerBody,
      displayStart: 0,
      displayEnd: recordsPerBody + OFFSET_ITEMS_NUMBER,
      attachedScroller: false,
      scroll: 0
    }
  }

  componentDidMount() {
    this.scrollRef = this.props.scrollRef
    this.attachScrollListener()
  }

  componentDidUpdate(): void {
    if (!this.state.attachedScroller && this.props.scrollRef) {
      this.attachScrollListener()
    }
  }

  componentWillUnmount() {
    this.detachScrollListener()
  }

  attachScrollListener() {
    if (this.state.attachedScroller) return

    if (this.scrollRef?.current) {
      this.scrollRef?.current?.addEventListener('scroll', this.onScroll)
      this.setState({ attachedScroller: true })
    } else this.setState({ attachedScroller: false })
  }

  detachScrollListener() {
    if (!this.state.attachedScroller) return

    this.setState({ attachedScroller: false })
    this.scrollRef?.current?.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    this.scrollListener(this.scrollRef?.current?.scrollTop || 1)
  }

  scrollListener = (scroll: number) => {
    const visibleStartIndexAfterScroll = Math.floor(scroll / this.state.recordHeight)
    const displayStart = Math.max(0, visibleStartIndexAfterScroll - OFFSET_ITEMS_NUMBER)
    const displayEnd = Math.min(
      displayStart + this.state.recordsPerBody + OFFSET_ITEMS_NUMBER * 2,
      this.props.totalItemsCount - 1
    )

    this.setState({
      displayStart,
      displayEnd,
      scroll
    })
  }

  render() {
    const { displayStart, displayEnd, recordHeight } = this.state
    const toShow = this.props.items.slice(displayStart, displayEnd + 1).valueSeq()

    return (
      <tbody>
        <tr style={{ height: displayStart * recordHeight }}>
          <td colSpan={200}>{''}</td>
        </tr>
        {toShow}
        <tr style={{ height: (this.props.totalItemsCount - displayEnd - 1) * recordHeight }}>
          <td colSpan={200}>{''}</td>
        </tr>
      </tbody>
    )
  }
}
