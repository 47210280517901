import styled from 'styled-components'

export const AutocompleteContainer = styled.div`
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 10;
`

export const SuggestionActive = styled.div`
  padding: 8px;
  text-align: left;
  background-color: #fafafa;
  cursor: pointer;
`

export const SuggestionInactive = styled.div`
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
`
