import { Label } from '../../primitives/Forms'
import i18next from 'i18next'
// @ts-expect-error
import { Control } from 'react-redux-form/lib/immutable'
import { LinkButton } from '../../primitives/Button'
import React from 'react'

interface Props {
  onReset: () => void
}

export default ({ onReset }: Props) => (
  <Label>
    <div>{i18next.t('courier.color')}</div>
    <Control.input model=".color" id="color" type="color" style={{ padding: 0, width: '80px', height: '40px' }} />
    <LinkButton type="button" style={{ fontWeight: 'bold', position: 'relative', bottom: '2px' }} onClick={onReset}>
      {i18next.t('courier.resetColor')}
    </LinkButton>
  </Label>
)
